import moment from 'moment';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Container, Row } from 'reactstrap';
import Section from '../../components/common/Section';
import Layout from '../../components/Layout';
import { usePageLog } from '../../utils/analytics';

const Terms: React.FC<RouteComponentProps> = () => {
  usePageLog({ pageTitle: 'Terms' });

  return (
    <Layout navSpace={true}>
      <Section minHeight='75vh'>
        <Container>
          <Row className='mt-3'>
            <h1>Swing Therapeutics Terms of Use</h1>
          </Row>
          <Row>
            <p>Last Updated: July 16, 2021</p>
          </Row>
          <Row>
            <p>
              Thank you for choosing our websites, swingtherapeutics.com, react-fm.com, and digital therapeutic applications (collectively, our
              “Services”), brought to you by Swing Therapeutics, Inc. (“Swing”, “us”, “our” and “we”).
            </p>
          </Row>
          <Row>
            <p>
              Below are our Terms of Use (the “Terms”). We are pleased to answer any questions or concerns you may have at +1 619-332-0553 or{' '}
              <a href='mailto:support@swingtherapeutics.com'>support@swingtherapeutics.com</a>. Please note that certain features of our Services may
              be subject to additional guidelines, terms, or rules, which will be posted on our website or included within the application in
              connection with such features. All such additional terms, guidelines, and rules are incorporated by reference into these Terms.
            </p>
          </Row>
          <Row>
            <p>
              Your use of our Services is also subject to our Privacy Policy, which may change from time to time (the policy explains how you can
              learn about these changes). Our Services are intended for use within the United States of America. Please note that our websites,
              swingtherapeutics.com, react-fm.com, www.react-fm.com are copyrighted works belonging to Swing Therapeutics.
            </p>
          </Row>
          <Row>
            <p>
              IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS, YOU MAY NOT ACCESS OR USE THIS WEBSITE OR THE SERVICES. BY USING OUR SERVICES, YOU AGREE
              TO COMPLY WITH OUR TERMS, AS WELL AS THE TERMS PERMITTED BY THE APPLE MEDIA SERVICES TERMS AND CONDITIONS AND/OR THE GOOGLE PLAY TERMS
              OF SERVICE, AS APPLICABLE TO YOUR MOBILE DEVICE.
            </p>
          </Row>
          <Row>
            <p>
              PLEASE BE AWARE THAT SECTION 6 (COMPANY COMMUNICATIONS) OF THIS AGREEMENT, BELOW, CONTAINS YOUR OPT-IN CONSENT TO RECEIVE COMMUNICATIONS
              FROM US, INCLUDING VIA E-MAIL, TEXT MESSAGE, CALLS AND PUSH NOTIFICATIONS.
            </p>
          </Row>
          <Row>
            <p>
              OUR SERVICES AND ANY OTHER SWING PRODUCTS AND SERVICES ARE NOT INTENDED TO PROVIDE HEALTH OR MEDICAL ADVICE AND ARE NOT A SUBSTITUTE FOR
              MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. YOUR USE OF OUR SERVICES DOES NOT CREATE ANY PATIENT RELATIONSHIP BETWEEN YOU AND SWING, NOR
              SHOULD IT BE CONSIDERED A REPLACEMENT FOR CONSULTATION WITH A DOCTOR OR OTHER HEALTHCARE PROFESSIONAL. ALWAYS SEEK THE ADVICE OF YOUR
              PHYSICIAN OR OTHER QUALIFIED HEALTH PROVIDER WITH ANY QUESTIONS YOU MAY HAVE REGARDING A MEDICAL CONDITION AND BEFORE STOPPING, STARTING
              OR MODIFYING ANY TREATMENT. NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY IN SEEKING IT BECAUSE OF SOMETHING YOU HAVE READ ON OR
              THROUGH THE SERVICES. WHILE SWING STRIVES TO PROVIDE HELPFUL AND ACCURATE PRODUCTS AND SERVICES, SWING NEVERTHELESS MAKES NO
              ENDORSEMENT, REPRESENTATION, OR WARRANTY OF ANY KIND ABOUT OUR SERVICES OR ABOUT THE EFFICACY, APPROPRIATENESS OR SUITABILITY OF ANY
              SPECIFIC TESTS, PRODUCTS, PROCEDURES, TREATMENTS, SERVICES, OPINIONS OR ANY OTHER CONTENT, INFORMATION, SERVICES, OR RECOMMENDATIONS
              THAT MAY BE CONTAINED ON, AVAILABLE THROUGH, OR PROVIDED VIA OUR SERVICES.{' '}
              <b>
                THE SERVICES ARE NOT INTENDED FOR MEDICAL EMERGENCIES. IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL 9-1-1 IMMEDIATELY.
              </b>
            </p>
          </Row>
          <Row>
            <h4>Table of Contents</h4>
          </Row>
          <Row>
            <ol>
              <li>
                <p>What equipment is necessary to use our Services?</p>
              </li>
              <li>
                <p>Registration and Account Creation</p>
              </li>
              <li>
                <p>Account Responsibilities</p>
              </li>
              <li>
                <p>License to User Content</p>
              </li>
              <li>
                <p>License Grant to the Services</p>
              </li>
              <li>
                <p>Company Communications</p>
              </li>
              <li>
                <p>Payment</p>
              </li>
              <li>
                <p>Acceptable Use Policy</p>
              </li>
              <li>
                <p>Enforcement</p>
              </li>
              <li>
                <p>Feedback</p>
              </li>
              <li>
                <p>What are Swing’s rights?</p>
              </li>
              <li>
                <p>No Support or Maintenance</p>
              </li>
              <li>
                <p>Mobile data fees may apply</p>
              </li>
              <li>
                <p>External and Third-Party Services</p>
              </li>
              <li>
                <p>Other Users</p>
              </li>
              <li>
                <p>Release</p>
              </li>
              <li>
                <p>No Warranty</p>
              </li>
              <li>
                <p>Limitation of Liability</p>
              </li>
              <li>
                <p>Indemnity</p>
              </li>
              <li>
                <p>Export</p>
              </li>
              <li>
                <p>Commercial Items</p>
              </li>
              <li>
                <p>Governing Law and Dispute Resolution</p>
              </li>
              <li>
                <p>Term and Termination</p>
              </li>
              <li>
                <p>International issues</p>
              </li>
              <li>
                <p>Electronic Communications</p>
              </li>
              <li>
                <p>Force Majeure</p>
              </li>
              <li>
                <p>Notice</p>
              </li>
              <li>
                <p>General terms</p>
              </li>
              <li>
                <p>What if Swing changes these Terms?</p>
              </li>
              <li>
                <p>Contact Information</p>
              </li>
            </ol>
          </Row>
          <Row>
            <h6>1. What equipment is necessary to use our Services?</h6>
          </Row>
          <Row>
            <p>
              Full use of our Services, including access to our digital therapeutic applications, is dependent upon your use of a smartphone, tablet,
              or other computing device with a current operating system, adequate software, storage space, and Internet access. The maintenance and
              security of your device and software may influence the performance of our Services, and you must ensure your equipment’s proper
              functionality and compatibility with our Services requirements. Some versions of our Services may be compatible only with designated
              devices, operating systems, internet browsers or settings.
            </p>
          </Row>
          <Row>
            <h6>2. Registration and Account Creation.</h6>
          </Row>
          <Row>
            <ol type='a'>
              <li>
                <p>
                  Registering Your Account. In order to use certain features of Swing’s products, you may be required to become a Registered User. For
                  purposes of the Agreement, a “Registered User” is a user who has registered an account on the Services (“Account”), has a valid
                  account on the social networking service (“Third-Party Platform”) through which the user has connected to the Services (each such
                  account, a “Third-Party Account”), or has an account with the provider of the digital therapeutics mobile application for the user’s
                  mobile device.
                </p>
              </li>
              <li>
                <p>
                  Access Through a Third-Party Platform. If you access the Services through a Third-Party Platform as part of the functionality of the
                  Services, you may link your Account with Third-Party Accounts, by allowing Swing to access your Third-Party Account, as is permitted
                  under the applicable terms and conditions that govern your use of each Third-Party Account. you represent that you are entitled to
                  disclose your Third-Party Account login information to Swing and/or grant Swing access to your Third-Party Account (including, but
                  not limited to, for use for the purposes described herein) without breach by you of any of the terms and conditions that govern your
                  use of the applicable Third-Party Account and without obligating Swing to pay any fees or making Swing subject to any usage
                  limitations imposed by such third-party service providers. By granting Swing access to any Third-Party Accounts, you understand that
                  Swing may access, make available and store (if applicable) any information, data, text, software, music, sound, photographs,
                  graphics, video, messages, tags and/or other materials accessible through the Services (collectively, “Content”) that you have
                  provided to and stored in your Third-Party Account (“Third-Party Platform Content”) so that it is available on and through the
                  Services via your Account. Unless otherwise specified in the Agreement, all Third-Party Platform Content shall be considered to be
                  your User Content (as defined below) for all purposes of the Agreement. Depending on the Third-Party Accounts you choose and subject
                  to the privacy settings that you have set in such Third-Party Accounts, personally identifiable information that you post to your
                  Third-Party Accounts may be available on and through your Account on Services. Please note that if a Third-Party Account or
                  associated service becomes unavailable, or Swing’s access to such Third-Party Account is terminated by the third- party service
                  provider, then Third-Party Platform Content will no longer be available on and through the Services. You have the ability to disable
                  the connection between your Account and your Third-Party Accounts at any time by accessing the “Settings” section of the Services.
                  PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD- PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED
                  SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS, AND SWING DISCLAIMS ANY LIABILITY FOR PERSONALLY IDENTIFIABLE
                  INFORMATION THAT MAY BE PROVIDED TO IT BY SUCH THIRD- PARTY SERVICE PROVIDERS IN VIOLATION OF THE PRIVACY SETTINGS THAT YOU HAVE SET
                  IN SUCH THIRD-PARTY ACCOUNTS. Swing makes no effort to review any Third-Party Platform Content for any purpose, including but not
                  limited to, for accuracy, legality or noninfringement, and Swing is not responsible for any Third-Party Platform Content.
                </p>
              </li>
              <li>
                <p>
                  Registration Data. You agree to provide true, accurate, current and complete information about yourself as prompted by the
                  registration form. You represent that you are at least eighteen (18) years old, can form a binding contract, and are not a person
                  barred from using Services under the laws of the United States, your place of residence or any other applicable jurisdiction. You
                  agree not to create more than one Account per person and not to create an Account using a false identity.
                </p>
              </li>
              <li>
                <p>
                  Termination of your Account. You may delete your Account at any time, for any reason, by contacting{' '}
                  <a href='mailto:support@swingtherapeutics.com'>support@swingtherapeutics.com</a>
                </p>
              </li>
            </ol>
          </Row>
          <Row>
            <h6>3. Account Responsibilities.</h6>
            <Row />
            <p>
              You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities
              that occur under your Account. You agree to immediately notify Swing of any unauthorized use, or suspected unauthorized use of your
              Account or any other breach of security. You are responsible for: (i) the Content, information and data you enter, upload, post, e-mail,
              transmit or otherwise make available through the Services during any registration and during your use of our Services; (ii) any
              solicited or unsolicited feedback you give us about our Services; (iii) and any other unsolicited information or Content you submit or
              provide to us, whether or not via our Services (each of (i), (ii), and (iii), together, your “User Content”). Swing cannot and will not
              be liable for any loss or damage arising from your failure to comply with the preceding requirements.
            </p>
          </Row>
          <Row>
            <h6>4. License to User Content</h6>
          </Row>
          <Row>
            <p>
              You hereby grant (and you represent and warrant that you have the right to grant) to Swing an irrevocable, nonexclusive, royalty-free
              and fully paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into
              other works, and otherwise use and exploit your User Content, and to grant sublicenses of the foregoing rights, solely for the purposes
              of including your User Content in the Services in an anonymized fashion. You hereby irrevocably waive (and agree to cause to be waived)
              any claims and assertions of moral rights or attribution with respect to your User Content.
            </p>
          </Row>
          <Row>
            <h6>5. License Grant to the Services</h6>
            <p>
              Our Services are licensed, not sold, to you. Your license to our Services is subject to your prior acceptance of these Terms. Your
              license to our Services is granted by Swing, not by Apple or Google (as applicable to your mobile device or computer system) and can be
              terminated by the company at any time. Swing reserves all rights not expressly granted to you under these Terms.
            </p>
          </Row>
          <Row>
            <ol type='a'>
              <li>
                <p>
                  Scope of License: Swing hereby grants to you a non-exclusive, revocable, non-transferable, limited license to use our Services as
                  permitted by these Terms for your own personal, noncommercial use. These Terms will govern any content, materials, or services
                  accessible from within our Services, as well as upgrades provided by Swing that replace or supplement the original version of our
                  Services, unless such upgrade is accompanied by different terms. Except as provided in these Terms, you may not distribute or make
                  our Services available over a network where they could be used by multiple devices at the same time. You may not transfer,
                  redistribute, or sublicense our Services. You may not copy (except as permitted by these Terms), reverse-engineer, disassemble,
                  attempt to derive the source code of, modify, or create derivative works of our Services, any updates, or any part thereof (except
                  as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the
                  licensing terms governing use of any open-sourced components included with our Services.
                </p>
              </li>
              <li>
                <p>
                  Consent to Use of Data: You agree that Swing may collect and use data and related information—including but not limited to your use
                  of the software and technical information about your device, system, and application software, and peripherals—that is gathered
                  periodically to facilitate the provision of software updates, product support, and other services to you (if any) related to our
                  Services. Swing may use this information, as long as it is in a form that does not personally identify you, to improve its products
                  or to provide services or technologies to you.
                </p>
              </li>
            </ol>
          </Row>
          <Row>
            <h6>6. Company Communications</h6>
          </Row>
          <Row>
            <p>
              By entering into this Agreement or using the Services, you agree to receive communications from us, including via e- mail, text message,
              calls, and push notifications. You agree that texts, calls or prerecorded messages may be generated by automatic telephone dialing
              systems. Communications from us and our affiliated companies may include but are not limited to operational communications concerning
              your Account or the use of the Services and updates concerning new and existing features on the Services. Standard text messaging
              charges applied by your cell phone carrier will apply to text messages that we send. IF YOU WISH TO OPT OUT OF ALL TEXTS OR CALLS FROM
              US (INCLUDING OPERATIONAL OR TRANSACTIONAL TEXTS OR CALLS), YOU CAN TEXT THE WORD “STOP” FROM THE MOBILE DEVICE RECEIVING THE MESSAGES
              TO THE NUMBER FROM WHICH YOU ARE RECEIVING THE TEXTS. HOWEVER, YOU ACKNOWLEDGE THAT OPTING OUT OF RECEIVING ALL TEXTS MAY IMPACT YOUR
              USE OF THE SERVICES.
            </p>
          </Row>
          <Row>
            <h6>7. Payment</h6>
          </Row>
          <Row>
            <p>Company does not currently charge a fee for its Services, but it reserves the right to charge such fees in the future.</p>
          </Row>
          <Row>
            <h6>8. Acceptable Use Policy.</h6>
          </Row>
          <Row>
            <p>The following terms constitute our “Acceptable Use Policy”:</p>
          </Row>
          <Row>
            <ol type='a'>
              <li>
                <p>
                  You agree not to use the Services to collect, upload, transmit, display, or distribute any User Content (i) that violates any
                  third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any
                  other intellectual property or proprietary right; (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful,
                  invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently
                  offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual or is otherwise
                  objectionable; (iii) that is harmful to minors in any way; or (iv) that is in violation of any law, regulation, or obligations or
                  restrictions imposed by any third party.
                </p>
              </li>
              <li>
                In addition, you agree not to: (i) upload, transmit, or distribute to or through the Services any computer viruses, worms, or any
                software intended to damage or alter a computer system or data; (ii) send through the Services unsolicited or unauthorized
                advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited
                messages, whether commercial or otherwise; (iii) use the Services to harvest, collect, gather or assemble information or data
                regarding other users, including e-mail addresses, without their consent; (iv) interfere with, disrupt, or create an undue burden on
                servers or networks connected to the Services, or violate the regulations, policies or procedures of such networks; (v) attempt to
                gain unauthorized access to the Services (or to other computer systems or networks connected to or used together with the Services),
                whether through password mining or any other means; (vi) harass or interfere with any other user’s use and enjoyment of the Services;
                or (vi) use software or automated agents or scripts to produce multiple accounts on the Services, or to generate automated searches,
                requests, or queries to (or to strip, scrape, or mine data from) the Services (provided, however, that we conditionally grant to the
                operators of public search engines revocable permission to use spiders to copy materials from the Services for the sole purpose of and
                solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such
                materials, subject to the parameters set forth in our robots.txt file).
              </li>
            </ol>
          </Row>
          <Row>
            <h6>9. Enforcement</h6>
          </Row>
          <Row>
            <p>
              We reserve the right (but have no obligation) to review any User Content, and to investigate and/or take appropriate action against you
              in our sole discretion if you violate the Acceptable Use Policy or any other provision of these Terms or otherwise create liability for
              us or any other person. Such action may include removing or modifying your User Content, terminating your Account, and/or reporting you
              to law enforcement authorities.
            </p>
          </Row>
          <Row>
            <h6>10. Feedback</h6>
          </Row>
          <Row>
            <p>
              If you provide Swing with any feedback, ideas or suggestions regarding the Services (“Feedback”), you hereby assign to Swing all rights
              in such Feedback and agree that Swing shall have the right to use and fully exploit such Feedback and related information in any manner
              it deems appropriate. Swing will treat any Feedback you provide to Swing as non-confidential and non-proprietary. You agree that you
              will not submit to Swing any information or ideas that you consider to be confidential or proprietary.
            </p>
          </Row>
          <Row>
            <h6>11. What are Swing's rights?</h6>
          </Row>
          <Row>
            <p>
              Swing owns, controls, and/or licenses all text, graphics, interfaces, photographs, trademarks, logos, music, audio, works of authorship
              of any kind, computer code, and information or other materials that are included, posted, generated, provided, or otherwise made
              available to you through our Services (together, the “Swing Content”), including but not limited to the design, structure, selection,
              coordination, expression, and arrangement of the Swing Content. Neither these Terms (nor your access to the Services) transfers to you
              or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights
              expressly set forth in Section 5. Swing and its suppliers reserve all rights not granted in these Terms. There are no implied licenses
              granted under these Terms. The Swing Content is protected by trade dress, copyright, trademark laws, other intellectual property rights,
              and other laws of the United States and foreign countries. Our Services and no Swing Content may be copied, reproduced, republished,
              uploaded, posted, transmitted, sold, licensed, transferred, adapted, modified, publicly displayed, or distributed without Swing’s prior
              written consent. The license(s) to you set forth below is conditioned on you not modifying the Swing Content and on your acceptance of
              any terms, conditions, and notices accompanying the Swing Content or otherwise set forth in our Services. Notwithstanding the foregoing,
              any materials available for downloading, access, or other use via our Services that have their own license terms, conditions, and
              notices will be governed by such terms, conditions, and notices. Swing reserves the right to determine the timing and content of
              software updates for our Services which, if applicable, may be automatically downloaded and installed without prior notice to you. You
              acknowledge that Swing may establish general practices and limits concerning use of our Services including without limitation the
              maximum period of time that data or other content will be retained by our Services or maximum usage times for our Services and maximum
              storage space that will be allotted on Swing’s servers on your behalf. You agree that Swing has no responsibility or liability for the
              deletion or failure to store any data or other content maintained or uploaded to our Services. To the extent applicable, you acknowledge
              that Swing reserves the right to terminate accounts that are inactive for an extended period of time. You further acknowledge that Swing
              reserves the right to change these general practices and limits at any time, in its sole discretion, with or without notice.
            </p>
          </Row>
          <Row>
            <h6>12. No Support or Maintenance</h6>
          </Row>
          <Row>
            <p>
              You acknowledge and agree that Swing will have no obligation to provide you with any support or maintenance in connection with the
              Services.
            </p>
          </Row>
          <Row>
            <h6>13. Mobile data fees may apply</h6>
          </Row>
          <Row>
            <p>
              You acknowledge that use of our Services may count against your mobile device’s data plan. Standard data rates from your wireless
              provider may apply.
            </p>
          </Row>
          <Row>
            <h6>14. External and Third-Party Services</h6>
          </Row>
          <Row>
            <p>
              Our Services may enable access to Swing and/or third-party services and sites (collectively and individually, “External Services”). You
              agree to use the External Services at your sole risk. Swing is not responsible for examining or evaluating the content or accuracy of
              any third-party External Services, and shall not be liable for any such third-party External Services. Data displayed by our Services or
              any External Service, including but not limited to financial, medical, and location information, is for general informational purposes
              only and is not guaranteed by Swing or its agents. You will not use the External Services in any manner that is inconsistent with these
              Terms or that infringes the intellectual property rights of Swing or any third party. You agree not to use the External Services to
              harass, abuse, stalk, threaten, or defame any person or entity, and that Swing is not responsible for any such use. External Services
              may not be available in all languages or in your home country, and may not be appropriate or available for use in any particular
              location. To the extent you choose to use such External Services, you are solely responsible for compliance with any applicable laws.
              Swing reserves the right to change, suspend, remove, disable, or impose access restrictions or limits on any External Services at any
              time without notice or liability to you.
            </p>
          </Row>
          <Row>
            <h6>15. Other Users</h6>
          </Row>
          <Row>
            <p>
              Each user of the Services is solely responsible for any and all of its own User Content. Because we do not control User Content, you
              acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others. We make no guarantees
              regarding the accuracy, currency, suitability, or quality of any User Content.
            </p>
          </Row>
          <Row>
            <h6>16. Release</h6>
          </Row>
          <Row>
            <p>
              You hereby release and forever discharge the Swing (and our officers, employees, agents, successors, and assigns) from, and hereby waive
              and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and
              cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly
              or indirectly out of, or that relates directly or indirectly to, the Services (including any interactions with, or act or omission of,
              other Services users or any External Services). If you are a California resident, you hereby waive California Civil Code 1542 in
              connection with the foregoing, which states, “A general release does not extend to claims that the creditor or releasing party does not
              know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have
              materially affected his or her settlement with the debtor or released party.”
            </p>
          </Row>
          <Row>
            <h6>17. No Warranty</h6>
          </Row>
          <Row>
            <p>
              YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK, THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND SWING (AND OUR
              SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL
              WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE
              (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY,
              SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. NO ADVICE
              OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM SWING OR THROUGH SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. YOU
              ACKNOWLEDGE AND AGREE THAT THE INFORMATION ON OUR SERVICES MAY NOT REFLECT THE MOST RECENT RESEARCH AND THAT YOUR RELIANCE ON ANY
              INFORMATION CONTAINED ON THE SERVICES IS AT YOUR SOLE RISK. YOU ACKNOWLEDGE AND AGREE THAT SWING IS NOT LIABLE, AND YOU AGREE NOT TO
              SEEK TO HOLD SWING LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING MEDICAL PROFESSIONALS, OPERATORS OF EXTERNAL SITES OR OTHER USERS
              OF THE SERVICES, AND THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH YOU. IF APPLICABLE LAW REQUIRES ANY WARRANTIES
              WITH RESPECT TO THE SERVICES, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE. SOME
              JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT
              ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
            </p>
          </Row>
          <Row>
            <h6>18. Limitation of liability</h6>
          </Row>
          <Row>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL SWING (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST
              PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL DAMAGES ARISING
              FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SERVICES, EVEN IF SWING HAS BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
              TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.
            </p>
          </Row>
          <Row>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES
              ARISING FROM OR RELATED TO THESE TERMS (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED
              TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $100). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR
              SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THESE TERMS.
            </p>
          </Row>
          <Row>
            <p>THE FOREGOING LIMITATIONS OF LIABILITY APPLY TO THE FULLEST EXTENT ALLOWED BY LAW.</p>
          </Row>
          <Row>
            <p>THE LIMITATIONS ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BARGAIN BETWEEN THE USER AND THE COMPANY.</p>
          </Row>
          <Row>
            <h6>19. Indemnity</h6>
          </Row>
          <Row>
            <p>
              You agree to indemnify and hold Swing, its existing and future officers, directors, shareholders, predecessors, successors in interest,
              employees, agents, subsidiaries, and affiliates harmless from any demands, loss, liability, claims, or expenses (including attorneys’
              fees) made against Swing due to or arising out of or in connection with your use of our Services or your User Content, as applicable,
              your violation of any applicable laws, rules, or regulations, your violation of the Terms, your violation of another party (including
              any other registered users of the Services), or your breach of any warranties made by you hereunder or your violation of any other
              provision of these Terms. Swing reserves the right to assume control of the defense of any third-party claim that is subject to
              indemnification by you, in which event you agree to reasonably cooperate with Swing in asserting any available defenses.
            </p>
          </Row>
          <Row>
            <p>
              This provision does not require you to indemnify Swing, its existing and future officers, directors, shareholders, predecessors,
              successors in interest, employees, agents, subsidiaries, and affiliates for any unconscionable commercial practice by such party or fore
              such party’s fraud, deception, false promise, misrepresentation or concealment, or suppression or omission of any material fact in
              connection with the website or any Services provided hereunder. You agree that the provisions in this section will survive any
              termination of your Account, the Agreement and/or your access to the Services.
            </p>
          </Row>
          <Row>
            <h6>20. Export</h6>
          </Row>
          <Row>
            <p>
              You may not use or otherwise export or re-export our Services except as authorized by United States law and the laws of the jurisdiction
              in which our Services was obtained. In particular, but without limitation, our Services may not be exported or re-exported (a) into any
              U.S.-embargoed countries or (b) to anyone on the U.S. Treasury Department’s Specially Designated Nationals List or the U.S. Department
              of Commerce Denied Persons List or Entity List. By using our Services you represent and warrant that you are not located in any such
              country or on any such list. You also agree that you will not use these products for any purposes prohibited by United States law.
            </p>
          </Row>
          <Row>
            <h6>21. Commercial Items</h6>
          </Row>
          <Row>
            <p>
              Our Services and related documentation are “Commercial Items”, as that term is defined at 48 C.F.R. §2.101, consisting of “Commercial
              Computer Software” and “Commercial Computer Software Documentation”, as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202,
              as applicable. Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer
              Software and Commercial Computer Software Documentation are being licensed to U.S. Government end users (a) only as Commercial Items and
              (b) with only those rights as are granted to all other end users pursuant to the terms and conditions herein. Unpublished-rights
              reserved under the copyright laws of the United States.
            </p>
          </Row>
          <Row>
            <h6>22. Governing Law and Dispute Resolution</h6>
          </Row>
          <Row>
            <p>
              THE TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED AND INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF CALIFORNIA, WITHOUT
              GIVING EFFECT TO ANY PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANOTHER JURISDICTION. THE UNITED NATIONS CONVENTION ON
              CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS DOES NOT APPLY TO THE AGREEMENT.
            </p>
          </Row>
          <Row>
            <h6>23. Term and Termination</h6>
          </Row>
          <Row>
            <p>
              Subject to this Section, these Terms will remain in full force and effect while you use the Services. We may suspend or terminate your
              rights to use the Services (including your Account) at any time for any reason at our sole discretion, including for any use of the
              Services in violation of these Terms. Upon termination of your rights under these Terms, your Account and right to access and use the
              Services will terminate immediately. You understand that any termination of your Account may involve deletion of your User Content
              associated with your Account from our live databases. Swing will not have any liability whatsoever to you for any termination of your
              rights under these Terms, including for termination of your Account or deletion of your User Content. Even after your rights under these
              Terms are terminated, the following provisions of these Terms will remain in effect: Sections 4, 7-9 and 12-25.
            </p>
          </Row>
          <Row>
            <h6>24. International issues</h6>
          </Row>
          <Row>
            <p>
              Swing administers and operates our Services from our locations in the United States. Our Services are only available for use within the
              United States of America. Information available via our Services may contain references to Swing products, programs, and services that
              are not available in your country. Such reference does not imply that Swing intends to make such products, programs, or services
              available in your country. Any offer on our Services for any feature, product, or service is void where prohibited. If you choose to
              access our Services from outside the United States, you are responsible for complying with applicable local laws. Certain text in our
              Services may be available in languages other than English; any such translations are provided as a convenience, and Swing makes no
              representations or commitments regarding the accuracy or completeness of any such translation.
            </p>
          </Row>
          <Row>
            <h6>25. Electronic Communications.</h6>
          </Row>
          <Row>
            <p>
              The communications between you and Swing may take place via electronic means, whether you visit or use Services or send Swing e-mails,
              or whether Swing posts notices on or through the Services or communicates with you via e- mail. For contractual purposes, you (a)
              consent to receive communications from Swing in an electronic form; and (b) agree that all terms and conditions, agreements, notices,
              disclosures, and other communications that Swing provides to you electronically satisfy any legal requirement that such communications
              would satisfy if it were to be in writing. The foregoing does not affect your statutory rights, including but not limited to the
              Electronic Signatures in Global and National Commerce Act at 15 U.S.C. §7001 et seq. (“E-Sign”).
            </p>
          </Row>
          <Row>
            <h6>26. Force Majeure.</h6>
          </Row>
          <Row>
            <p>
              Swing shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including, but not
              limited to, acts of God, war, terrorism, riots, pandemics, epidemics, embargos, acts of civil or military authorities, fire, floods,
              accidents, strikes or shortages of transportation facilities, fuel, energy, labor or materials.
            </p>
          </Row>
          <Row>
            <h6>27. Notice.</h6>
          </Row>
          <Row>
            <p>
              Where Swing requires that you provide an e-mail address, you are responsible for providing Swing with your most current e-mail address.
              In the event that the last e-mail address you provided to Swing is not valid, or for any reason is not capable of delivering to you any
              notices required/ permitted by the Agreement, Swing’s dispatch of the e-mail containing such notice will nonetheless constitute
              effective notice. You may give notice to Swing at the following address: support@swingtherapeutics.com. Such notice shall be deemed
              given when received by Swing by letter delivered by nationally recognized overnight delivery service or first class postage prepaid mail
              at the above address.
            </p>
          </Row>
          <Row>
            <h6>28. General terms</h6>
          </Row>
          <Row>
            <p>
              If a court or other tribunal of competent jurisdiction holds any of the provisions of these Terms to be void or unenforceable, such
              provisions shall be limited or eliminated to the minimum extent necessary and replaced with a valid provision that best embodies the
              intent of these Terms, so that these Terms shall remain in full force and effect. These Terms and our Privacy Policy for our Services
              constitute the entire agreement between you and Swing with regard to your use of our Services and any and all other written or oral
              agreements or understandings previously existing between you and Swing with respect to such use are hereby superseded and cancelled.
              Swing’s failure to insist on or enforce strict performance of these Terms shall not be construed as Swing’s waiver of any provision or
              any right it has to enforce these Terms, nor shall any course of conduct between Swing and you or any other party be deemed to modify
              any provision of these Terms. These Terms shall not be interpreted or construed to confer any rights or remedies on any third parties.
              Swing customer service representatives are not authorized to modify any provision of these terms, either verbally or in writing.
            </p>
          </Row>
          <Row>
            <p>
              You may not assign or transfer these Terms, by operation of law or otherwise, without Swing’s prior written consent. Any attempt by you
              to assign or transfer these Terms, without such consent, will be null and void. Swing may freely assign or transfer these Terms without
              restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors, and their
              permitted assigns.
            </p>
          </Row>
          <Row>
            <h6>29. What if Swing changes these Terms?</h6>
          </Row>
          <Row>
            <p>
              If we materially change these Terms, we will notify you by posting a notice on our Services. Your continued use of our Services
              following any notification of changes to these Terms will mean that you accept and agree to the revised Terms.
            </p>
          </Row>
          <Row>
            <h6>30. Contact Information</h6>
          </Row>
          <Row>
            <p>
              Address:
              <br />
              Swing Therapeutics
              <br />
              Attn: Terms of Use
              <br />
              353 Sacramento St, Suite 1803
              <br />
              San Francisco, California
              <br />
              941111 US
              <br />
              Phone:+1 619-332-0553
              <br />
              Email: <a href='mailto:support@swingtherapeutics.com'>support@swingtherapeutics.com</a>
            </p>
          </Row>
          <Row>
            <p>
              {moment().format('MMMM YYYY')} Swing Therapeutics, Inc. All rights reserved. All trademarks, logos and service marks (“Marks”) displayed
              in our Services are Swing’s property or the property of other third parties. You are not permitted to use these Marks without our prior
              written consent or the consent of such third party which may own the Marks.
            </p>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default Terms;
