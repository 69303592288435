import classNames from 'classnames';
import React from 'react';
import { BackIcon } from '../../../assets/icons/svgicons';

interface BackButtonProps {
  height?: string;
  width?: string;
  onClick?: () => void;
  className?: string;
  flip?: boolean;
}

const BackButton: React.FC<BackButtonProps> = ({ height = '20px', width = '20px', onClick, className, flip }) => {
  return (
    <div className={classNames('hoverable', className)} style={{ height: height, width: width }} onClick={() => onClick?.()}>
      <BackIcon flip={flip} />
    </div>
  );
};

export default BackButton;
