import React from 'react';
import { Question } from '@swing-therapeutics/surveybay/dist/types';
import { Col, Progress, Row } from 'reactstrap';
import styles from './FormProgressBar.module.scss';

interface FormProgressBarProps {
  questions: Question[];
}

const getQuestionProgress = (questions: Question[]): { questionsAnswered: number; totalQuestions: number; percentAnswered: number } => {
  let questionsAnswered = 0;
  let totalQuestions = 0;

  questions.forEach((question) => {
    if (question.answered) {
      totalQuestions += 1;
      questionsAnswered += 1;
    } else if (!question.skipped) {
      // Dont count a skipped question in the total
      totalQuestions += 1;
    }
  });

  return {
    questionsAnswered,
    totalQuestions,
    percentAnswered: questionsAnswered / totalQuestions,
  };
};

const FormProgressBar: React.FC<FormProgressBarProps> = ({ questions }) => {
  const { questionsAnswered, totalQuestions, percentAnswered } = getQuestionProgress(questions);

  return (
    <Col>
      <Row>
        <Col xs='autp'>
          <h6>Progress</h6>
        </Col>
        <Col xs='auto'>
          <h6>
            {questionsAnswered} out of {totalQuestions}
          </h6>
        </Col>
      </Row>
      <Row>
        <Progress max={1} value={percentAnswered} className={styles.progressbar} color={percentAnswered === 1 ? 'success' : 'primary'} />
      </Row>
    </Col>
  );
};

export default FormProgressBar;
