import { WorkflowID } from '@swing-therapeutics/surveybay/dist/types';
import { DocumentData, DocumentSnapshot, firestore } from '../../utils/firebase';
import { RequestError } from '../RequestError';
import { User } from '../User';
import { SurveyMetaAbstract } from './SurveyMetaAbstract';

const findEligibilityDoc = (userUID: string) => {
  return firestore.collection(`users/${userUID}/workflows`).where('workflowID', '==', WorkflowID.ELIGIBILITY);
};

export class EligibilityMeta extends SurveyMetaAbstract {
  getFirestoreData() {
    return {
      eligibilitySurveys: this.surveys,
    };
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData>): EligibilityMeta {
    const docData = docSnap.data();

    return new EligibilityMeta({ ...docData }, docSnap.ref.path);
  }

  static async fromUser(user: User): Promise<EligibilityMeta | RequestError> {
    let doc: DocumentSnapshot<DocumentData>;
    try {
      const collection = await findEligibilityDoc(user.uid).get();
      doc = collection.docs[0];
    } catch (error) {
      return new RequestError(error, 'Eligibility meta doc retrieval');
    }
    if (!doc?.exists) {
      return new RequestError(new Error('Eligibility doc doesnt exist for user'), 'Eligibility doc retrieval');
    } else {
      return this.fromFirestore(doc);
    }
  }

  static async fromUserSubscribe(user: User, changeCallback: (eligibilityMeta: EligibilityMeta) => void): Promise<() => void> {
    try {
      const collection = await findEligibilityDoc(user.uid).get();
      const doc = collection.docs[0];
      return firestore.doc(`users/${user.uid}/workflows/${doc.id}`).onSnapshot((snap) => {
        if (!snap.exists) {
          return;
        }
        changeCallback(new EligibilityMeta(snap.data(), snap.ref.path));
      });
    } catch (error) {
      console.log(error);
      return () => {};
    }
  }
}
