import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { UserStatus, WorkflowType, WorkflowStatusMap } from '@swing-therapeutics/surveybay/dist/types';
import { RequestError } from '../../models/RequestError';
import { CreateAccountInfo, User } from '../../models/User';
import { auth, DocumentData, DocumentSnapshot, firestore, functions } from '../firebase';
import { clearZenDeskWidget, prefillZenDeskWidget } from '../functions/Zendesk';
import { firebase } from '../firebase';

interface UserState {
  user: User | null | 'FIRSTLOAD';
  landingPageKey: string;
  inExtension: boolean;
  privateRelay: boolean;
  signInWithApple: any;
}

const initialUserState: UserState = {
  user: 'FIRSTLOAD', // First time context loads, auth is checking for an existing session
  // Default landing page key (study) for users
  // If a user navigates to an invalid landing page key (doesnt exist) then this lp key will be used
  landingPageKey: '',
  inExtension: false,
  privateRelay: false,
  signInWithApple: () => {},
};

// User state when a user is signed out
const signedOutUserState: Partial<UserState> = {
  inExtension: false,
  user: null,
};

type ActionTypes = { type: 'SIGNOUT' } | { type: 'CREATEACCOUNT'; payload: CreateAccountInfo } | { type: 'SETLPKEY'; payload: string };

interface UserStateHookReturn {
  userState: UserState;
  userStateDispatch: (action: ActionTypes) => Promise<void>;
  loading: boolean;
  privateRelay: boolean;
  signInWithApple: any;
}

// Hook to be wrapped in context to provide user state app wide
const useUserState = (): UserStateHookReturn => {
  const [userState, setUserState] = useState({ ...initialUserState });
  const [loading, setLoading] = useState(false);
  const [privateRelay, setPrivateRelay] = useState(false);
  const [emailFromApple, setEmailFromApple] = useState('');
  // Store the unsub function to be called on dismount of app
  const unsubUserListener = useRef<() => void>();

  const appleProvider = new firebase.auth.OAuthProvider('apple.com');
  appleProvider.addScope('email');
  appleProvider.addScope('name');

  const signInWithApple = () => {
    firebase
      .auth()
      .signInWithPopup(appleProvider)
      .then((res) => {
        //@ts-ignore thinks email doen't exist
        if (res?.additionalUserInfo?.profile?.email) {
          //@ts-ignore
          setEmailFromApple(res?.additionalUserInfo?.profile?.email);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkEligibleForExtension = useCallback(async (user: User) => {
    // If the user is in the done status, check if the user should be moved to extension eligible
    if (user.workflowStatus !== UserStatus.DONE) return;
    // This is function exists to catch if an extension doesnt exist when a user gets to the done status
    // But later it now exists, so when they sign in this check will run and handle if they should move to ext eligible
    const ssEnrollInExtension = functions.httpsCallable('ssEnrollInExtension');
    // The check eligible param just returns if eligible, doesnt actually enroll in extension
    const result = await ssEnrollInExtension({ checkEligible: true });
    if (result.data.eligible) {
      // move them to extension
      await firestore.doc(`users/${user.uid}`).update({
        workflowStatus: UserStatus.EXT_ELIGIBLE,
      });
    }
  }, []);

  useEffect(() => {
    // Run code based on user's state
    if (userState.user !== 'FIRSTLOAD' && userState.user) {
      // User signed in
      prefillZenDeskWidget(userState.user.displayName, userState.user.email);
      checkEligibleForExtension(userState.user);
    } else if (userState.user !== 'FIRSTLOAD' && !userState.user) {
      // User is not signed in
      clearZenDeskWidget();
    }
  }, [userState, checkEligibleForExtension]);

  // Returns true of false based on if the user exists in the firestore users collection
  const setUserFromUID = useCallback(async (uid: string): Promise<boolean> => {
    let userDoc;
    try {
      userDoc = await firestore.collection('users').doc(uid).get();
    } catch (error) {
      new RequestError(error, 'User doc retrieval');
      // Handle request error here
    }
    if (userDoc?.exists) {
      // Member exists in firestore users collection
      // Listen for changes to the user's doc on firestore
      unsubUserListener.current?.();
      unsubUserListener.current = userDoc.ref.onSnapshot(
        (snap) => {
          if (!snap.exists) {
            // Users doc was removed, sign them out
            console.warn("User's doc does not exist, signing current user out");
            auth.signOut();
            unsubUserListener.current?.();
          } else {
            // User's doc exists, set the user
            const user = User.fromFirestore(snap as DocumentSnapshot<DocumentData>);
            setUserState((prevState) => ({
              ...prevState,
              // If a user doesnt have a landing page key keep the previously set one
              landingPageKey: user.landingPageKey ? user.landingPageKey : prevState.landingPageKey,
              inExtension: WorkflowStatusMap[user.workflowStatus] === WorkflowType.EXTENSION,
              user,
            }));
          }
        },
        (error) => {
          // Permission error is expected when signing out
          // On sign out, onAuthStateChanged will handle setting user state
          if (error.code !== 'permission-denied') {
            // No other errors expected so display this error in console
            console.error(error);
            setUserState((prevState) => ({
              ...prevState,
              ...signedOutUserState,
            }));
          }
          unsubUserListener.current?.();
        },
      );
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    // Listen to auth state changes
    const unsubscribeAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth?.email) {
        if (userAuth.email.match(/@privaterelay.appleid.com/)) {
          setPrivateRelay(true);
          if (emailFromApple && !emailFromApple.match(/@privaterelay.appleid.com/)) {
            // update the users email to the new email under providerData
            userAuth.updateEmail(emailFromApple);
            setPrivateRelay(false);
          }
        } else {
          setPrivateRelay(false);
        }
      }
      if (userAuth && userAuth.uid) {
        if (!(await setUserFromUID(userAuth.uid))) {
          // Member does not exist in firestore users collection
          // Create the user from the userAuth with a workflow status of CREATING
          const user = User.fromUserAuth(userAuth);
          setUserState((prevState) => ({
            ...prevState,
            user,
          }));
        }
      } else {
        auth.signOut();
        setPrivateRelay(false);
        setUserState((prevState) => ({
          ...prevState,
          ...signedOutUserState,
        }));
      }
    });

    return () => {
      unsubscribeAuth();
      unsubUserListener.current?.();
    };
  }, [emailFromApple, setUserFromUID]);

  // Wrapper for the setState to perform async calls to API and update state when completed
  const userStateDispatch = async (action: ActionTypes) => {
    switch (action.type) {
      case 'SIGNOUT':
        /*
          Perform the users sign out
        */
        auth.signOut();
        return;
      case 'CREATEACCOUNT':
        /*
          Create the user account
        */
        if (userState.user !== 'FIRSTLOAD' && userState.user) {
          setLoading(true);
          const status = await User.registerUser(userState.user, action.payload);
          if (!(status instanceof RequestError)) {
            await setUserFromUID(userState.user.uid);
          }
          // Add a small delay to prevent flicker of signup form
          // Assigning the snapshot to the userState takes a little extra time,
          // due to onSnapShot not returning a promise so we don't know when it completes
          await new Promise((resolve) => setTimeout(resolve, 500));
          setLoading(false);
        }
        return;
      case 'SETLPKEY':
        /*
          Set the landing page key for the user's session
        */
        if (userState.user && userState.user !== 'FIRSTLOAD' && userState.user.workflowStatus !== UserStatus.CREATING) {
          // Prevent the landing page key piece of state from changing once a user has created an account
          // This forces the landing page key value to be the same as whats on the user's document
          console.warn('Cannot change landing page key once a user has created an account');
          return;
        }
        setUserState((prevState) => ({
          ...prevState,
          landingPageKey: action.payload,
        }));
        return;
      default:
        // This will never happen thanks to ts
        return;
    }
  };

  return {
    userState,
    userStateDispatch,
    loading,
    privateRelay,
    signInWithApple,
  };
};

const UserStateContext = createContext({} as UserStateHookReturn);

export const UserStateProvider: React.FC = ({ children }) => {
  return <UserStateContext.Provider value={useUserState()}>{children}</UserStateContext.Provider>;
};

export const UserStateConsumer = UserStateContext.Consumer;
export default UserStateContext;
