import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import { useSurveyMessages } from '../../../utils/Hooks';
import styles from './SurveyMessage.module.scss';

/*
 * Display new messages from the surveyMessages collection
 */
const SurveyMessage: React.FC = () => {
  const newMessage = useSurveyMessages();
  const [message, setMessage] = useState('');
  const [grow, setGrow] = useState(false);
  const to = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearSetTimeout = useCallback(() => {
    // Clear the set timeout if it exists
    const _to = to.current;
    if (_to) {
      clearTimeout(_to);
    }
  }, []);

  useEffect(() => {
    if (newMessage) {
      setMessage(newMessage);
      setGrow(true);
      to.current = setTimeout(() => {
        setGrow(false);
        // Hide after 25 seconds
      }, 25000);
    }
    return clearSetTimeout;
  }, [newMessage, clearSetTimeout]);

  const handleCloseClick = useCallback(() => {
    clearSetTimeout();
    setGrow(false);
  }, [clearSetTimeout]);

  return (
    <div className={classNames(styles.messagecontainer, grow ? styles.grow : styles.shrink)}>
      <CloseButton className={classNames('hoverable', styles.closebutton)} onClick={handleCloseClick} />
      <p>{message}</p>
    </div>
  );
};

export default SurveyMessage;
