import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Container, Fade, Row } from 'reactstrap';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import styles from './Landing.module.scss';
import Section from '../../components/common/Section';
import ReferralGroup from '../../models/ReferralGroup';
import { RequestError } from '../../models/RequestError';
import { usePageLog } from '../../utils/analytics';
import LandingPageStyling from '../../models/landing/LandingPageStyling';
interface PageParams {
  referralGroupKey?: string;
  landingPageKey: string;
}

const Landing: React.FC<RouteComponentProps<PageParams>> = ({ match }) => {
  usePageLog({ pageTitle: 'Welcome' });
  const [referralGroup, setReferralGroup] = useState<ReferralGroup>();
  const history = useHistory();
  const isMounted = useRef(true);
  const [landingPage, setLandingPage] = useState<LandingPageStyling>();

  const fetchAndSetLandingPage = useCallback(async (landingKey) => {
    const landing = await LandingPageStyling.fetchLandingPage(landingKey);
    if (landing instanceof RequestError || isMounted.current === false) return;
    setLandingPage(landing);
  }, []);

  const fetchAndSetReferralGroup = useCallback(
    async (referralGroupKey) => {
      const referralGroup = await ReferralGroup.fetchReferralGroup(referralGroupKey);
      if (referralGroup instanceof RequestError) {
        return;
      }
      isMounted.current && setReferralGroup(referralGroup);
    },
    [isMounted],
  );

  useEffect(() => {
    isMounted.current = true;
    if (match.params.referralGroupKey) {
      fetchAndSetReferralGroup(match.params.referralGroupKey);
    }
    if (match.params.landingPageKey) {
      fetchAndSetLandingPage(match.params.landingPageKey);
    }
    return () => {
      isMounted.current = false;
    };
  }, [match.params, fetchAndSetReferralGroup, fetchAndSetLandingPage]);

  useEffect(() => {
    if (history.location.hash) {
      const element = document.getElementById(history.location.hash);
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history.location.hash]);

  const sectionOneTitleColor = landingPage?.sectionOne.title.color || '#5A9893';
  const sectionOneTitleStyle = { color: sectionOneTitleColor };
  const sectionOneSubtitleColor = landingPage?.sectionOne.subtitle.color || '#000000';
  const sectionOneSubtitleStyle = { color: sectionOneSubtitleColor };

  const sectionTwoTitleColor = landingPage?.sectionTwo.title.color || '#5A9893';
  const sectionTwoTitleStyle = { color: sectionTwoTitleColor };
  const sectionTwoSubtitleColor = landingPage?.sectionTwo.subtitle.color || '#000000';
  const sectionTwoSubtitleStyle = { color: sectionTwoSubtitleColor };

  const sectionTwoSubsectionOneTitleColor = landingPage?.sectionTwo.subsectionOne.title.color || '#5A9893';
  const sectionTwoSubsectionOneTitleStyle = { color: sectionTwoSubsectionOneTitleColor };
  const sectionTwoSubsectionOneSubtitleColor = landingPage?.sectionTwo.subsectionOne.subtitle.color || '#000000';
  const sectionTwoSubsectionOneSubtitleStyle = { color: sectionTwoSubsectionOneSubtitleColor };

  const sectionTwoSubsectionTwoTitleColor = landingPage?.sectionTwo.subsectionTwo.title.color || '#5A9893';
  const sectionTwoSubsectionTwoTitleStyle = { color: sectionTwoSubsectionTwoTitleColor };
  const sectionTwoSubsectionTwoSubtitleColor = landingPage?.sectionTwo.subsectionTwo.subtitle.color || '#000000';
  const sectionTwoSubsectionTwoSubtitleStyle = { color: sectionTwoSubsectionTwoSubtitleColor };

  const sectionTwoSubsectionThreeTitleColor = landingPage?.sectionTwo.subsectionThree.title.color || '#5A9893';
  const sectionTwoSubsectionThreeTitleStyle = { color: sectionTwoSubsectionThreeTitleColor };
  const sectionTwoSubsectionThreeSubtitleColor = landingPage?.sectionTwo.subsectionThree.subtitle.color || '#000000';
  const sectionTwoSubsectionThreeSubtitleStyle = { color: sectionTwoSubsectionThreeSubtitleColor };

  return (
    <>
      <div id='#main' />
      <Section>
        <Container>
          <div style={{ paddingTop: 124, paddingBottom: 34 }}>
            <p style={{ fontStyle: '', fontFamily: 'sans-serif', fontSize: '1rem', fontWeight: 500, lineHeight: 1.5, color: 'black' }}>
              Thank you for your interest in the REACT-FM study! However, we are sorry to inform you that{' '}
              <span style={{ fontWeight: 'bold' }}> this study is no longer recruiting </span> new participants.
              <br />
              <br />
              We’d like to let you know that <em>Stanza</em>, the digital treatment studied in REACT-FM, has been cleared by FDA to market in the US.
              It is currently offered by <em>Swing Care</em>, a telehealth fibromyalgia specialty clinic for patients in select states. If you are
              located in one of the states listed on our website and are interested in this treatment, please click this{' '}
              <a style={{ textDecoration: 'underline' }} href='https://www.swing.care'>
                link
              </a>{' '}
              to learn more and book a free informational call. If you are not located in the states we currently operate in, please know that{' '}
              <em>Swing Care</em> is coming to more states in the near future.
              <br />
              <br />
              Visit the link below to sign up for our communication email list and stay current on the new states <em>Swing Care</em> expands to:{' '}
              <br />
              <a style={{ textDecoration: 'underline' }} href='https://www.swing.care/#open-signup-modal'>
                https://www.swing.care/#open-signup-modal
              </a>
            </p>
          </div>
        </Container>
      </Section>
      <Layout header={{ title: landingPage?.header.title.text, subtitle: landingPage?.header.subtitle.text }}>
        <Section>
          <Container>
            {referralGroup ? (
              <Fade in={true}>
                <Row className='justify-content-center'>
                  <h4 className='mb-0 text-center text-primary'>Affiliated with</h4>
                </Row>
                <Row className='justify-content-center'>
                  <h2 className='mb-0 text-center text-primary'>{referralGroup.name}</h2>
                </Row>
                <Row className='justify-content-center mb-2'>
                  <img src={referralGroup.logoURL} className={styles.logoimg} alt='Referral group logo' />
                </Row>
              </Fade>
            ) : (
              <Row className='my-5' />
            )}
            <Row className='my-4'>
              <Col lg='6' className='px-5'>
                <Row className='justify-content-center'>
                  <img src={landingPage?.sectionOne.banner} className={styles.phoneimg} alt='Tempo app' />
                </Row>
              </Col>
              <Col lg='6' className='px-5 mt-4 mt-lg-0'>
                <Row id='#about'>
                  <h2 style={sectionOneTitleStyle}>{landingPage?.sectionOne.title.text}</h2>
                </Row>
                <Row style={sectionOneSubtitleStyle}>{landingPage?.sectionOne.subtitle.text}</Row>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Row className='my-4 justify-content-center'>
              <h2 className='text-center' style={sectionTwoTitleStyle} id='#whyparticipate'>
                {landingPage?.sectionTwo.title.text}
              </h2>
            </Row>
            <Row className='justify-content-center'>
              <p className='text-center' style={sectionTwoSubtitleStyle}>
                {landingPage?.sectionTwo.subtitle.text}
              </p>
            </Row>
            <Row>
              <Col lg='4' className='p-5'>
                <Row className='justify-content-center mb-4'>
                  {landingPage && <img src={landingPage?.sectionTwo.subsectionOne.svg} height='79px' width='73px' />}
                </Row>
                <Row className='justify-content-center mb-2'>
                  <h6 className='text-center' style={sectionTwoSubsectionOneTitleStyle}>
                    <b>{landingPage?.sectionTwo.subsectionOne.title.text}</b>
                  </h6>
                </Row>
                <Row className='justify-content-center'>
                  <p className='text-center' style={sectionTwoSubsectionOneSubtitleStyle}>
                    {landingPage?.sectionTwo.subsectionOne.subtitle.text}
                  </p>
                </Row>
              </Col>
              <Col lg='4' className='p-5'>
                <Row className='justify-content-center mb-4'>
                  {landingPage && <img src={landingPage?.sectionTwo.subsectionTwo.svg} height='79px' width='73px' />}
                </Row>
                <Row className='justify-content-center mb-2'>
                  <h6 className='text-center' style={sectionTwoSubsectionTwoTitleStyle}>
                    <b>{landingPage?.sectionTwo.subsectionTwo.title.text}</b>
                  </h6>
                </Row>
                <Row className='justify-content-center'>
                  <p className='text-center' style={sectionTwoSubsectionTwoSubtitleStyle}>
                    {landingPage?.sectionTwo.subsectionTwo.subtitle.text}
                  </p>
                </Row>
              </Col>
              <Col lg='4' className='p-5'>
                <Row className='justify-content-center mb-4'>
                  {landingPage && <img src={landingPage?.sectionTwo.subsectionThree.svg} height='79px' width='73px' />}
                </Row>
                <Row className='justify-content-center mb-2'>
                  <h6 className='text-center' style={sectionTwoSubsectionThreeTitleStyle}>
                    <b>{landingPage?.sectionTwo.subsectionThree.title.text}</b>
                  </h6>
                </Row>
                <Row className='justify-content-center'>
                  <p className='text-center' style={sectionTwoSubsectionThreeSubtitleStyle}>
                    {landingPage?.sectionTwo.subsectionThree.subtitle.text}
                  </p>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    </>
  );
};

export default Landing;
