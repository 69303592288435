import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import './theme/assets/vendor/nucleo/css/nucleo.css';
import './theme/assets/vendor/font-awesome/css/font-awesome.min.css';
import './styling/ThemeOverrides.css';
import './styling/App.scss';

import { UserStateProvider } from './utils/contexts/UserContext';
import ProtectedRoute, { ProtectionTypes } from './utils/routing/ProtectedRoute';
import Landing from './views/Landing';
import SignIn from './views/SignIn';
import AnswerSurvey from './views/Surveys/AnswerSurvey';
import Consent from './views/Consent';
import FailPage from './views/FailPage';
import Profile from './views/Profile';
import LandingPageRoute from './utils/routing/LandingPageRoute';
import Terms from './views/Terms';
import Privacy from './views/Privacy';
import PageNotFound from './views/PageNotFound';
import { loadZenDeskWidget } from './utils/functions/Zendesk';
import { usePageTopOnNav } from './utils/Hooks';
import WelcomeRedirect from './utils/routing/WelcomeRedirect';
import ExtLearnMore from './views/ExtLearnMore';
import ScheduleACall from './views/ScheduleACall';
import { ContentProvider } from './utils/contexts/ContentContext';
import FAQ from './views/FAQ';

const App: React.FC = () => {
  // Navigate to top of page when the page changes
  usePageTopOnNav();

  useEffect(() => {
    loadZenDeskWidget();
  }, []);

  return (
    <ContentProvider>
      <UserStateProvider>
        <Switch>
          <LandingPageRoute path='/welcome/:landingPageKey/:referralGroupKey?' landingPageKeyIndex={2} exact component={Landing} />
          <Route path='/' exact component={WelcomeRedirect} />
          <Route path='/welcome' exact component={WelcomeRedirect} />
          <LandingPageRoute path='/signin/:landingPageKey/:referralGroupKey?' landingPageKeyIndex={2} exact component={SignIn} />
          <Route path='/terms' exact component={Terms} />
          <Route path='/faq/:landingPageKey/' exact component={FAQ} />
          <Route path='/privacy' exact component={Privacy} />
          <ProtectedRoute
            path='/surveys/:surveyType' // Should be either baseline | eligibility | therapy
            addedProtection={ProtectionTypes.WORKFLOW}
            exact
            component={AnswerSurvey}
          />
          <ProtectedRoute path='/consent' addedProtection={ProtectionTypes.WORKFLOW} exact component={Consent} />
          {/* This route catches anyone that navs to /surveys and redirects them to the proper survey page */}
          {/* A good route to redirect a user to so they end up on the right workflow page */}
          <ProtectedRoute path='/surveys' addedProtection={ProtectionTypes.WORKFLOW} />
          <ProtectedRoute path='/noteligible' addedProtection={ProtectionTypes.ACCOUNT_EXISTS} component={FailPage} />
          <ProtectedRoute path='/schedulecall' addedProtection={ProtectionTypes.ACCOUNT_EXISTS} component={ScheduleACall} />
          <ProtectedRoute path='/learnmore' addedProtection={ProtectionTypes.WORKFLOW} component={ExtLearnMore} />
          <ProtectedRoute path='/profile' addedProtection={ProtectionTypes.WORKFLOW} component={Profile} />
          <Route path='/404' component={PageNotFound} />
          <Redirect to='/404' />
        </Switch>
      </UserStateProvider>
    </ContentProvider>
  );
};

export default App;
