import { useEffect } from 'react';
import { analytics } from '../firebase';

interface PageLogParams {
  pageTitle: string;
}

// Log when a page is navigated to
const usePageLog = ({ pageTitle }: PageLogParams) => {
  useEffect(() => {
    const pageViewData = {
      page_path: window.location.pathname,
      page_title: pageTitle,
    };
    analytics.logEvent('page_view', pageViewData);
  }, [pageTitle]);
};

export default usePageLog;
