import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { firestore } from '../../firebase';
import defaultContent from './DefaultContent';
import type { AppContentState } from './types';
import type { AppContent } from '@swing-therapeutics/surveybay/dist/types';
import LandingPageStyling from '../../../models/landing/LandingPageStyling';

const initialContentState: AppContentState = {
  appContent: null,
  studyTitle: '',
};

type ActionTypes = { type: 'SETLPDATA'; payload: LandingPageStyling };

interface AppStateHookReturn {
  appContentState: AppContentState;
  appContentStateDispatch: (action: ActionTypes) => Promise<void>;
  buttonColor: string;
  setButtonColor: Dispatch<SetStateAction<string>>;
}

// checks if favIcon exists and if so overwrite favicon else create new favicon
const setFavicons = (favImg: string) => {
  let headTitle = document.querySelector('head');
  let favIcons = [{ rel: 'apple-touch-icon' }, { rel: 'apple-touch-startup-image' }, { rel: 'shortcut icon' }, { rel: 'favicon' }];
  favIcons.forEach(function (favIcon) {
    const faviconIfExists = document.getElementById(favIcon.rel);
    if (faviconIfExists) {
      //@ts-ignore thinks href doesn't exist'
      faviconIfExists.href = favImg;
    } else {
      let setFavicon = document.createElement('link');
      setFavicon.setAttribute('rel', favIcon.rel);
      setFavicon.id = favIcon.rel;
      setFavicon.setAttribute('href', favImg);
      headTitle?.appendChild(setFavicon);
    }
  });
};

/*
 * Fetch and provide content for the app
 */
const useAppContent = (): AppStateHookReturn => {
  const [appContentState, setAppContentState] = useState<AppContentState>({ ...initialContentState });
  const [buttonColor, setButtonColor] = useState('');

  useEffect(() => {
    (async () => {
      // Fetch the app content
      try {
        const docSnap = await firestore.doc('/web/surveysaurus/content/dynamic').get();
        const docData = docSnap.data();
        if (!docSnap.exists || !docData) {
          throw new Error('Content doc does not exist');
        }
        setAppContentState({
          appContent: docData as AppContent,
          studyTitle: 'REACT-FM',
        });
      } catch (error) {
        console.error(error);
        // Set the default content for the app
        setAppContentState({
          appContent: defaultContent,
          studyTitle: 'REACT-FM',
        });
      }
    })();
  }, []);

  // Wrapper for the setState to perform async calls to API and update state when completed
  const appContentStateDispatch = async (action: ActionTypes) => {
    switch (action.type) {
      case 'SETLPDATA':
        if (action.payload.metadata.title) {
          setAppContentState((prevState) => ({
            ...prevState,
            studyTitle: action.payload.metadata.title,
          }));
          document.title = action.payload.metadata.title;
        }
        if (action.payload.metadata.favicon) {
          setFavicons(action.payload.metadata.favicon);
        }
        if (action.payload.metadata.buttonColor) {
          const buttonColor = action.payload.metadata.buttonColor;
          const element = document.getElementById('loginButton');
          if (element) {
            element.style.backgroundColor = buttonColor;
            element.style.borderColor = buttonColor;
          }
        }
        return;
      default:
        return;
    }
  };

  return {
    appContentState,
    appContentStateDispatch,
    buttonColor,
    setButtonColor,
  };
};

const ContentContext = createContext({} as AppStateHookReturn);

export const ContentProvider: React.FC = ({ children }) => {
  return <ContentContext.Provider value={useAppContent()}>{children}</ContentContext.Provider>;
};

export const UserStateConsumer = ContentContext.Consumer;
export default ContentContext;
