import { IDisclaimer, WorkflowID } from '@swing-therapeutics/surveybay/dist/types';
import { firebaseTimestampToDate } from '@swing-therapeutics/swingcore/dist/utils/firebase/firebaseTimestampToDate';
import { DocumentData, DocumentSnapshot, firestore } from '../utils/firebase';
import { RequestError } from './RequestError';
import { User } from './User';

const findDisclaimerDoc = (userUID: string) => {
  return firestore.collection(`users/${userUID}/workflows`).where('workflowID', '==', WorkflowID.DISCLAIMER);
};

export class DisclaimerMeta implements IDisclaimer {
  disclaimerAccepted: boolean;
  readonly disclaimerText: string;
  updated: Date;
  docPath: string;

  constructor(props: any, docPath: string) {
    this.disclaimerAccepted = props.disclaimerAccepted ?? false;
    this.disclaimerText = props.disclaimerText ?? 'Error loading disclaimer text';
    this.docPath = docPath;
    this.updated = props.updated ? firebaseTimestampToDate(props.updated) : new Date();
  }

  getFirestoreData() {
    // Return data that should be in firestore
    return {
      disclaimerAccepted: this.disclaimerAccepted,
      disclaimerText: this.disclaimerText,
      updated: this.updated,
    };
  }

  async persist(): Promise<'Success' | RequestError> {
    const data = this.getFirestoreData();
    data.updated = new Date();
    try {
      await firestore.doc(this.docPath).set(data, { merge: true });
    } catch (error) {
      return new RequestError(error, 'Dsiclaimer meta persist');
    }
    return 'Success';
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData>): DisclaimerMeta {
    const docData = docSnap.data();

    return new DisclaimerMeta({ ...docData }, docSnap.ref.path);
  }

  static async fromUser(user: User): Promise<DisclaimerMeta | RequestError> {
    let doc: DocumentSnapshot<DocumentData>;
    try {
      const collection = await findDisclaimerDoc(user.uid).get();
      doc = collection.docs[0];
    } catch (error) {
      return new RequestError(error, 'Disclaimer meta dock retrieval');
    }
    if (!doc?.exists) {
      return new RequestError(new Error('Disclaimer doc doesnt exist for user'), 'Disclaimer doc retrieval');
    } else {
      return this.fromFirestore(doc);
    }
  }
}
