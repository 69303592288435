import { IScheduleCall, ScheduledCallStatus, WorkflowType } from '@swing-therapeutics/surveybay/dist/types';
import { DocumentData, DocumentSnapshot, firestore } from '../../utils/firebase';
import { User } from '../User';
import { ScheduleCallAbstract } from './ScheduleCallAbstract';

const findScheduleCallDocs = (userUID: string, _workflowType: WorkflowType.EXTENSION = WorkflowType.EXTENSION) => {
  return firestore.collection(`users/${userUID}/calls`).where('workflowType', '==', WorkflowType.EXTENSION);
};

export class ScheduleCall extends ScheduleCallAbstract {
  static fromFirestore(docSnap: DocumentSnapshot<DocumentData | undefined>): ScheduleCall {
    const data = docSnap.data();
    const scheduledCallMeta = new ScheduleCall(data);
    scheduledCallMeta.docPath = docSnap.ref.path;
    return scheduledCallMeta;
  }

  getFirestoreData() {
    // Only return fields that should be modified by the frontend
    // All other fields are modified in the cloud functions or temepst
    const data: Partial<IScheduleCall> = {
      status: this.status,
    };
    if (this.eventURI) {
      data.eventURI = this.eventURI;
    }
    if (this.inviteeURI) {
      data.inviteeURI = this.inviteeURI;
    }
    return data;
  }

  static async subscribeCurrentCall(user: User, changeCallback: (scheduleCall: ScheduleCall | false) => void): Promise<() => void> {
    try {
      // Search the call collection for calls that are not terminal
      const collection = await findScheduleCallDocs(user.uid, WorkflowType.EXTENSION)
        .where('status', 'in', [ScheduledCallStatus.CALL_SCHEDULED, ScheduledCallStatus.NOT_SCHEDULED])
        .orderBy('created')
        .get();
      const doc = collection.docs[0];
      if (!doc) {
        // No call for user to interact with right now
        changeCallback(false);
        return () => {};
      }
      // Call needs user interaction, subscribe to it
      return doc.ref.onSnapshot((snap) => {
        if (!snap.exists) {
          return;
        }
        changeCallback(this.fromFirestore(snap));
      });
    } catch (error) {
      changeCallback(false);
      console.error(error);
      return () => {};
    }
  }

  static subscribeAnyCallUpdate(user: User, newCallOptionCallback: () => void): () => void {
    return findScheduleCallDocs(user.uid, WorkflowType.EXTENSION).onSnapshot((snap) => {
      for (const docChange of snap.docChanges()) {
        if (['modified', 'added'].includes(docChange.type)) {
          newCallOptionCallback();
          break;
        }
      }
    });
  }
}
