import React, { useContext, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import Layout from '../../components/Layout';
import Section from '../../components/common/Section';
import UserStateContext from '../../utils/contexts/UserContext';
import ContentContext from '../../utils/contexts/ContentContext/ContentContext';
import NextStepButton from '../../components/common/NextStepButton';
import LoadingIcon from '../../components/common/LoadingIcon';
import WorkflowProgressBar from '../../components/common/WorkflowProgressBar';
import TherapyProgressTable from '../../components/common/TherapyProgressTable';
import styles from './Profile.module.scss';
import { usePageLog } from '../../utils/analytics';
import { UserStatus } from '@swing-therapeutics/surveybay/dist/types';
import ScheduleCallButtons from '../../components/common/ScheduleCallButtons';

interface PageParams {}

const ProfileView: React.FC<RouteComponentProps<PageParams>> = () => {
  usePageLog({ pageTitle: 'Profile' });
  const { userState } = useContext(UserStateContext);
  const { appContentState } = useContext(ContentContext);
  const [hideProgressSection, setHideProgressSection] = useState(false);
  const layoutHeader = `${appContentState.studyTitle}${userState.inExtension ? '-Extension' : ''} Study`;
  const layoutDescription =
    userState.landingPageKey === '003'
      ? `This site is for participants in the Real-World Evidence From Smartphone-Based Acceptance and Commitment Therapy in Fibromyalgia (“${appContentState.studyTitle}”) study`
      : `This site is for participants in the Prospective Study to Evaluate a Digital Regimen for Fibromyalgia Management: ${
          userState.inExtension ? 'An Extension ' : ''
        }Study (“${appContentState.studyTitle}${userState.inExtension ? '-Extension' : ''}”)`;
  return (
    <Layout
      header={{
        title: layoutHeader,
        subtitle: layoutDescription,
        subtitleType: 'p',
      }}
    >
      <Section minHeight='50vh'>
        <Container className={styles.profilecont}>
          <Row>
            <h2>Your Profile</h2>
          </Row>
          {userState.user && userState.user !== 'FIRSTLOAD' ? (
            userState.user.workflowStatus === UserStatus.HOLDING_PRE_SCREEN ? (
              <Row className={styles.section}>
                <Col>
                  <Row>
                    <h3 className='text-dark'>Thank you for your interest</h3>
                  </Row>
                  <Row>
                    <p>
                      At this time we are not enrolling new participants. You will be emailed at {userState.user.email} when we continue enrolling
                      again.
                    </p>
                  </Row>
                </Col>
              </Row>
            ) : (
              <>
                <Row className={styles.section}>
                  <Col>
                    <Row>
                      <h3 className='text-dark'>Progress</h3>
                    </Row>
                    <Row className='justify-content-center my-4 px-0 px-sm-3'>
                      <WorkflowProgressBar user={userState.user} inExtension={userState.inExtension} />
                    </Row>
                  </Col>
                </Row>
                {[UserStatus.EXT_THERAPY, UserStatus.THERAPY, UserStatus.EXT_DECLINED, UserStatus.DONE, UserStatus.EXT_DONE].includes(
                  userState.user.workflowStatus,
                ) && (
                  <Row className={styles.section} style={{ display: hideProgressSection ? 'none' : '' }}>
                    <Col>
                      <Row>
                        <h3 className='text-dark'>{userState.inExtension ? 'Extension ' : ''}Therapy Progress</h3>
                      </Row>
                      <Row className='justify-content-center'>
                        <TherapyProgressTable setHideProgressSection={setHideProgressSection} />
                      </Row>
                    </Col>
                  </Row>
                )}
                <NextStepButton />
                {userState.user.workflowStatus === UserStatus.EXT_ELIGIBLE && (
                  <>
                    <Row>
                      <h4 className='text-center' style={{ whiteSpace: 'pre-wrap' }}>
                        We are pleased to inform you that you have met the eligibility criteria for a follow-up clinical study called{' '}
                        {appContentState.studyTitle} Extension. Please click the button below to learn more.
                      </h4>
                    </Row>
                    <Row className='justify-content-center my-3'>
                      <Link to='/learnmore'>
                        <Button className='btn-icon btn-3 btn-lg CTA-btn' type='button'>
                          <span className='btn-inner--text'>Learn more</span>
                          <span className='btn-inner--icon mr-1'>
                            <i className='ni ni-curved-next' />
                          </span>
                        </Button>
                      </Link>
                    </Row>
                  </>
                )}
                <ScheduleCallButtons />
              </>
            )
          ) : (
            <Row className='justify-content-center'>
              <LoadingIcon />
            </Row>
          )}
        </Container>
      </Section>
    </Layout>
  );
};

export default ProfileView;
