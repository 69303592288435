import { IScheduleCall, ScheduledCallStatus, UserStatus } from '@swing-therapeutics/surveybay/dist/types';
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import CalendlyCallCalendar from '../../components/common/CalendlyCallCalendar';
import LoadingIcon from '../../components/common/LoadingIcon';
import ScheduledCallInfo from '../../components/common/ScheduledCallInfo';
import Section from '../../components/common/Section';
import Layout from '../../components/Layout';
import { ScheduleCall } from '../../models/calls/ScheduleCall';
import { ScheduleCallAbstract } from '../../models/calls/ScheduleCallAbstract';
import { TerminationCallMeta } from '../../models/calls/TerminationCallMeta';
import { User } from '../../models/User';
import { usePageLog } from '../../utils/analytics';
import UserStateContext from '../../utils/contexts/UserContext';

const ScheduleACall: React.FC<RouteComponentProps> = () => {
  usePageLog({ pageTitle: 'ScheduleCall' });
  const { userState } = useContext(UserStateContext);
  // null indicates that there is no call to schedule right now
  const [callOption, setCallOption] = useState<IScheduleCall | 'loading' | null>('loading');
  const unSubCurrenCall = useRef<() => void>();
  const scheduleCall = useRef<ScheduleCallAbstract>();

  const checkCallOption = useCallback(async (user: User) => {
    unSubCurrenCall.current?.();
    let CallClass: typeof ScheduleCall | typeof TerminationCallMeta = ScheduleCall;
    if ([UserStatus.EXT_EARLY_TERMINATION_FOLLOWUP, UserStatus.EARLY_TERMINATION_FOLLOWUP].includes(user.workflowStatus)) {
      // Termination call is a slightly souped up version of scheduled call that lives in workflows collection instead of calls collection
      // Has all the same methods as ScheduleCall
      CallClass = TerminationCallMeta;
    }
    unSubCurrenCall.current = await CallClass.subscribeCurrentCall(user, (_scheduleCall) => {
      // Only show a call option if it is not scheduled or scheduled, all other status is terminal, nothing for user to do
      if (!_scheduleCall || ![ScheduledCallStatus.NOT_SCHEDULED, ScheduledCallStatus.CALL_SCHEDULED].includes(_scheduleCall.status)) {
        // No call to schedule right now
        setCallOption(null);
        return;
      }
      scheduleCall.current = _scheduleCall;
      setCallOption({ ..._scheduleCall });
    });
  }, []);

  useEffect(() => {
    setCallOption('loading');
    if (userState.user && userState.user !== 'FIRSTLOAD') {
      checkCallOption(userState.user);
    }
    return () => {
      unSubCurrenCall.current?.();
    };
  }, [userState.user, checkCallOption]);

  const onEventScheduled = useCallback((eventURI: string, inviteeURI: string) => {
    scheduleCall.current?.callScheduled(eventURI, inviteeURI);
    scheduleCall.current?.persist();
  }, []);

  return (
    <Layout navSpace={true}>
      {/* If there is no call option take user back to profile page, there is no call to schedule or view */}
      {callOption === null && <Redirect to='/profile' />}
      <Section minHeight='75vh'>
        <Container className='mt-3'>
          <Row className='justify-content-center'>
            {callOption === 'loading' || callOption === null || !userState.user || userState.user === 'FIRSTLOAD' ? (
              <LoadingIcon text='Loading call...' />
            ) : (
              <Col xs={12} md={7}>
                <Row>
                  <h2>{callOption.title}</h2>
                </Row>
                <Row>
                  <h6>{callOption.description}</h6>
                </Row>
                {callOption.status === ScheduledCallStatus.NOT_SCHEDULED ? (
                  <>
                    <Row>
                      <h6>Please select an available time to set up your call with Swing Study staff.</h6>
                    </Row>
                    <Row>
                      <CalendlyCallCalendar
                        apiURL={callOption.calendlyCallURL}
                        name={userState.user.displayName}
                        email={userState.user.email}
                        onEventScheduled={onEventScheduled}
                      />
                    </Row>
                  </>
                ) : (
                  <>
                    <ScheduledCallInfo email={userState.user.email} callInfo={callOption} />
                    <Row className='mt-3 justify-content-center'>
                      <Link to='/profile'>
                        <Button className='btn-icon btn-3 btn-lg CTA-btn'>
                          <span className='btn-inner--text'>Back to profile page</span>
                          <span className='btn-inner--icon mr-1'>
                            <i className='ni ni-curved-next' />
                          </span>
                        </Button>
                      </Link>
                    </Row>
                  </>
                )}
              </Col>
            )}
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default ScheduleACall;
