import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { RequestError } from '../../../models/RequestError';
import { User } from '../../../models/User';
import UserStateContext from '../../../utils/contexts/UserContext';
import isMobileDevice from '../../../utils/functions/isMobileDevice';
import { Button, Modal, ModalBody, Row } from 'reactstrap';
import LandingPage from '../../../models/landing/LandingPage';

const DownloadTempoButton: React.FC = () => {
  const { userState } = useContext(UserStateContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [landingPage, setLandingPage] = useState<LandingPage>();
  const isMounted = useRef(true);

  const fetchLandingPage = useCallback(async (user: User) => {
    const landingPage = await LandingPage.fetchLandingPage(user.landingPageKey);
    if (landingPage instanceof RequestError || isMounted.current === false) return;
    setLandingPage(landingPage);
  }, []);

  useEffect(() => {
    isMounted.current = true;
    setLandingPage(undefined);
    if (userState.user && userState.user !== 'FIRSTLOAD' && userState.landingPageKey !== '') {
      fetchLandingPage(userState.user);
    }
    return () => {
      isMounted.current = false;
    };
  }, [userState.user, fetchLandingPage, userState.landingPageKey]);

  const handleButtonClick = () => {
    if (!landingPage) return;
    if (isMobileDevice() || modalOpen) {
      window.open(landingPage.tempoDownloadLink);
      setModalOpen(false);
    } else {
      // Show popup modal saying you need to be on mobile device
      setModalOpen(true);
    }
  };

  const ActionButton = () => (
    <Button className='btn-icon btn-3 btn-lg CTA-btn' onClick={handleButtonClick}>
      {landingPage ? (
        <>
          <span className='btn-inner--text'>Download Tempo</span>
          <span className='btn-inner--icon mr-1'>
            <i className='ni ni-curved-next' />
          </span>
        </>
      ) : (
        'Loading link...'
      )}
    </Button>
  );

  return (
    <>
      <ActionButton />
      <Modal className='modal-dialog-centered' isOpen={modalOpen} toggle={() => setModalOpen((prevState) => !prevState)}>
        <ModalBody>
          <Row className='my-2 p-2'>
            <h6>Looks like you are not on a mobile device or tablet, you will need to be on your mobile device or tablet to run the app.</h6>
            <h6>If this is a mistake and you are on a mobile device or tablet, click the download button.</h6>
          </Row>
          <Row className='justify-content-center my-2'>
            <ActionButton />
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DownloadTempoButton;
