import {
  ITherapyMeta,
  SurveyInfo,
  SurveyStatus,
  TherapySurvey,
  WorkflowID,
  WorkflowType,
  WorkflowStatusMap,
} from '@swing-therapeutics/surveybay/dist/types';
import { firebaseTimestampToDate } from '@swing-therapeutics/swingcore/dist/utils/firebase/firebaseTimestampToDate';
import { DocumentData, DocumentSnapshot, firestore } from '../../utils/firebase';
import { RequestError } from '../RequestError';
import { User } from '../User';
import { SurveyMetaAbstract } from './SurveyMetaAbstract';

const findTherapyMeta = (userUID: string, workflowType: WorkflowType) => {
  let workflowID = WorkflowID.THERAPY;
  if (workflowType === WorkflowType.EXTENSION) {
    workflowID = WorkflowID.EXT_THERAPY;
  }
  return firestore.collection(`users/${userUID}/workflows`).where('workflowID', '==', workflowID);
};

// This meta doc varies slightly from the baseline meta and eligibility meta doc
// the surveys for every week are stored on this doc so there is an extra layer of
// nesting to get to the current surveys
export class TherapyMeta extends SurveyMetaAbstract implements ITherapyMeta {
  therapyStatus: string;
  therapyWeek: number;
  therapySurveys: TherapySurvey[];
  totalTherapyWeeks: number;
  // Holds the week that the surveys in the surveys property has
  surveyWeek: number;
  therapyStartDay: Date;

  constructor(props: any, docPath: string) {
    super(props, docPath);
    this.therapyWeek = props.therapyWeek;
    this.therapyStatus = props.therapyStatus;
    this.therapySurveys = props.therapySurveys;
    this.totalTherapyWeeks = props.totalTherapyWeeks;
    this.therapyStartDay = firebaseTimestampToDate(props.therapyStartDay);
    // A user can be on a more recent week of therapy but still need to complete earlier week's surveys,
    // find the oldest week of surveys that can still be completed
    let surveyWeek: undefined | number = undefined;
    let surveys: undefined | SurveyInfo[] = undefined;
    for (const therapySurvey of this.therapySurveys) {
      if (therapySurvey.surveys.length) {
        // Check if they can be edited, if every status of the survey is either expired or completed then the survey should not be edited
        const dontEdit = therapySurvey.surveys.every((survey) =>
          [SurveyStatus.EXPIRED, SurveyStatus.EXPIRED_IN_PROGRESS, SurveyStatus.COMPLETED].includes(survey.status),
        );
        if (dontEdit) continue;
        // At this point this is the week of surveys the user should be doing
        surveyWeek = therapySurvey.week;
        surveys = therapySurvey.surveys;
        break;
      }
    }
    this.surveys = surveys ?? [];
    // -1 indicates that there are no surveys to take right now
    this.surveyWeek = surveyWeek ?? -1;
  }

  getFirestoreData() {
    return {
      therapySurveys: this.therapySurveys,
    };
  }

  complete(surveyBayKey: string) {
    if (this.surveyWeek === -1) {
      console.error('Trying to complete survey, but the survey week is set to -1');
      return;
    }
    const surveyIndex = this.therapySurveys[this.surveyWeek - 1].surveys.findIndex((i) => i.surveyBayKey === surveyBayKey);
    if (surveyIndex === -1) {
      console.error(`Could not find survey with surveyBayKey ${surveyBayKey} when setting status to complete`);
      return;
    }
    this.therapySurveys[this.surveyWeek - 1].surveys[surveyIndex].status = SurveyStatus.COMPLETED;
    this.therapySurveys[this.surveyWeek - 1].surveys[surveyIndex].updated = new Date();
  }

  start(surveyBayKey: string) {
    if (this.surveyWeek === -1) {
      console.error('Trying to start survey, but the survey week is set to -1');
      return;
    }
    const surveyIndex = this.therapySurveys[this.surveyWeek - 1].surveys.findIndex((i) => i.surveyBayKey === surveyBayKey);
    if (surveyIndex === -1) {
      console.error(`Could not find survey with surveyBayKey ${surveyBayKey} when setting status to start`);
      return;
    }
    this.therapySurveys[this.surveyWeek - 1].surveys[surveyIndex].status = SurveyStatus.IN_PROGRESS;
    this.therapySurveys[this.surveyWeek - 1].surveys[surveyIndex].updated = new Date();
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData>): TherapyMeta {
    const docData = docSnap.data();

    return new TherapyMeta({ ...docData }, docSnap.ref.path);
  }

  static async fromUser(user: User): Promise<TherapyMeta | RequestError> {
    let doc: DocumentSnapshot<DocumentData>;
    try {
      const collection = await findTherapyMeta(user.uid, WorkflowStatusMap[user.workflowStatus]).get();
      doc = collection.docs[0];
    } catch (error) {
      return new RequestError(error, 'Therapy meta doc retrieval');
    }
    if (!doc?.exists) {
      return new RequestError(new Error('Therapy doc doesnt exist for user'), 'Therapy doc retrieval');
    } else {
      return this.fromFirestore(doc);
    }
  }

  static async fromUserSubscribe(user: User, changeCallback: (therapyMeta: TherapyMeta) => void): Promise<() => void> {
    try {
      const collection = await findTherapyMeta(user.uid, WorkflowStatusMap[user.workflowStatus]).get();
      const doc = collection.docs[0];
      return doc.ref.onSnapshot((snap) => {
        if (!snap.exists) {
          return;
        }
        changeCallback(new TherapyMeta(snap.data(), snap.ref.path));
      });
    } catch (error) {
      console.log(error);
      return () => {};
    }
  }
}
