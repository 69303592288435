import { UserFail } from '@swing-therapeutics/surveybay/dist/types';
import React, { useContext } from 'react';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { ExclamationIcon } from '../../assets/icons/svgicons';
import LoadingIcon from '../../components/common/LoadingIcon';
import Section from '../../components/common/Section';
import Layout from '../../components/Layout';
import { usePageLog } from '../../utils/analytics';
import ContentContext from '../../utils/contexts/ContentContext/ContentContext';

interface LocationState {
  failReason: UserFail; // Reason user was directed to this fail page
}

const FailPage: React.FC<RouteComponentProps<{}, StaticContext, LocationState>> = ({ location }) => {
  usePageLog({ pageTitle: 'Not Eligible' });
  const { appContentState } = useContext(ContentContext);
  if (!location.state?.failReason) {
    // Only way user can see this page is if provided a failReason (cant just nav to this page)
    return <Redirect to='/profile' />;
  }

  return (
    <Layout navSpace={true}>
      <Section minHeight='75vh'>
        <Container>
          <Row className='justify-content-center mt-3'>
            <Card className='card-max-500 shadow'>
              <CardHeader>
                <Row>
                  <Col xs='auto' className='pr-0'>
                    <ExclamationIcon height='25px' width='25px' />
                  </Col>
                  <Col>
                    <h4 className='mb-0'>Thanks for completing the survey!</h4>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Col>
                  <Row>
                    {appContentState ? (
                      <h5 className='text-dark text-default'>{appContentState.appContent?.failPageText[location.state.failReason]}</h5>
                    ) : (
                      <LoadingIcon />
                    )}
                  </Row>
                  {
                    // Show an extra message on these fail reasons bc the user may have downloaded the app
                    [UserFail.BASELINE_ABANDONED, UserFail.EARLY_TERMINATION, UserFail.CONSENT_FAIL].includes(location.state.failReason) && (
                      <Row className='mt-3'>
                        <h5 className='text-dark text-default'>If you have downloaded the study application, please delete it.</h5>
                      </Row>
                    )
                  }
                </Col>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default FailPage;
