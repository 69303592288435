import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import Layout from '../../components/Layout';
import styles from './FAQ.module.scss';
import Section from '../../components/common/Section';
import { usePageLog } from '../../utils/analytics';
import { Accordion, AccordionCollapse, AccordionContext, AccordionToggle } from 'react-bootstrap';
import { AccordionCheveron } from '../../assets/icons/svgicons';
import ContentContext from '../../utils/contexts/ContentContext/ContentContext';
import LoadingIcon from '../../components/common/LoadingIcon';
import { RequestError } from '../../models/RequestError';
import LandingPageStyling from '../../models/landing/LandingPageStyling';
import UserStateContext from '../../utils/contexts/UserContext';

interface PageParams {
  referralGroupKey?: string;
  landingPageKey: string;
}

const FAQ: React.FC<RouteComponentProps<PageParams>> = ({ match }) => {
  usePageLog({ pageTitle: 'FAQ' });
  const { appContentState } = useContext(ContentContext);
  const [landingPage, setLandingPage] = useState<LandingPageStyling>();
  const isMounted = useRef(true);

  const { userStateDispatch, userState } = useContext(UserStateContext);
  const fetchAndSetLandingPage = useCallback(async (landingKey) => {
    const landing = await LandingPageStyling.fetchLandingPage(landingKey);
    if (landing instanceof RequestError || isMounted.current === false) return;
    setLandingPage(landing);
  }, []);

  useEffect(() => {
    isMounted.current = true;
    if (match.params.landingPageKey) {
      fetchAndSetLandingPage(match.params.landingPageKey);
    }
    if (userState.landingPageKey !== match.params.landingPageKey) {
      userStateDispatch({
        type: 'SETLPKEY',
        payload: match.params.landingPageKey,
      });
    }
    return () => {
      isMounted.current = false;
    };
  }, [match.params, fetchAndSetLandingPage, userState.landingPageKey, userStateDispatch]);

  return (
    <Layout header={{ title: 'Frequently Asked Questions (FAQs)' }}>
      <Section>
        <Container>
          <Row className={styles.sectionheader}>
            <h2 className='text-dark'>General Questions</h2>
          </Row>
          {appContentState ? (
            <Row>
              <Accordion>
                {landingPage?.faqs?.map((faq, index) => (
                  <div className='accordion-item' key={index}>
                    <AccordionToggle className='accordion-header' eventKey={index.toString()}>
                      <ToggleButton eventKey={index.toString()} />
                      <h3>{faq.question}</h3>
                    </AccordionToggle>
                    <AccordionCollapse className='accordion-body' eventKey={index.toString()}>
                      <p>{faq.answer}</p>
                    </AccordionCollapse>
                  </div>
                ))}
              </Accordion>
            </Row>
          ) : (
            <LoadingIcon />
          )}
        </Container>
      </Section>
    </Layout>
  );
};

export default FAQ;

interface ToggleProps {
  eventKey: string;
}

const ToggleButton: React.FC<ToggleProps> = ({ eventKey }) => {
  const activeEventKey = useContext(AccordionContext);

  const isCurrentEventKey = activeEventKey === eventKey;

  return <AccordionCheveron className={`toggle-button ${isCurrentEventKey ? 'active' : ''}`} />;
};
