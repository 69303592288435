import { IScheduleCall, ScheduledCallStatus, WorkflowType } from '@swing-therapeutics/surveybay/dist/types';
import { firebaseTimestampToDate } from '@swing-therapeutics/swingcore/dist/utils/firebase/firebaseTimestampToDate';
import { DocumentData, DocumentSnapshot, firestore } from '../../utils/firebase';

export class ScheduleCallAbstract implements IScheduleCall {
  docPath: string;
  description: string;
  title: string;
  eventName: string;
  required: boolean;
  calendlyCallURL: string;
  therapyWeek: number;
  status: ScheduledCallStatus;
  created: Date;
  workflowType: WorkflowType;
  dateOfCall?: Date | null;
  eventURI?: string | null;
  inviteeURI?: string | null;
  callStartTime?: Date | null;
  callEndTime?: Date | null;
  locationInfo?: any | null;
  cancelURL?: string | null;
  rescheduleURL?: string | null;

  constructor(props: any) {
    if (this.constructor === ScheduleCallAbstract) {
      throw new Error("Abstract class can't be contructed");
    }
    Object.assign(this, props);
    this.callStartTime = props.callStartTime ? firebaseTimestampToDate(props.callStartTime) : props.callStartTime;
    this.callEndTime = props.callEndTime ? firebaseTimestampToDate(props.callEndTime) : props.callEndTime;
    this.created = props.created ? firebaseTimestampToDate(props.created) : props.created;
  }

  static fromFirestore(_docSnap: DocumentSnapshot<DocumentData>): any {
    throw new Error('Method fromFirestore() must be implemented');
  }

  callScheduled(eventURI: string, inviteeURI: string) {
    this.status = ScheduledCallStatus.CALL_SCHEDULED;
    this.eventURI = eventURI;
    this.inviteeURI = inviteeURI;
  }

  declineCurrentCall() {
    this.status = ScheduledCallStatus.DECLINED;
  }

  getFirestoreData(): Object {
    // Should only ever be updating these two values
    return {
      status: this.status,
      eventURI: this.eventURI,
      inviteeURI: this.inviteeURI,
    };
  }

  async persist() {
    await firestore.doc(this.docPath).update(this.getFirestoreData());
  }
}
