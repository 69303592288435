import React from 'react';
import { useContext } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import UserStateContext from '../contexts/UserContext';

// Redirects a user to the currently set landing page key
const WelcomeRedirect: React.FC<RouteComponentProps> = () => {
  const { userState } = useContext(UserStateContext);
  let landingPageKey = userState.landingPageKey === '' ? '003' : userState.landingPageKey;
  if (landingPageKey !== '003' && landingPageKey !== '005' && landingPageKey !== '') {
    landingPageKey = '003';
  }
  return <Redirect to={`/welcome/${landingPageKey}`} />;
};

export default WelcomeRedirect;
