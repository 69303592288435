export class RequestError extends Error {
  at: string;
  error: any;

  constructor(error: any, at: string = 'Unspecified', message: string = 'Request Error') {
    super(message);
    console.error(error);
    this.error = error;
    this.at = at;
  }
}
