import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { ScheduledCallStatus, SurveyInfo, SurveyStatus, UserStatus } from '@swing-therapeutics/surveybay/dist/types';
import { Link } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import { RequestError } from '../../../models/RequestError';
import { BaselineMeta } from '../../../models/survey/BaselineMeta';
import { EligibilityMeta } from '../../../models/survey/EligibilityMeta';
import { TherapyMeta } from '../../../models/survey/TherapyMeta';
import { User } from '../../../models/User';
import UserStateContext from '../../../utils/contexts/UserContext';
import LoadingIcon from '../LoadingIcon';
import { firebaseTimestampToDate } from '@swing-therapeutics/swingcore/dist/utils/firebase/firebaseTimestampToDate';
import { ConsentMeta } from '../../../models/calls/ConsentMeta';
import { TerminationMeta } from '../../../models/survey/TerminationMeta';
import { TerminationCallMeta } from '../../../models/calls/TerminationCallMeta';

interface NextStepButtonProps {}

interface LinkAndText {
  link: string;
  text: string;
  surveyInfo?: string;
  showExtensionOption?: boolean;
}

// Link and text for after a user completes final week of surveys or has a status of 'done'
const doneLinkAndText: LinkAndText = {
  link: '/profile',
  text: 'Great work! You have finished therapy. Thanks so much for participating in our study!',
  showExtensionOption: true,
};

// Check if the entire compiled survey has been started (user answered at least one question)
const surveysStarted = (surveys: SurveyInfo[]): boolean => {
  let oneCompleted = false;
  for (let index = 0; index < surveys.length; index++) {
    // The compiled survey will be started if any of the individual surveys have a status of in-progress
    if (surveys[index].status === SurveyStatus.IN_PROGRESS) {
      return true;
    }
    if (surveys[index].status === SurveyStatus.COMPLETED) {
      oneCompleted = true;
      continue;
    }
    // Or if one of the individual surveys has a status of assigned and another has been completed
    if (surveys[index].status === SurveyStatus.ASSIGNED && oneCompleted) {
      return true;
    }
  }
  return false;
};

const parseExpireSurveyTime = (expireDate: Date): string => {
  // moment.fromNow will round days up, we dont want that
  // Moments .diff function will not round up days
  // If the diff in days is greater than 0 show our own in X days
  // If the diff in days is less than 1 use the .fromNow
  const mExpireDate = moment(expireDate);
  const now = moment(new Date());
  const dayDiff = mExpireDate.diff(now, 'days');
  if (dayDiff >= 1) {
    return `in ${dayDiff} ${dayDiff === 1 ? 'day' : 'days'}`;
  }
  return mExpireDate.fromNow();
};

// Render a button that takes the user to their next task
const NextStepButton: React.FC<NextStepButtonProps> = () => {
  const { userState } = useContext(UserStateContext);
  const [linkAndText, setLinkAndText] = useState<LinkAndText>();
  const unSub = useRef<() => void>();

  const getNextStep = useCallback(async (user: User) => {
    switch (user.workflowStatus) {
      case UserStatus.SCREENING: {
        unSub.current = await EligibilityMeta.fromUserSubscribe(user, (eligibilityMeta) => {
          if (eligibilityMeta instanceof RequestError) return;
          const linkAndText = {
            link: '/surveys',
            text: `${surveysStarted(eligibilityMeta.surveys) ? 'Finish' : 'Take'} eligibility survey`,
            surveyInfo: `Your eligibility window expires ${
              // All surveys will have same expire timestamp so can just take the first survey
              parseExpireSurveyTime(firebaseTimestampToDate(eligibilityMeta.surveys[0].expires))
            }. Please complete your survey by then.`,
          };
          setLinkAndText(linkAndText);
        });
        break;
      }
      case UserStatus.EXT_CONSENT_ABANDONED:
      case UserStatus.EXT_CONSENT:
      case UserStatus.CONSENT_ABANDONED:
      case UserStatus.CONSENT: {
        unSub.current = await ConsentMeta.fromUserSubscribe(user, (consentMeta) => {
          const linkAndText = {
            link: '/consent',
            text: consentMeta.status !== ScheduledCallStatus.CALL_SCHEDULED ? 'Schedule consent call' : "You've scheduled your consent call",
          };
          setLinkAndText(linkAndText);
        });
        break;
      }
      case UserStatus.BASELINE: {
        unSub.current = await BaselineMeta.fromUserSubscribe(user, (baselineMeta) => {
          if (baselineMeta instanceof RequestError) return;
          let linkAndText = {
            link: '/surveys',
            text: `${surveysStarted(baselineMeta.surveys) ? 'Finish' : 'Take'} baseline survey`,
            surveyInfo: `Your baseline window expires ${
              // All surveys will have same expire timestamp so can just take the first survey
              parseExpireSurveyTime(firebaseTimestampToDate(baselineMeta.surveys[0].expires))
            }. Please complete your survey by then.`,
          };
          setLinkAndText(linkAndText);
        });
        break;
      }
      case UserStatus.EXT_PRE_THERAPY: {
        const linkAndText = {
          link: '/profile',
          text: 'You can now begin your therapy. Please log back into Tempo using the same login and password that you used to log in here.',
        };
        setLinkAndText(linkAndText);
        break;
      }
      case UserStatus.PRE_THERAPY: {
        const linkAndText = {
          link: '/profile',
          text: 'Thanks for completing your baseline survey! You can now begin your therapy. Please log back into Tempo using the same login and password that you used to log in here.',
        };
        setLinkAndText(linkAndText);
        break;
      }
      case UserStatus.EXT_THERAPY:
      case UserStatus.THERAPY: {
        unSub.current = await TherapyMeta.fromUserSubscribe(user, (therapyMeta) => {
          const completedThisWeeksSurveys = therapyMeta.surveys.every((survey) => survey.status === SurveyStatus.COMPLETED);
          let linkAndText: LinkAndText;
          if (completedThisWeeksSurveys && therapyMeta.totalTherapyWeeks === therapyMeta.therapySurveys.length) {
            // User compelted all surveys but the done status may take a second to be updated via cloud function
            linkAndText = doneLinkAndText;
          } else if (completedThisWeeksSurveys) {
            linkAndText = {
              link: '/profile',
              text: 'No surveys to take right now 🎉\nYou will be notified by email when your next survey is available.',
            };
          } else {
            linkAndText = {
              link: '/surveys',
              text: `${surveysStarted(therapyMeta.surveys) ? 'Finish' : 'Take'} week ${therapyMeta.surveyWeek} survey`,
              surveyInfo: `Window to complete this survey expires ${parseExpireSurveyTime(
                firebaseTimestampToDate(therapyMeta.surveys[0].expires),
              )}. Please complete your survey by then.`,
            };
          }
          setLinkAndText(linkAndText);
        });
        break;
      }
      case UserStatus.EARLY_TERMINATION_FOLLOWUP:
      case UserStatus.EXT_EARLY_TERMINATION_FOLLOWUP: {
        unSub.current = await TerminationMeta.fromUserSubscribe(user, async (terminationMeta) => {
          if (terminationMeta.surveysTerminal) {
            unSub.current?.();
            unSub.current = await TerminationCallMeta.subscribeCurrentCall(user, (terminationCallMeta) => {
              let linkAndText = {
                link: '/profile',
                text: 'NOT SET',
              };
              switch (terminationCallMeta.status) {
                case ScheduledCallStatus.NOT_SCHEDULED:
                  linkAndText = {
                    link: '/schedulecall',
                    text: 'Schedule followup call',
                  };
                  break;
                case ScheduledCallStatus.CALL_SCHEDULED:
                  linkAndText = {
                    link: '/schedulecall',
                    text: "You've scheduled your followup call",
                  };
                  break;
                case ScheduledCallStatus.ABANDONED:
                case ScheduledCallStatus.DECLINED:
                case ScheduledCallStatus.COMPLETED:
                  linkAndText = {
                    link: '/profile',
                    text: "You've completed the followup process, thanks for participating in the study!",
                  };
                  break;
                default:
                  break;
              }
              setLinkAndText(linkAndText);
            });
          } else {
            const linkAndText = {
              link: '/surveys',
              text: `${surveysStarted(terminationMeta.surveys) ? 'Finish' : 'Take'} followup survey`,
              surveyInfo: `Your followup window expires ${
                // All surveys will have same expire timestamp so can just take the first survey
                parseExpireSurveyTime(firebaseTimestampToDate(terminationMeta.surveys[0].expires))
              }. Please complete your survey by then.`,
            };
            setLinkAndText(linkAndText);
          }
        });
        break;
      }
      case UserStatus.EXT_ELIGIBLE:
      case UserStatus.EXT_DECLINED:
      case UserStatus.EXT_DONE:
      case UserStatus.DONE:
        setLinkAndText(doneLinkAndText);
        break;
      default: {
        const linkAndText = {
          link: '/profile',
          text: `Workflow status '${user.workflowStatus}' not caught in next button switch`,
        };
        setLinkAndText(linkAndText);
      }
    }
  }, []);

  useEffect(() => {
    unSub.current?.();
    if (userState.user && userState.user !== 'FIRSTLOAD') {
      getNextStep(userState.user);
    }
    return () => {
      unSub.current?.();
    };
  }, [userState.user, getNextStep]);

  return (
    <>
      {linkAndText ? (
        linkAndText.link === '/profile' ? (
          <Row className='justify-content-center'>
            <h4 className='text-center' style={{ whiteSpace: 'pre-wrap' }}>
              {linkAndText.text}
            </h4>
          </Row>
        ) : (
          <Row className='justify-content-center mt-4'>
            <Link to={linkAndText.link}>
              <Button id='call-to-action-btn' className='btn-icon btn-3 btn-lg CTA-btn' type='button'>
                <span className='btn-inner--text'>{linkAndText.text}</span>
                <span className='btn-inner--icon mr-1'>
                  <i className='ni ni-curved-next' />
                </span>
              </Button>
            </Link>
          </Row>
        )
      ) : (
        <LoadingIcon text={false} />
      )}
      {linkAndText?.surveyInfo && (
        <Row className='justify-content-center mt-3'>
          <p>{linkAndText.surveyInfo}</p>
        </Row>
      )}
    </>
  );
};

export default NextStepButton;
