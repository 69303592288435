import React from 'react';
import { Button, Row } from 'reactstrap';
import { GoogleIcon, AppleSignIn } from '../../../assets/icons/svgicons';
import { signInWithGoogle } from '../../../utils/firebase';
import './SignInWith.scss';

// Options for the user to sign in with third party
const SignInWith = (props: { signInWithApple: any }) => {
  const { signInWithApple } = props;
  return (
    <>
      <Row className='justify-content-center'>
        <h6 className='mb-3'>Sign in with</h6>
      </Row>
      <Row className='justify-content-center'>
        <Button id='signInWithGoogle' className='btn-neutral btn-icon' onClick={signInWithGoogle}>
          <span className='btn-inner--icon mr-1'>
            <GoogleIcon height='36px' width='36px' />
          </span>
          <span className='btn-inner--text text-dark'>Google</span>
        </Button>
      </Row>
      <Row className='justify-content-center'>
        <span id='signInWithApple' onClick={signInWithApple}>
          <AppleSignIn />
        </span>
      </Row>
    </>
  );
};

export default SignInWith;
