import { IScheduleCall, ScheduledCallStatus, UserStatus } from '@swing-therapeutics/surveybay/dist/types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Row } from 'reactstrap';
import { ScheduleCall } from '../../../models/calls/ScheduleCall';
import type { User } from '../../../models/User';
import UserStateContext from '../../../utils/contexts/UserContext';
import { TherapyMeta } from '../../../models/survey/TherapyMeta';

const ScheduleCallButtons: React.FC = () => {
  const { userState } = useContext(UserStateContext);
  const [callOption, setCallOption] = useState<IScheduleCall | null>(null);
  const unSubCurrentCall = useRef<() => void>();
  const unSubAllCallUpdated = useRef<() => void>();
  const scheduleCall = useRef<ScheduleCall>();
  const history = useHistory();
  const [userData, setUserData] = useState<User | null>(null);
  const [therapyMeta, setTherapyMeta] = useState(null as any);

  const checkCallOption = useCallback(async () => {
    unSubAll();
    if (
      userState.user &&
      userState.user !== 'FIRSTLOAD' &&
      // Scheduled calls are only for extension therapy and ext done right now
      [UserStatus.EXT_THERAPY, UserStatus.EXT_PRE_THERAPY, UserStatus.EXT_DONE].includes(userState.user.workflowStatus)
    ) {
      unSubCurrentCall.current = await ScheduleCall.subscribeCurrentCall(userState.user, (_scheduleCall) => {
        if (!_scheduleCall || ![ScheduledCallStatus.NOT_SCHEDULED, ScheduledCallStatus.CALL_SCHEDULED].includes(_scheduleCall.status)) {
          // No relavent call option to subscribe to or call option is not scheduled or scheduled, all other status is terminal, nothing for user to do
          setCallOption(null);
          // Watch for any updates to any call, run a recursion when we get an update
          unSubAllCallUpdated.current = ScheduleCall.subscribeAnyCallUpdate(userState.user as User, checkCallOption);
          return;
        }
        // Unsub to all calls updates, we have a call we are interacting with
        unSubAllCallUpdated.current?.();
        scheduleCall.current = _scheduleCall;
        setCallOption({ ..._scheduleCall });
      });
    }
  }, [userState.user]);

  const handleDeclineClick = useCallback(() => {
    if (scheduleCall.current) {
      // Decline the call and save it
      scheduleCall.current.declineCurrentCall();
      scheduleCall.current.persist();
      // Check for a new call option
      setCallOption(null);
      checkCallOption();
    }
  }, [checkCallOption]);

  const getTherapyMeta = async () => {
    if ((userData !== null && userData.workflowStatus === UserStatus.EXT_THERAPY) || userData?.workflowStatus === UserStatus.THERAPY) {
      await TherapyMeta.fromUser(userData).then((doc) => setTherapyMeta(doc));
    }
  };

  const deleteOldProductFeedback = useCallback(() => {
    if (therapyMeta && callOption?.therapyWeek) {
      if (therapyMeta.therapyWeek - callOption.therapyWeek > 1 && callOption.title.match(/product feedback/gi)) {
        handleDeclineClick();
      }
    }
  }, [callOption, handleDeclineClick, therapyMeta]);

  useEffect(() => {
    if (userState.user && userState.user !== 'FIRSTLOAD') {
      setUserData(userState.user);
      getTherapyMeta();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState, userData, callOption]);

  useEffect(() => {
    deleteOldProductFeedback();
  }, [callOption, deleteOldProductFeedback, handleDeclineClick, therapyMeta]);

  const unSubAll = () => {
    unSubCurrentCall.current?.();
    unSubAllCallUpdated.current?.();
  };

  useEffect(() => {
    setCallOption(null);
    checkCallOption();
    return unSubAll;
  }, [checkCallOption]);

  const handleScheduleCallClick = useCallback(() => {
    history.push('/schedulecall');
  }, [history]);

  return (
    callOption && (
      <>
        <Row className='mt-3 justify-content-center'>
          <h6 style={{ textAlign: 'center' }}>{callOption.description}</h6>
        </Row>
        <Row className='justify-content-center'>
          <Button id='call-to-action-btn' className='btn-icon btn-3 btn-lg CTA-btn m-3' type='button' onClick={handleScheduleCallClick}>
            <span className='btn-inner--text'>
              {callOption.status === ScheduledCallStatus.CALL_SCHEDULED ? "You've scheduled your call" : 'Schedule your call'}
            </span>
            <span className='btn-inner--icon mr-1'>
              <i className='ni ni-curved-next' />
            </span>
          </Button>
          {!callOption.required && callOption.status !== ScheduledCallStatus.CALL_SCHEDULED && (
            <Button id='call-to-action-btn' className='btn-icon btn-3 btn-lg CTA-btn m-3' type='button' onClick={handleDeclineClick}>
              <span className='btn-inner--text'>No thanks</span>
            </Button>
          )}
        </Row>
      </>
    )
  );
};

export default ScheduleCallButtons;
