import classNames from 'classnames';
import React, { useContext, useCallback, useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import AppFooter from './AppFooter';
import AppNavBar from './AppNavBar';
import Disclaimer from './Disclaimer';
import styles from './Layout.module.scss';
import TempoHeader from '../../assets/img/tempo-header-1.png';
import AdminNav from './AdminNav';
import UserStateContext from '../../utils/contexts/UserContext';
import LandingPageStyling from '../../models/landing/LandingPageStyling';
import ContentContext from '../../utils/contexts//ContentContext/ContentContext';
import { RequestError } from '../../models/RequestError';
interface LayoutProps {
  showNavBar?: boolean;
  showFooter?: boolean;
  showDisclaimer?: boolean;
  children: any;
  header?: {
    title?: string;
    subtitle?: string;
    subtitleType?: 'p' | 'leadin';
    bannerImg?: string;
  };
  navSpace?: boolean;
}

// A wrapper for all page/view components to display footer and navbar
const Layout: React.FC<LayoutProps> = ({ showNavBar = true, showFooter = true, showDisclaimer = false, children, header, navSpace = false }) => {
  const { userState } = useContext(UserStateContext);
  const { setButtonColor } = useContext(ContentContext);
  const [landingPage, setLandingPage] = useState<LandingPageStyling>();

  const fetchAndSetLandingPage = useCallback(async (landingKey) => {
    const landing = await LandingPageStyling.fetchLandingPage(landingKey);
    if (landing instanceof RequestError) return;
    setLandingPage(landing);
  }, []);

  useEffect(() => {
    if (userState?.landingPageKey) {
      fetchAndSetLandingPage(userState.landingPageKey);
    }
  }, [fetchAndSetLandingPage, userState.landingPageKey]);

  useEffect(() => {
    if (landingPage) {
      setButtonColor(landingPage?.metadata?.buttonColor);
    }
  }, [landingPage, setButtonColor]);

  const headerImage = landingPage?.header.banner || TempoHeader;
  const headerTitleColor = landingPage?.header.title.color || '#5A9893';
  const headerTitleStyle = { color: headerTitleColor };
  const headerSubtitleColor = landingPage?.header.subtitle.color || '#000000';
  const headerSubtitleStyle = { color: headerSubtitleColor, fontWeight: 400 };

  return (
    <>
      {showNavBar && <AppNavBar header={landingPage?.header} />}
      {showDisclaimer && <Disclaimer />}
      {
        // Add space at the top of page to block content from being under nav bar
        // Not necessary if there is a header
        navSpace && !header && <div style={{ height: 82 }} />
      }
      <main className={classNames(styles.main, 'position-relative')}>
        {header && (
          <Row className={classNames(styles.header)}>
            <img src={headerImage} className={styles.headerimg} alt='Header' />
            <Col className={classNames(styles.textcont, 'mt-4 mt-lg-0')}>
              <Row className='justify-content-center px-5'>
                <h1 className='text-center' style={headerTitleStyle}>
                  {header.title}
                </h1>
              </Row>
              <Row className='justify-content-center px-5'>
                {
                  {
                    p: (
                      <p className='text-center' style={headerSubtitleStyle}>
                        {header.subtitle}
                      </p>
                    ),
                    leadin: (
                      <h5 className='text-center leadin' style={headerSubtitleStyle}>
                        {header.subtitle}
                      </h5>
                    ),
                  }[header.subtitleType || 'leadin']
                }
              </Row>
            </Col>
          </Row>
        )}
        {children}
      </main>
      {showFooter && <AppFooter logo={landingPage?.footer.logo} textColor={landingPage?.footer.textColor} bgColor={landingPage?.footer.bgColor} />}
      <AdminNav />
    </>
  );
};

export default Layout;
