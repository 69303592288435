import React, { useContext, useCallback, useState, useEffect, useRef } from 'react';
import { ScheduledCallStatus, UserStatus, WorkflowStatusMap, WorkflowType } from '@swing-therapeutics/surveybay/dist/types';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import UserStateContext from '../../../utils/contexts/UserContext';
import { firestore, functions } from '../../../utils/firebase';
import styles from './AdminNav.module.scss';
import LoadingIcon from '../../common/LoadingIcon';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import LandingPage from '../../../models/landing/LandingPage';
import { RequestError } from '../../../models/RequestError';
import type { User } from '../../../models/User';
import { TerminationCallMeta } from '../../../models/calls/TerminationCallMeta';
import { TerminationMeta } from '../../../models/survey/TerminationMeta';

enum ModalContent {
  EXPIRE_SURVEY,
  DELETE_USER,
  EXTENSION,
}

enum LoadingStep {
  JUMP = 'Jumping to next step...',
  SET_SURVEY_EXPIRE = 'Setting survey expire date...',
  EXPIRING_SURVEYS = 'Expiring surveys...',
  DELETING_SELF = 'Deleting self...',
  COMPLETE_THERAPY_SURVEYS = 'Completing therapy surveys...',
}

type ModalState = false | ModalContent;
type LoadingState = false | LoadingStep;

// Only allow admin navigation if the users role is equal to 'surveysaurus-tester'
// Note that calling the function for admin navigation or any other ssFunction does an extra check on the backend to make sure the user
// is in the surveysaurus-tester roles collection and cant just be changed on the user's document to receive the surveysaurus-tester role
const AdminNav: React.FC = () => {
  const { userState } = useContext(UserStateContext);
  const [error, setError] = useState('');
  const prevStatus = useRef<UserStatus>();
  const [loading, setLoading] = useState<LoadingState>(false);
  const [modalState, setModalState] = useState<ModalState>(false);
  const [minimized, setMinimized] = useState(false);
  const [extOption, setExtOption] = useState(false);
  const [tFollowupCallComplete, setTFollowupCallComplete] = useState(true);
  const [tFollowupSurveyComplete, setTFollowupSurveyComplete] = useState(true);

  useEffect(() => {
    if (userState.user && userState.user !== 'FIRSTLOAD') {
      const prevStatusCP = prevStatus.current;
      prevStatus.current = userState.user.workflowStatus;
      if (prevStatusCP !== userState.user.workflowStatus) {
        // When the workflow status changes we know that the cloud function
        // Is done jumping the user
        setLoading(false);
      }
    }
  }, [userState.user]);

  useEffect(() => {
    // Watch to see if the followup process is complete, it is completed when
    // the termination call is in a terminal status, admin nav can keep jumping until it is done
    // There is no workflow change when the survey are completed which is why this need to happen
    let unsub: (() => void)[] = [];
    const watchFollowupComplete = async () => {
      if (
        userState.user &&
        userState.user !== 'FIRSTLOAD' &&
        [UserStatus.EXT_EARLY_TERMINATION_FOLLOWUP, UserStatus.EARLY_TERMINATION_FOLLOWUP].includes(userState.user.workflowStatus)
      ) {
        const unsub1 = await TerminationCallMeta.subscribeCurrentCall(userState.user, (terminationCall) => {
          if ([ScheduledCallStatus.COMPLETED, ScheduledCallStatus.DECLINED].includes(terminationCall.status)) {
            // Call is terminal so its completed
            setTFollowupCallComplete(true);
          } else {
            setTFollowupCallComplete(false);
          }
        });
        unsub.push(unsub1);
        const unsub2 = await TerminationMeta.fromUserSubscribe(userState.user, (terminationMeta) => {
          setTFollowupSurveyComplete(terminationMeta.surveysTerminal);
        });
        unsub.push(unsub2);
      }
    };
    watchFollowupComplete();
    return () => {
      unsub.forEach((_unsub) => _unsub());
    };
  }, [userState.user]);

  const checkExtOption = useCallback(async () => {
    setExtOption(false);
    if (
      userState.user &&
      userState.user !== 'FIRSTLOAD' &&
      [UserStatus.EARLY_TERMINATION, UserStatus.EARLY_TERMINATION_FOLLOWUP].includes(userState.user.workflowStatus)
    ) {
      // Cant go into extension from early termination
      return;
    }
    if (userState.landingPageKey === '') return;
    const landingPage = await LandingPage.fetchLandingPage(userState.landingPageKey);
    if (landingPage instanceof RequestError) {
      return;
    }
    if (landingPage.extensionID) {
      setExtOption(true);
    }
  }, [userState.landingPageKey, userState.user]);

  useEffect(() => {
    checkExtOption();
  }, [checkExtOption]);

  const handleJumpClick = useCallback(async () => {
    setLoading(LoadingStep.JUMP);
    setError('');
    const ssAdminNav = functions.httpsCallable('ssAdminNav');
    const result = await ssAdminNav({});
    if (result.data.error) {
      console.error('Error performing admin navigation', result.data.error);
      setError(result.data.error.message);
      setLoading(false);
    }
    if (
      userState.user &&
      userState.user !== 'FIRSTLOAD' &&
      [UserStatus.THERAPY, UserStatus.EXT_THERAPY, UserStatus.EXT_EARLY_TERMINATION_FOLLOWUP, UserStatus.EARLY_TERMINATION_FOLLOWUP].includes(
        userState.user.workflowStatus,
      )
    ) {
      // Handle done loading differently here, workflow status doesnt update when the function is done running
      setLoading(false);
    }
  }, [userState.user]);

  const handleSetSurveysExpire = useCallback(async (newExpDate: string) => {
    setLoading(LoadingStep.SET_SURVEY_EXPIRE);
    setModalState(false);
    setError('');
    const ssSetSurveysExpire = functions.httpsCallable('ssSetSurveysExpire');
    const result = await ssSetSurveysExpire({ newExpDate });
    if (result.data.error) {
      console.error('Error performing set expire survey date', result.data.error);
      setError(result.data.error.message);
    }
    setLoading(false);
  }, []);

  const handleExpireSurveys = useCallback(async () => {
    setLoading(LoadingStep.EXPIRING_SURVEYS);
    setError('');
    const ssExpireSurveys = functions.httpsCallable('ssExpireSurveys');
    const result = await ssExpireSurveys({});
    if (result.data.error) {
      console.error('Error performing expire survey', result.data.error);
      setError(result.data.error.message);
    }
    setLoading(false);
  }, []);

  const handleDeleteUser = useCallback(async () => {
    setLoading(LoadingStep.DELETING_SELF);
    setModalState(false);
    setError('');
    const ssDeleteSelf = functions.httpsCallable('ssDeleteSelf');
    const result = await ssDeleteSelf({});
    if (result.data.error) {
      console.error('Error performing self delete', result.data.error);
      setError(result.data.error.message);
      setLoading(false);
    }
    // If there is no error dont need to change state, user would have been signed out
  }, []);

  const handleCompleteTherapySurveys = useCallback(async () => {
    setLoading(LoadingStep.COMPLETE_THERAPY_SURVEYS);
    setError('');
    const ssCompleteTherapySurveys = functions.httpsCallable('ssCompleteTherapySurveys');
    const result = await ssCompleteTherapySurveys({});
    if (result.data.error) {
      console.error('Error performing complete therapy surveys', result.data.error);
      setError(result.data.error.message);
    }
    setLoading(false);
  }, []);

  const handleJumpToExtEligible = useCallback(async () => {
    setLoading(LoadingStep.JUMP);
    setModalState(false);
    setError('');
    // We can assume the useState.user is of type User here (see where this is called from for proof)
    await firestore.doc(`users/${(userState.user as User).uid}`).update({
      workflowStatus: UserStatus.EXT_ELIGIBLE,
    });
  }, [userState.user]);

  // On the user's document there exists a single `role` field, but users can have multiple roles (see tempest roles collection)
  // So inculed admin nav for surveysaurus-tester or admin bc the app is resetting `role` to admin if a user has an admin role
  return userState.user && userState.user !== 'FIRSTLOAD' && ['surveysaurus-tester', 'admin'].includes(userState.user.role) ? (
    <>
      {minimized ? (
        <div className={styles.root} style={{ padding: '10px 20px' }}>
          <Row className='justify-content-center'>
            <FontAwesomeIcon icon={faPlus} onClick={() => setMinimized(false)} className='hoverable' />
          </Row>
        </div>
      ) : (
        <div className={styles.root}>
          <Row className='justify-content-end'>
            <FontAwesomeIcon icon={faMinus} onClick={() => setMinimized(true)} className='hoverable' />
          </Row>
          <Row>
            <Col>
              <h6 className='mb-0 text-center'>Admin navigation</h6>
              <p className='mb-0 text-center'>Landing page:</p>
              <p id='workflow-status' className='mb-0 text-center text-primary'>
                <b>{userState.landingPageKey}</b>
              </p>
              <p className='mb-0 text-center'>Current workflow status:</p>
              <p id='workflow-status' className='mb-0 text-center text-primary'>
                <b>{userState.user.workflowStatus}</b>
              </p>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col>
              {loading !== false ? (
                <>
                  <Row className='justify-content-center'>
                    <p>{loading}</p>
                  </Row>
                  <Row className='justify-content-center'>
                    <LoadingIcon text={false} />
                  </Row>
                </>
              ) : (
                <>
                  {([
                    UserStatus.SCREENING,
                    UserStatus.CONSENT,
                    UserStatus.CONSENT_ABANDONED,
                    UserStatus.EXT_CONSENT,
                    UserStatus.EXT_CONSENT_ABANDONED,
                    UserStatus.BASELINE,
                    UserStatus.PRE_THERAPY,
                    UserStatus.THERAPY,
                    UserStatus.EXT_PRE_THERAPY,
                    UserStatus.EXT_THERAPY,
                    UserStatus.EXT_ELIGIBLE,
                  ].includes(userState.user.workflowStatus) ||
                    !tFollowupCallComplete ||
                    !tFollowupSurveyComplete) && (
                    <Row className={styles.buttonrow}>
                      <Button className='btn-icon btn-3 btn-lg CTA-btn' type='button' onClick={handleJumpClick}>
                        <span className='btn-inner--text'>
                          Jump
                          {
                            //@ts-ignore
                            {
                              [UserStatus.THERAPY]: ' therapy week',
                              [UserStatus.EXT_THERAPY]: ' therapy week',
                              [UserStatus.EXT_ELIGIBLE]: ' into extension',
                            }[userState.user.workflowStatus] || ''
                          }
                        </span>
                        <span className='btn-inner--icon mr-1'>
                          <i className='ni ni-curved-next' />
                        </span>
                      </Button>
                    </Row>
                  )}
                  {([UserStatus.BASELINE, UserStatus.SCREENING, UserStatus.THERAPY, UserStatus.EXT_THERAPY].includes(userState.user.workflowStatus) ||
                    !tFollowupSurveyComplete) && (
                    <>
                      {[UserStatus.THERAPY, UserStatus.EXT_THERAPY].includes(userState.user.workflowStatus) && (
                        <Row className={styles.buttonrow}>
                          <Button className='btn-icon btn-3 btn-lg CTA-btn' type='button' onClick={handleCompleteTherapySurveys}>
                            <span className='btn-inner--text'>Complete assigned surveys</span>
                          </Button>
                        </Row>
                      )}
                      <Row className={styles.buttonrow}>
                        <Button className='btn-icon btn-3 btn-lg CTA-btn' type='button' onClick={() => setModalState(ModalContent.EXPIRE_SURVEY)}>
                          <span className='btn-inner--text'>Select survey expire date</span>
                        </Button>
                      </Row>
                      <Row className={styles.buttonrow}>
                        <Button className='btn-icon btn-3 btn-lg CTA-btn' type='button' onClick={handleExpireSurveys}>
                          <span className='btn-inner--tåext'>Expire surveys</span>
                        </Button>
                      </Row>
                    </>
                  )}
                  {WorkflowStatusMap[userState.user.workflowStatus] === WorkflowType.STANDARD && extOption && (
                    <Row className={styles.buttonrow}>
                      <Button className='btn-icon btn-3 btn-lg CTA-btn' type='button' onClick={() => setModalState(ModalContent.EXTENSION)}>
                        <span className='btn-inner--text'>Extension</span>
                      </Button>
                    </Row>
                  )}
                  <Row className={styles.buttonrow}>
                    <Button className='btn-icon btn-3 btn-lg CTA-btn' type='button' onClick={() => setModalState(ModalContent.DELETE_USER)}>
                      <span className='btn-inner--text'>Delete current user</span>
                    </Button>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {error && (
            <Row className='mt-3'>
              <p className='text-warning mb-0' style={{ maxWidth: 200 }}>
                {error}
              </p>
            </Row>
          )}
        </div>
      )}
      <Modal className='modal-dialog-centered' isOpen={modalState !== false} toggle={() => setModalState(false)}>
        {modalState !== false &&
          {
            [ModalContent.EXPIRE_SURVEY]: <ExpireSurveyModalContent onSetSurveysExpire={handleSetSurveysExpire} />,
            [ModalContent.DELETE_USER]: <DeleteUserModalConfirmation onDeleteConfirm={handleDeleteUser} />,
            [ModalContent.EXTENSION]: <ExtensionModal onJumpToExtEligible={handleJumpToExtEligible} />,
          }[modalState]}
      </Modal>
    </>
  ) : null;
};

export default AdminNav;

interface ExpireSurveyMProps {
  onSetSurveysExpire: (newExpDate: string) => void;
}

const ExpireSurveyModalContent: React.FC<ExpireSurveyMProps> = ({ onSetSurveysExpire }) => {
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));

  const handleSetSurveyExpireClick = () => {
    onSetSurveysExpire(selectedDate);
  };

  return (
    <>
      <div className='modal-header'>
        <h3 className='mb-0'>
          <small>Select survey expire date</small>
        </h3>
      </div>
      <ModalBody>
        <Row className='m-2'>
          <p>This expire date will apply to your current surveys that are not in a terminal state (completed, expire, expired in progress).</p>
        </Row>
        <Row className='justify-content-center'>
          <input type='date' value={selectedDate} onChange={(event) => setSelectedDate(event.target.value)} />
        </Row>
        <Row className='justify-content-center mt-3'>
          <Button className='btn-icon btn-3 btn-lg CTA-btn' type='button' onClick={handleSetSurveyExpireClick}>
            <span className='btn-inner--text'>Set survey expire date</span>
            <span className='btn-inner--icon mr-1'>
              <i className='ni ni-curved-next' />
            </span>
          </Button>
        </Row>
      </ModalBody>
    </>
  );
};

interface DeleteUserMProps {
  onDeleteConfirm: () => void;
}

const DeleteUserModalConfirmation: React.FC<DeleteUserMProps> = ({ onDeleteConfirm }) => {
  return (
    <>
      <div className='modal-header'>
        <h3 className='mb-0'>
          <small>Confirm delete</small>
        </h3>
      </div>
      <ModalBody>
        <Row className='m-2'>
          <p>
            You are deleting your account. All data tied to this user (Tempo App and REACT-FM) will be deleted. Do this to reset your Surveysaurus
            account
          </p>
        </Row>
        <Row className='justify-content-center mt-3'>
          <Button className='btn-icon btn-3 btn-lg CTA-btn' type='button' onClick={onDeleteConfirm}>
            <span className='btn-inner--text'>Confirm delete</span>
          </Button>
        </Row>
      </ModalBody>
    </>
  );
};

interface ExtensionModalProps {
  onJumpToExtEligible: () => void;
}

const ExtensionModal: React.FC<ExtensionModalProps> = ({ onJumpToExtEligible }) => {
  return (
    <>
      <div className='modal-header'>
        <h3 className='mb-0'>
          <small>Extension</small>
        </h3>
      </div>
      <ModalBody>
        <Row className='m-2'>
          <p>
            When jumping to extension eligible state, your user may not actually be eligible for the extension if other checks exist for specific
            landing pages. A default landing page with no extra checks allows a user to be eligible if they have made it through the standard portion
            of the trial. Some landing pages (ex. 003) have extra checks involed with being eligible.
          </p>
        </Row>
        <Row className='justify-content-center mt-3'>
          <Button className='btn-icon btn-3 btn-lg CTA-btn' type='button' onClick={onJumpToExtEligible}>
            <span className='btn-inner--text'>Jump to extension eligible</span>
            <span className='btn-inner--icon mr-1'>
              <i className='ni ni-curved-next' />
            </span>
          </Button>
        </Row>
      </ModalBody>
    </>
  );
};
