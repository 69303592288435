import React, { useContext, useEffect, useCallback } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import UserStateContext from '../contexts/UserContext';
import ContentContext from '../contexts/ContentContext/ContentContext';
import FailPage from '../../views/FailPage';
import LoadingScreen from '../../views/LoadingScreen';
import { UserFail, UserStatus } from '@swing-therapeutics/surveybay/dist/types';
import LandingPageStyling from '../../models/landing/LandingPageStyling';
import { RequestError } from '../../models/RequestError';

// All protected routes require a user sign in
// Add additional protection to the route besides just being signed in
// Careful with adding addionalProtection, could create infinite loop if not implemented correctly
export enum ProtectionTypes {
  // Route is a workflow route,
  // ensure the user has the correct status to land on the requested page
  WORKFLOW = 'workflow',
  // Route requires the user to have a user's doc in firestore
  ACCOUNT_EXISTS = 'account_exists',
}

interface ProtectRouteProps extends RouteProps {
  addedProtection?: ProtectionTypes;
}

const ProtectedRoute: React.FC<ProtectRouteProps> = ({ addedProtection, ...routeProps }) => {
  const { userState } = useContext(UserStateContext);
  const { appContentState, appContentStateDispatch } = useContext(ContentContext);

  const setLandingPageTitle = useCallback(
    async (landingPageKey): Promise<boolean> => {
      const response = await LandingPageStyling.fetchLandingPage(landingPageKey);
      if (response instanceof RequestError) {
        return false;
      }

      if (response.metadata.title !== appContentState.studyTitle) {
        appContentStateDispatch({ type: 'SETLPDATA', payload: response });
      }
      return true;
    },
    [appContentState.studyTitle, appContentStateDispatch],
  );

  useEffect(() => {
    if (userState.landingPageKey === '') return;
    setLandingPageTitle(userState.landingPageKey).then((exists) => {
      if (!exists) {
        return;
      }
    });
  });

  if (userState.user === 'FIRSTLOAD') {
    // Need to set 'component' prop to undefined bc we cant have a component and render prop
    return <Route {...routeProps} component={undefined} render={(props) => <LoadingScreen {...props} />} />;
  } else if (userState.user) {
    if (!addedProtection) {
      return <Route {...routeProps} />;
    } else if (addedProtection === ProtectionTypes.ACCOUNT_EXISTS && userState.user.workflowStatus !== UserStatus.CREATING) {
      return <Route {...routeProps} />;
    } else if (addedProtection === ProtectionTypes.WORKFLOW && userState.user.workflowStatus !== UserStatus.CREATING) {
      if (!userState.user.workflowStatus) {
        // No workflowStatus for user, account wasn't created with surveysaurus aka
        // the account already existed and user was already enrolled in program
        // Take them to the not eligible page
        if (routeProps.location) {
          // location should always be defined but ts doesnt know that
          // Set the location state for the Fail page
          routeProps.location.state = {
            failReason: UserFail.EXISTING_ACCOUNT,
          };
        }
        return <Route {...routeProps} component={FailPage} />;
      }
      // Check if the user has a fail status
      const failReason = checkFailStatus(userState.user.workflowStatus);
      if (failReason) {
        // Redirect to fail page
        if (routeProps.location) {
          // location should always be defined but ts doesnt know that
          // Set the location state for the Fail page
          routeProps.location.state = {
            failReason,
          };
        }
        return <Route {...routeProps} component={FailPage} />;
      }
      if (userState.user.workflowStatus === UserStatus.CREATED) {
        // User's status is CREATED which means the cloud function should be updating the user status
        // to SCREENING very soon, show loading page while cloud function does its thing
        return <Route {...routeProps} component={undefined} render={(props) => <LoadingScreen {...props} text='Setting up your account...' />} />;
      }
      if (routeProps.path === '/profile') {
        return <Route {...routeProps} />;
      }
      // At this point the user has been cleared to access protected workflow pages
      if (routeProps.location?.pathname.includes('/surveys')) {
        if (routeProps.path === '/surveys') {
          return surveyRedirect(userState.user.workflowStatus);
        }
        if (userState.user.workflowStatus === UserStatus.SCREENING && routeProps.location.pathname === '/surveys/eligibility') {
          return <Route {...routeProps} />;
        }
        if (userState.user.workflowStatus === UserStatus.BASELINE && routeProps.location.pathname === '/surveys/baseline') {
          return <Route {...routeProps} />;
        }
        if (
          [UserStatus.THERAPY, UserStatus.EXT_THERAPY].includes(userState.user.workflowStatus) &&
          routeProps.location.pathname === '/surveys/therapy'
        ) {
          return <Route {...routeProps} />;
        }
        if (
          [UserStatus.EXT_EARLY_TERMINATION_FOLLOWUP, UserStatus.EARLY_TERMINATION_FOLLOWUP].includes(userState.user.workflowStatus) &&
          routeProps.location.pathname === '/surveys/followup'
        ) {
          return <Route {...routeProps} />;
        }
        return <Redirect to='/profile' />;
      }
      // The learn more page will redirect the user to the /consent page to allow them to schedule a call
      // When their status updates from EXT_ELIGIBLE to EXT_CONSNET
      if ([UserStatus.EXT_ELIGIBLE, UserStatus.EXT_CONSENT].includes(userState.user.workflowStatus) && routeProps.path === '/learnmore') {
        return <Route {...routeProps} />;
      }
      if (
        [UserStatus.CONSENT, UserStatus.CONSENT_ABANDONED, UserStatus.EXT_CONSENT, UserStatus.EXT_CONSENT_ABANDONED].includes(
          userState.user.workflowStatus,
        ) &&
        routeProps.path === '/consent'
      ) {
        return <Route {...routeProps} />;
      }
      return <Redirect to='/profile' />;
    }
  }

  return <Redirect to={`/signin/${userState.landingPageKey}`} />;
};

const surveyRedirect = (userStatus: UserStatus) => {
  // Redirect to proper survey page based on user's status
  switch (userStatus) {
    case UserStatus.SCREENING:
      return <Redirect to='/surveys/eligibility' />;
    case UserStatus.BASELINE:
      return <Redirect to='/surveys/baseline' />;
    case UserStatus.EXT_THERAPY:
    case UserStatus.THERAPY:
      return <Redirect to='/surveys/therapy' />;
    case UserStatus.EARLY_TERMINATION_FOLLOWUP:
    case UserStatus.EXT_EARLY_TERMINATION_FOLLOWUP:
      return <Redirect to='/surveys/followup' />;
    default:
      return <Redirect to='/profile' />;
  }
};

const checkFailStatus = (userStatus: UserStatus): false | UserFail => {
  switch (userStatus) {
    case UserStatus.SCREENING_FAIL:
      return UserFail.SCREENING_FAIL;
    case UserStatus.EXT_CONSENT_FAIL:
    case UserStatus.CONSENT_FAIL:
      return UserFail.CONSENT_FAIL;
    case UserStatus.BASELINE_ABANDONED:
      return UserFail.BASELINE_ABANDONED;
    case UserStatus.SCREENING_ABANDONED:
      return UserFail.SCREENING_ABANDONED;
    // Never started and early termination
    // Show the same fail message
    case UserStatus.EXT_NEVER_STARTED:
    case UserStatus.NEVER_STARTED:
    case UserStatus.EXT_EARLY_TERMINATION:
    case UserStatus.EARLY_TERMINATION:
      return UserFail.EARLY_TERMINATION;
    default:
      return false;
  }
};

export default ProtectedRoute;
