import { UserStatus } from '@swing-therapeutics/surveybay/dist/types';
import React, { useCallback, useContext, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { Button, Container, Row } from 'reactstrap';
import LoadingIcon from '../../components/common/LoadingIcon';
import Section from '../../components/common/Section';
import Layout from '../../components/Layout';
import { User } from '../../models/User';
import { usePageLog } from '../../utils/analytics';
import UserStateContext from '../../utils/contexts/UserContext';
import ContentContext from '../../utils/contexts/ContentContext/ContentContext';
import { functions } from '../../utils/firebase';
import styles from './ExtLearnMore.module.scss';

const ExtLearnMore: React.FC<RouteComponentProps> = () => {
  usePageLog({ pageTitle: 'EXT Learn More' });
  const { userState } = useContext(UserStateContext);
  const { appContentState } = useContext(ContentContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleEnrollClick = useCallback(async () => {
    setLoading(true);
    setMessage('');
    const ssEnrollInExtension = functions.httpsCallable('ssEnrollInExtension');
    const result = await ssEnrollInExtension({});
    if (result.data.error) {
      console.error('Error enrolling in extension', result.data.error);
      if (result.data.error.message === 'User is not eligible to enroll in extension') {
        setMessage('Not eligible for extension');
      }
      setLoading(false);
    }
    // If no error then the user will be taken back to profile page when their status updates
  }, []);

  const handleDeclineClick = useCallback(async () => {
    if (userState.user && userState.user !== 'FIRSTLOAD') {
      setLoading(true);
      const result = await User.declineExtension(userState.user);
      if (result !== 'Success') {
        // If successful the user will be taken back to the schedule call page,
        // If not, set loading to false
        setLoading(false);
      }
    }
  }, [userState]);

  return (
    <Layout navSpace={true}>
      {/* When the user enrolls in the extension their status will be updated to EXT_CONSENT, we then want to redirect them to the schedule call page (/consent) */}
      {userState.user && userState.user !== 'FIRSTLOAD' && userState.user.workflowStatus === UserStatus.EXT_CONSENT && <Redirect to='/consent' />}
      <Section minHeight='75vh'>
        <Container className={`${styles.container}`}>
          <Row className='justify-content-center'>
            <h4>
              The {appContentState.studyTitle} Extension trial is designed to test the long-term use and impact of the same Digital app used in the{' '}
              {appContentState.studyTitle}
              study. If you agree to enroll, you will continue to have access to the Digital App for an additional 9 months. During that time, you can
              use the Digital App as you see fit, whether that is daily, weekly, or some other way that best suits your needs. You will also complete
              surveys every month that are similar to the ones you completed in {appContentState.studyTitle} and be compensated for your time.
            </h4>
          </Row>
          <Row className='mt-3'>
            <h4>Please log into the PROSPER-FM participant portal to indicate your interest</h4>
          </Row>
          <Row className='mt-3 justify-content-center'>
            {loading ? (
              <LoadingIcon text={false} />
            ) : (
              <>
                <Button id='call-to-action-btn' className='btn-icon btn-3 btn-lg CTA-btn m-3' type='button' onClick={handleEnrollClick}>
                  <span className='btn-inner--text'>Schedule a call to hear more</span>
                </Button>
                <Button id='call-to-action-btn' className='btn-icon btn-3 btn-lg CTA-btn m-3' type='button' onClick={handleDeclineClick}>
                  <span className='btn-inner--text'>No thanks</span>
                </Button>
              </>
            )}
          </Row>
          {message && (
            <Row className='mt-3 justify-content-center'>
              <h6>{message}</h6>
            </Row>
          )}
        </Container>
      </Section>
    </Layout>
  );
};

export default ExtLearnMore;
