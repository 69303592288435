import React from 'react';
import { RouteComponentProps } from 'react-router';
import LoadingIcon from '../../components/common/LoadingIcon';
import Section from '../../components/common/Section';
import Layout from '../../components/Layout';

interface LoadingScreenProps extends RouteComponentProps {
  text?: string | boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ text = false }) => {
  return (
    <Layout>
      <Section height='75vh'>
        <div className='d-flex fill justify-content-center'>
          <LoadingIcon text={text} />
        </div>
      </Section>
    </Layout>
  );
};

export default LoadingScreen;
