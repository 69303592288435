import classNames from 'classnames';
import React from 'react';
import styles from './LoadingIcon.module.scss';

interface LoadingIconProps {
  text?: string | boolean; // Default text is 'Loading...', set to false for no text
  textColor?: 'white' | 'dark' | 'success';
  height?: number | string;
}

const LoadingIcon: React.FC<LoadingIconProps> = ({ text = 'Loading...', textColor = 'dark', height }) => {
  return (
    <div style={{ height: height }} className='align-items-center justify-content-center d-flex flex-column'>
      {text && <h2 className={classNames('text-center mb-4 text-' + textColor, styles.infotext)}>{text}</h2>}
      <div className='d-flex justify-content-center'>
        <div className={styles.dot1} />
        <div className={styles.dot2} />
        <div className={styles.dot3} />
      </div>
    </div>
  );
};

export default LoadingIcon;
