import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Table, Tooltip } from 'reactstrap';
import { CompleteIcon, InProgressIcon, NotCompleteIcon } from '../../../assets/icons/svgicons';
import { RequestError } from '../../../models/RequestError';
import { TherapyMeta } from '../../../models/survey/TherapyMeta';
import { User } from '../../../models/User';
import UserStateContext from '../../../utils/contexts/UserContext';
import LoadingIcon from '../LoadingIcon';
import styles from './TherapyProgressTable.module.scss';
import { SurveyStatus, WorkflowStatusMap, WorkflowType } from '@swing-therapeutics/surveybay/dist/types';
import LandingPageCore from '../../../models/landing/LandingPageCore';

interface StatusIconProps {
  therapyWeek: number;
}

interface TherapyWeekInfo {
  week: number;
  status: 'completed' | 'assigned' | 'expired' | 'not-assigned';
  currentWeek: boolean;
}

const TherapyProgressTable: React.FC<{ setHideProgressSection: (arg0: boolean) => void }> = ({ setHideProgressSection }) => {
  const { userState } = useContext(UserStateContext);
  const [therapyWeeks, setTherapyWeeks] = useState<TherapyWeekInfo[]>();
  const [currentTherapyWeek, setCurrentTherapyWeek] = useState<number>();
  const unSubTherapyMeta = useRef<() => void>();
  const therapyVerbiage = userState.inExtension ? 'extension therapy' : 'therapy';

  const getTherapyWeeks = useCallback(async (user: User) => {
    const landingPageDoc = await LandingPageCore.fetchLandingPage(
      user.landingPageKey,
      WorkflowType.EXTENSION === WorkflowStatusMap[user.workflowStatus],
    );
    if (landingPageDoc instanceof RequestError) return;
    unSubTherapyMeta.current = await TherapyMeta.fromUserSubscribe(user, (therapyMeta) => {
      const therapyWeeks: TherapyWeekInfo[] = [];
      landingPageDoc.surveyWeeks.forEach((surveyWeek, index) => {
        if (surveyWeek.surveys.length > 0) {
          let status: 'completed' | 'assigned' | 'expired' | 'not-assigned' = 'not-assigned';
          if (therapyMeta.therapySurveys[index]) {
            const surveyStatus = therapyMeta.therapySurveys[index].surveys.map((survey) => survey.status);
            if (surveyStatus.includes(SurveyStatus.EXPIRED) || surveyStatus.includes(SurveyStatus.EXPIRED_IN_PROGRESS)) {
              status = 'expired';
            } else if (surveyStatus.includes(SurveyStatus.ASSIGNED) || surveyStatus.includes(SurveyStatus.IN_PROGRESS)) {
              status = 'assigned';
            } else {
              // Last case means the surveys are completed because we checked for every other status
              status = 'completed';
            }
          }
          therapyWeeks.push({
            week: surveyWeek.week,
            status,
            currentWeek: surveyWeek.week === therapyMeta.therapyWeek,
          });
        }
      });
      setTherapyWeeks(therapyWeeks);
      setCurrentTherapyWeek(therapyMeta.therapyWeek);
    });
  }, []);

  useEffect(() => {
    if (userState.user && userState.user !== 'FIRSTLOAD') {
      getTherapyWeeks(userState.user);
    }
    return () => {
      unSubTherapyMeta.current?.();
    };
  }, [getTherapyWeeks, userState.user]);

  useEffect(() => {
    if (userState.user !== 'FIRSTLOAD') {
      if (!therapyWeeks) {
        setHideProgressSection(true);
      } else {
        setHideProgressSection(false);
      }
    }
  }, [userState, therapyWeeks, setHideProgressSection]);

  return !therapyWeeks ? (
    <div>{userState.user !== 'FIRSTLOAD' && userState?.user?.workflowStatus !== 'ext declined' && <LoadingIcon text={false} height={50} />}</div>
  ) : (
    <>
      {currentTherapyWeek && (
        <h5 className='text-dark'>
          You are on week <span id='therapy-week'>{currentTherapyWeek} </span> of {therapyVerbiage}
        </h5>
      )}
      <Table bordered className={classNames(styles.table, 'd-none d-md-table')}>
        <thead>
          <tr>
            {therapyWeeks.map((therapyWeek) => (
              <th key={therapyWeek.week} style={therapyWeek.currentWeek ? { color: 'var(--autumn)' } : undefined}>
                {`Week ${therapyWeek.week}`}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {therapyWeeks.map((therapyWeek) => {
              return (
                <td key={therapyWeek.week}>
                  {
                    {
                      // Inline switch statement
                      completed: <TherapyCompleteIcon therapyWeek={therapyWeek.week} />,
                      expired: <TherapyExpiredIcon therapyWeek={therapyWeek.week} />,
                      assigned: <TherapyInProgressIcon therapyWeek={therapyWeek.week} />,
                      'not-assigned': <TherapyNotAssigned therapyWeek={therapyWeek.week} />,
                    }[therapyWeek.status]
                  }
                </td>
              );
            })}
          </tr>
        </tbody>
      </Table>
      {/* Vertical table on small screens */}
      <Table bordered className={classNames(styles.table, 'd-table d-md-none')}>
        <thead>
          <tr>
            <th>Week</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {therapyWeeks.map((therapyWeek) => {
            return (
              <tr key={therapyWeek.week}>
                <th style={therapyWeek.currentWeek ? { color: 'var(--primary)' } : undefined}>{therapyWeek.week}</th>
                <td>
                  {
                    {
                      // Inline switch statement
                      completed: <TherapyCompleteIcon therapyWeek={therapyWeek.week} />,
                      expired: <TherapyExpiredIcon therapyWeek={therapyWeek.week} />,
                      assigned: <TherapyInProgressIcon therapyWeek={therapyWeek.week} />,
                      'not-assigned': <TherapyNotAssigned therapyWeek={therapyWeek.week} />,
                    }[therapyWeek.status]
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default TherapyProgressTable;

const TherapyCompleteIcon: React.FC<StatusIconProps> = ({ therapyWeek }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      <CompleteIcon id={`tooltip-therapyweek-${therapyWeek}`} className={styles.icon} />
      <Tooltip
        placement='bottom'
        isOpen={tooltipOpen}
        target={`tooltip-therapyweek-${therapyWeek}`}
        toggle={() => setTooltipOpen((prevState) => !prevState)}
        fade={false}
      >
        Survey completed!
      </Tooltip>
    </>
  );
};

const TherapyExpiredIcon: React.FC<StatusIconProps> = ({ therapyWeek }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      <NotCompleteIcon id={`tooltip-therapyweek-${therapyWeek}`} className={styles.icon} />
      <Tooltip
        placement='bottom'
        isOpen={tooltipOpen}
        target={`tooltip-therapyweek-${therapyWeek}`}
        toggle={() => setTooltipOpen((prevState) => !prevState)}
        fade={false}
      >
        Survey expired before completion
      </Tooltip>
    </>
  );
};

const TherapyInProgressIcon: React.FC<StatusIconProps> = ({ therapyWeek }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      <InProgressIcon id={`tooltip-therapyweek-${therapyWeek}`} className={styles.icon} />
      <Tooltip
        placement='bottom'
        isOpen={tooltipOpen}
        target={`tooltip-therapyweek-${therapyWeek}`}
        toggle={() => setTooltipOpen((prevState) => !prevState)}
        fade={false}
      >
        In progress
      </Tooltip>
    </>
  );
};

const TherapyNotAssigned: React.FC<StatusIconProps> = ({ therapyWeek }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      <p id={`tooltip-therapyweek-${therapyWeek}`} className='mb-0'>
        -
      </p>
      <Tooltip
        placement='bottom'
        isOpen={tooltipOpen}
        target={`tooltip-therapyweek-${therapyWeek}`}
        toggle={() => setTooltipOpen((prevState) => !prevState)}
        fade={false}
      >
        Survey not assigned yet
      </Tooltip>
    </>
  );
};
