import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ReactFMLogoLight } from '../../../assets/icons/svgicons';
import { version } from '../../../../package.json';

interface AppFooterProps {
  logo?: string;
  textColor?: string;
  bgColor?: string;
}

const AppFooter: React.FC<AppFooterProps> = ({ logo, textColor, bgColor }) => {
  //footer logo
  const footerLogoStyle = { height: '46px', width: '124px' };
  const footerLogo = logo ? <img src={logo} style={footerLogoStyle} /> : <ReactFMLogoLight style={footerLogoStyle} />;
  //footer text color
  const footerTxtColor = textColor ?? '#FFFFFF';
  const footerTxtStyle = { color: footerTxtColor };
  //footer background color
  const footerBgColor = bgColor ?? '#323051';
  const footerBgStyle = { backgroundColor: footerBgColor };

  return (
    <footer className='py-1 pb-3' style={footerBgStyle}>
      <Container>
        <Row className='p-2 pt-4' style={{ height: 172 }}>
          {process.env.REACT_APP_PRODUCTION_ENV !== 'true' && (
            <Col xs={12}>
              <Row className='justify-content-center'>
                <p className='mb-0' style={footerTxtStyle}>
                  <small>Connected to '{process.env.REACT_APP_FIREBASE_PROJECT_ID}'</small>
                </p>
              </Row>
              <Row className='justify-content-center'>
                <p className='my-0' style={footerTxtStyle}>
                  <small>Web app version v{version}</small>
                </p>
              </Row>
              <Row className='justify-content-center'>
                <p className='mb-0' style={footerTxtStyle}>
                  <small>
                    <i>Above text should not be visible in production</i>
                  </small>
                </p>
              </Row>
            </Col>
          )}
          <Col xs={6}>{footerLogo}</Col>
          <Col xs={6} className='pr-4'>
            <Row className='justify-content-end'>
              <p className='text-right mb-0' style={footerTxtStyle}>
                <small>
                  <Link to='/terms' style={footerTxtStyle}>
                    Terms of Use
                  </Link>
                  {' | '}
                  <Link to='/privacy' style={footerTxtStyle}>
                    Privacy Policy
                  </Link>
                </small>
              </p>
            </Row>
            <Row className='justify-content-end'>
              <p className='text-right mb-0' style={footerTxtStyle}>
                <small>
                  © {new Date().getFullYear()}{' '}
                  <a style={footerTxtStyle} href='https://www.swingtherapeutics.com' target='_blank' rel='noreferrer'>
                    Swing Therapeutics, Inc.
                  </a>{' '}
                  All rights reserved.
                </small>
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default AppFooter;
