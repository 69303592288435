import { ITerminationCallMeta, WorkflowID, WorkflowStatusMap, WorkflowType } from '@swing-therapeutics/surveybay/dist/types';
import { DocumentData, DocumentSnapshot, firestore } from '../../utils/firebase';
import { User } from '../User';
import { ScheduleCallAbstract } from './ScheduleCallAbstract';

const findTerminationCallDoc = (userUID: string, workflowType: WorkflowType) => {
  let workflowID = WorkflowID.EARLY_TERM_CALL;
  if (workflowType === WorkflowType.EXTENSION) {
    workflowID = WorkflowID.EXT_EARLY_TERM_CALL;
  }
  return firestore.collection(`users/${userUID}/workflows`).where('workflowID', '==', workflowID);
};

export class TerminationCallMeta extends ScheduleCallAbstract implements ITerminationCallMeta {
  employeeName: string;
  notes: string;

  constructor(props: any) {
    super(props);
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData | undefined>): TerminationCallMeta {
    const data = docSnap.data();
    if (!data) {
      throw new Error('Termination call meta doc has no data');
    }
    const consentMeta = new TerminationCallMeta(data);
    consentMeta.docPath = docSnap.ref.path;
    return consentMeta;
  }

  static async fromUser(user: User): Promise<TerminationCallMeta> {
    const collection = await findTerminationCallDoc(user.uid, WorkflowStatusMap[user.workflowStatus]).get();
    const doc = collection.docs[0];
    if (!doc?.exists) {
      throw new Error('Termination call meta doc doesnt exist for user');
    } else {
      return this.fromFirestore(doc);
    }
  }

  // Subscribe to the users current termination call, same as `fromUserSubscribe` but named differently to be same as method in ScheduleCall.ts
  static async subscribeCurrentCall(user: User, changeCallback: (terminationCallMeta: TerminationCallMeta) => void): Promise<() => void> {
    try {
      const collection = await findTerminationCallDoc(user.uid, WorkflowStatusMap[user.workflowStatus]).get();
      const doc = collection.docs[0];
      return doc.ref.onSnapshot((snap) => {
        if (!snap.exists) {
          return;
        }
        changeCallback(this.fromFirestore(snap));
      });
    } catch (error) {
      console.error(error);
      return () => {};
    }
  }
}
