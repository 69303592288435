import React from 'react';
// reactstrap carousel sometimes slides in the wrong direction
// react-bootstrap carousel does not have this issue
import { Carousel } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import BackButton from '../BackButton';
import QuestionDisplay from '@swing-therapeutics/surveybay/dist/FormComponents/QuestionDisplay';
import { Question, ResponseTypes } from '@swing-therapeutics/surveybay/dist/types';

interface QuestionCarouselProps {
  questions: Question[];
  questionIndex: number;
  navigation: boolean;
  debugNavigation?: boolean;
  handleQuestionAnswer: (answer: ResponseTypes) => void;
  handleQuestionNav: (direction: 'NEXT' | 'PREVIOUS' | number) => void;
}

const QuestionCarousel: React.FC<QuestionCarouselProps> = ({
  questions,
  questionIndex,
  navigation,
  debugNavigation = false,
  handleQuestionAnswer,
  handleQuestionNav,
}) => {
  // For displaying the navigation numbers as questions can be skipped
  // And therefore shouldn't have a number
  let questionNumberAccumulator = 1;

  return (
    <Col>
      <Row className='justify-content-center my-3'>
        <Carousel activeIndex={questionIndex} className='question-carousel' controls={false} indicators={false}>
          {questions.map((question, index) => {
            if (!question.skipped) {
              if (index === 0) {
                questionNumberAccumulator = 1;
              } else {
                questionNumberAccumulator += 1;
              }
            }
            return (
              <Carousel.Item key={question.uniqueID} className='p-4 px-5'>
                <QuestionDisplay
                  question={question}
                  questionNumber={questionNumberAccumulator}
                  handleQuestionNav={navigation ? handleQuestionNav : undefined}
                  handleQuestionAnswer={handleQuestionAnswer}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Row>
      {debugNavigation && (
        <Row className='justify-content-center mb-3'>
          <Col xs={2} className='my-auto'>
            <Row className='justify-content-end px-2'>
              <BackButton onClick={() => handleQuestionNav('PREVIOUS')} />
            </Row>
          </Col>
          <Col xs={2} className='my-auto'>
            <Row className='px-2'>
              <BackButton flip={true} onClick={() => handleQuestionNav('NEXT')} />
            </Row>
          </Col>
          <Col xs={12} className='mt-3'>
            <Row className='justify-content-center'>
              {questions.map((question, index) => {
                if (question.skipped) return undefined;

                if (index === 0) {
                  questionNumberAccumulator = 1;
                } else {
                  questionNumberAccumulator += 1;
                }

                return (
                  <h6
                    className={'px-2 hoverable-underline' + (question.answered ? ' text-success' : '')}
                    style={questionIndex === index ? { border: '1px solid var(--secondary)', borderRadius: 5 } : { border: '1px solid transparent' }}
                    onClick={() => handleQuestionNav(index)}
                    key={question.uniqueID}
                  >
                    {questionNumberAccumulator}
                  </h6>
                );
              })}
            </Row>
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default QuestionCarousel;
