import React from 'react';
import { Row } from 'reactstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import FormikTextField from '../../../components/common/Formik/FormikTextField';
import FormikSubmitButton from '../../../components/common/Formik/FormikSubmitButton';
import PhoneNumber from 'awesome-phonenumber';

export interface SignUpFormValues {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  lastName: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Required'),
  firstName: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Required'),
  phone: Yup.string()
    .test('is-us-phone', 'Invalid phone #', (value, _context) => {
      if (!value) {
        return false;
      }
      return new PhoneNumber(value, 'US').isValid();
    })
    .required('Required'),
});

interface SignUpFormProps {
  onSubmit: (arg0: SignUpFormValues) => void;
  email?: string; // Set default email value
  firstName?: string;
  lastName?: string;
  phone?: string;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ onSubmit, email = '', firstName = '', lastName = '', phone = '' }) => {
  return (
    <Formik
      initialValues={{ email, firstName, lastName, phone }}
      onSubmit={(values: SignUpFormValues, actions) => {
        onSubmit({
          ...values,
          phone: new PhoneNumber(values.phone, 'US').getNumber('international'),
        });
        actions.setSubmitting(false);
      }}
      validateOnChange={false}
      validationSchema={SignupSchema}
    >
      <FormikForm role='form' className='flex-row px-2'>
        <FormikTextField name='firstName' placeHolder='First name' icon='circle-08' />
        <FormikTextField name='lastName' placeHolder='Last name' icon='circle-08' />
        <FormikTextField name='email' placeHolder='Email' icon='email-83' readOnly />
        <FormikTextField id='phoneNumber' name='phone' placeHolder='Phone' icon='chat-round' />
        <Row className='justify-content-center mt-2'>
          <FormikSubmitButton id='createAccountBtn' label='Create Account' submittingMessage='Creating account' />
        </Row>
      </FormikForm>
    </Formik>
  );
};

export default SignUpForm;
