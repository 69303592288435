import React from 'react';
import CTABackground2 from '../../../assets/img/tempo-CTA-background-2.png';
import styles from './Section.module.scss';

export enum BackgroundType {
  CTABackground,
}

interface BackgroundProps {
  backgroundType: BackgroundType | undefined;
  children: any;
}

const BackgroundSwitcher: React.FC<BackgroundProps> = ({ backgroundType, children }) => {
  switch (backgroundType) {
    case BackgroundType.CTABackground:
      return (
        <>
          <img src={CTABackground2} className={styles.backgroundimg} alt='CTA background' />
          {children}
        </>
      );
    default:
      return children;
  }
};

interface SectionProps {
  children?: React.ReactNode;
  background?: BackgroundType;
  minHeight?: number | string; // Set a min height for the section
  height?: number | string; // Set a height for the section
  id?: string;
}

// Section wrapper with optional background with color/dots
const Section: React.FC<SectionProps> = ({ children, background, minHeight, height, id }) => {
  return (
    <section style={{ minHeight, height, position: 'relative' }} id={id} className='p-4'>
      <BackgroundSwitcher backgroundType={background}>{children}</BackgroundSwitcher>
    </section>
  );
};

export default Section;
