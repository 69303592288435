import type { ILandingPage } from '@swing-therapeutics/surveybay/dist/types';
import { DocumentData, DocumentSnapshot, firestore } from '../../utils/firebase';
import { RequestError } from '../RequestError';
import LandingPageCore from './LandingPageCore';

class LandingPage extends LandingPageCore implements ILandingPage {
  cohort: string;
  arm: string;
  site: string;
  study: string;
  role: string;
  eligibilityScreening: string[];
  disclaimerText: string;
  baselineSurveys: string[];
  tempoDownloadLink: string;
  postEligibilityProgram: string;
  postBaselineProgram: string;
  postTherapyProgram: string;
  maxUsersInConsent: number;
  stylingID: string | false;

  constructor(props: any) {
    super(props);
    // The parent class parses timestamp stuff, dont overwrite it
    const { created, updated, ...noTimeStampProps } = props;
    Object.assign(this, noTimeStampProps);
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData | undefined>): LandingPage {
    const data = docSnap.data();
    return new LandingPage(data);
  }

  static async fetchLandingPage(landingPageKey: string): Promise<LandingPage | RequestError> {
    let doc: DocumentSnapshot<DocumentData | undefined>;
    try {
      doc = await firestore.doc(`web/surveysaurus/landingPages/${landingPageKey}`).get();
    } catch (error) {
      return new RequestError(error, 'Landing page retrieval');
    }
    if (!doc?.exists) {
      return new RequestError(new Error(`Landing page ${landingPageKey} does not exist`), 'Landing page retrieval');
    }
    return LandingPage.fromFirestore(doc);
  }
}

export default LandingPage;
