import { UserFail } from '@swing-therapeutics/surveybay/dist/types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { DisclaimerMeta } from '../../../models/DisclaimerMeta';
import { RequestError } from '../../../models/RequestError';
import { User } from '../../../models/User';
import UserStateContext from '../../../utils/contexts/UserContext';

const Disclaimer: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState<string>();
  const { userState } = useContext(UserStateContext);
  const history = useHistory();
  const metaDataRef = useRef<DisclaimerMeta>();
  const isMounted = useRef(true);

  const checkIfDisclaimerAccepted = useCallback(
    async (user: User) => {
      const metaData = await DisclaimerMeta.fromUser(user);
      if (metaData instanceof RequestError) {
        return;
      }
      if (isMounted.current && !metaData.disclaimerAccepted) {
        setModalOpen(true);
        setModalText(metaData.disclaimerText);
        metaDataRef.current = metaData;
      }
    },
    [metaDataRef, isMounted],
  );

  useEffect(() => {
    isMounted.current = true;

    if (!userState.user || userState.user === 'FIRSTLOAD') return;
    checkIfDisclaimerAccepted(userState.user);

    return () => {
      isMounted.current = false;
    };
  }, [userState.user, checkIfDisclaimerAccepted]);

  const handleModalAccept = useCallback(() => {
    const metaData = metaDataRef.current;
    if (metaData) {
      metaData.disclaimerAccepted = true;
      metaData.persist();
    }
    setModalOpen(false);
  }, [metaDataRef]);

  const handleModalDeny = useCallback(() => {
    history.push({
      pathname: '/noteligible',
      state: {
        failReason: UserFail.DISCLAIM_NOTACCEPTED,
      },
    });
  }, [history]);

  return (
    <Modal
      className='modal-dialog-centered'
      isOpen={modalOpen}
      backdrop='static' // Dont allow backdrop click to close, must click accept to close
    >
      <div className='modal-header'>
        <h3 className='mb-0'>
          <small>Disclaimer</small>
        </h3>
      </div>
      <ModalBody>
        <p>{modalText}</p>
      </ModalBody>
      <ModalFooter className='justify-content-center'>
        <Button id='disclaimerYes' color='primary' type='button' onClick={handleModalAccept}>
          Yes
        </Button>
        <Button id='disclaimerNo' color='primary' type='button' onClick={handleModalDeny}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Disclaimer;
