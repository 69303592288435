import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Link, NavLink, NavLinkProps, useHistory } from 'react-router-dom';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
import styles from './AppNavBar.module.scss';
// reactstrap components
import { UncontrolledCollapse, NavbarBrand, Navbar, Nav, Container, Row, Col, Modal, ModalBody, Button } from 'reactstrap';
import UserStateContext from '../../../utils/contexts/UserContext';
import classNames from 'classnames';
import type { ILandingPageHeader } from '@swing-therapeutics/surveybay/dist/types';
import { ReactFMLogoDark } from '../../../assets/icons/svgicons';
import ContentContext from '../../../utils/contexts/ContentContext/ContentContext';

const defaultState = {
  collapseClasses: '',
  collapseOpen: false,
  confirmLogoutModalOpen: false,
};

interface AppNavBarProps {
  header?: ILandingPageHeader;
}

const AppNavBar: React.FC<AppNavBarProps> = ({ header }) => {
  const [state, setState] = useState({ ...defaultState });
  const { userState, userStateDispatch } = useContext(UserStateContext);
  const { buttonColor } = useContext(ContentContext);
  const history = useHistory();
  const referralGroup = useMemo(() => {
    // Referral group should always be third string in path
    return history.location.pathname.split('/')[3];
  }, [history.location.pathname]);
  const isValidStudyInParams = useMemo(() => {
    // Study should always be second string in path
    const study = history.location.pathname.split('/')[2];
    const is404 = history.location.pathname.split('/')[1] === '404';
    if (is404) return false;
    if (!study) return true;
    if (study === '003' || study === '005') return true;
    else return false;
  }, [history.location.pathname]);
  const welcomePageLink = `/welcome/${userState.landingPageKey}${referralGroup ? `/${referralGroup}` : ''}`;
  useEffect(() => {
    const header = document.getElementById('navbar-main')!;
    const headroom = new Headroom(header);
    headroom.init();
  }, []);

  const onExiting = () => {
    setState((prevState) => ({ ...prevState, collapseClasses: 'collapsing-out' }));
  };

  const onExited = () => {
    setState((prevState) => ({ ...prevState, collapseClasses: '' }));
  };

  //navbar logo
  const navBarLogoStyle = { height: '46px', width: '124px' };
  const navBarLogo = header?.logo ? (
    <img src={header.logo} style={navBarLogoStyle} />
  ) : isValidStudyInParams ? null : (
    <ReactFMLogoDark style={navBarLogoStyle} />
  );

  return (
    <header className='header-global'>
      <Navbar className='navbar-main navbar-transparent navbar-light headroom' expand='lg' id='navbar-main'>
        <Container>
          <NavbarBrand className='mr-lg-1' to={welcomePageLink} tag={Link}>
            {navBarLogo}
          </NavbarBrand>
          <button className='navbar-toggler' id='navbar_global'>
            <span className='navbar-toggler-icon' />
          </button>
          <UncontrolledCollapse
            toggler='#navbar_global'
            id='collapse'
            navbar
            className={state.collapseClasses}
            onExiting={onExiting}
            onExited={onExited}
          >
            <div className='navbar-collapse-header'>
              <Row>
                <Col className='collapse-brand' xs='8'>
                  <Row as={Link} to={welcomePageLink} className='align-items-center pl-3'>
                    {navBarLogo}
                  </Row>
                </Col>
                <Col className='collapse-close' xs='4'>
                  <button className='navbar-toggler' id='navbar_global'>
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className='navbar-nav-hover align-items-lg-center justify-content-lg-end' tag={Container} navbar>
              <CustomNavLink to={welcomePageLink} hash='#main' label='Welcome' />
              {!userState.inExtension && ( // Only show these links if not in extension and not in extension
                <>
                  <CustomNavLink to={welcomePageLink} hash='#about' label='About the Study' />
                  <CustomNavLink to={welcomePageLink} hash='#whyparticipate' label='Why Participate' />
                </>
              )}
              {!header?.hideProfile && userState.user && userState.user !== 'FIRSTLOAD' ? <CustomNavLink to='/profile' label='Your Profile' /> : ''}
              {header && !header?.hideFAQ && <CustomNavLink to={`/faq/${userState.landingPageKey}`} label='FAQs' />}
            </Nav>
            <Nav className='align-items-center justify-content-center navbar-nav-hover mt-3 mt-lg-0' navbar>
              <Button
                color='primary'
                id='loginButton'
                className={styles.loginbutton}
                style={{ backgroundColor: buttonColor, borderColor: buttonColor }}
                onClick={() => {
                  if (userState.user && userState.user !== 'FIRSTLOAD') {
                    setState((prevState) => ({ ...prevState, confirmLogoutModalOpen: true }));
                  } else {
                    history.push(`/signin/${userState.landingPageKey}${referralGroup ? `/${referralGroup}` : ''}`);
                  }
                }}
              >
                {userState.user && userState.user !== 'FIRSTLOAD' ? 'Log out' : 'Login'}
              </Button>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
      <ConfirmLogoutModal
        open={state.confirmLogoutModalOpen}
        onYesClick={() => {
          setState((prevState) => ({ ...prevState, confirmLogoutModalOpen: false }));
          userStateDispatch({ type: 'SIGNOUT' });
        }}
        onNoClick={() => setState((prevState) => ({ ...prevState, confirmLogoutModalOpen: false }))}
      />
    </header>
  );
};

export default AppNavBar;

interface ConfirmLogoutModalProps {
  open: boolean;
  onNoClick: () => void;
  onYesClick: () => void;
}

const ConfirmLogoutModal: React.FC<ConfirmLogoutModalProps> = ({ open, onNoClick, onYesClick }) => {
  return (
    <Modal className='modal-dialog-centered' isOpen={open} toggle={onNoClick}>
      <ModalBody>
        <Container>
          <Row className='justify-content-center mb-4'>
            <h3 className='text-center'>Are you sure you want to sign out?</h3>
          </Row>
          <Row className='justify-content-center'>
            <Button color='primary' className='mr-2' onClick={onYesClick}>
              Yes
            </Button>
            <Button color='primary' className='ml-2' onClick={onNoClick}>
              No
            </Button>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

interface CustomNavLinkProps extends NavLinkProps {
  label: string;
  hash?: string; // Hash to include on the link
}

const CustomNavLink: React.FC<CustomNavLinkProps> = ({ to, label, hash }) => {
  const link = hash ? to + hash : to;
  const history = useHistory();
  const closeRef = useRef<HTMLSpanElement>(null);

  return (
    <NavLink
      to={link}
      className={classNames(styles.deactivenavlink, 'mx-4 my-2')}
      isActive={() => {
        // #main is also active on the main link
        if (hash === '#main') {
          return history.location.pathname + history.location.hash === link || (history.location.pathname === to && history.location.hash === '');
        }
        return history.location.pathname + history.location.hash === link;
      }}
      onClick={() => {
        // Also close the modal if open when clicked, hash links dont close the nav modal because we stay on same page
        const el = document.getElementById('collapse');
        if (el?.className.includes('show')) {
          closeRef.current?.click();
        }
      }}
      activeClassName={styles.activenavlink}
    >
      {label}
      <span id='navbar_global' style={{ display: 'none' }} ref={closeRef} />
    </NavLink>
  );
};
