import React, { useCallback, useContext } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap';
import LoadingIcon from '../../components/common/LoadingIcon';
import Section from '../../components/common/Section';
import Layout from '../../components/Layout';
import { usePageLog } from '../../utils/analytics';
import UserStateContext from '../../utils/contexts/UserContext';
import ContentContext from '../../utils/contexts/ContentContext/ContentContext';
import SignInWith from './SignInWith';
import SignUpForm, { SignUpFormValues } from './SignUpForm';
import { UserStatus } from '@swing-therapeutics/surveybay/dist/types';
import { CreateAccountInfo } from '../../models/User';

interface PageParams {
  referralGroupKey?: string;
  landingPageKey: string;
}

const SignIn: React.FC<RouteComponentProps<PageParams>> = ({ match }) => {
  usePageLog({ pageTitle: 'Signin' });
  const { userState, userStateDispatch, loading, privateRelay, signInWithApple } = useContext(UserStateContext);
  const { appContentState } = useContext(ContentContext);

  const handleSignUpSubmit = useCallback(
    (signUpForm: SignUpFormValues) => {
      const payload: CreateAccountInfo = {
        ...signUpForm,
        referralGroupKey: match.params.referralGroupKey ?? '',
        landingPageKey: userState.landingPageKey,
      };
      userStateDispatch({ type: 'CREATEACCOUNT', payload });
    },
    [userStateDispatch, match, userState.landingPageKey],
  );

  if (!privateRelay) {
    return (
      <Layout navSpace={true}>
        <Section minHeight='75vh'>
          <Container>
            <Row className='justify-content-center mt-3'>
              <Card className='card-max-400 shadow mx-4'>
                <CardHeader>
                  <h3 className='mb-0'>
                    {userState.user && userState.user !== 'FIRSTLOAD' && userState.user.workflowStatus === UserStatus.CREATING
                      ? 'Create account'
                      : 'Sign in'}
                  </h3>
                </CardHeader>
                <CardBody className='px-0'>
                  <Container>
                    <p>
                      Thanks for your interest in the {appContentState.studyTitle} clinical study. To see if you qualify, please first log in using
                      your existing Google account. After you have logged in, you will be asked a series of questions to see if you qualify for the
                      study. You must be a US resident and have a gmail account to take part in {appContentState.studyTitle}. If you do not have one,
                      you can create one by clicking the Google button below.
                    </p>
                    {loading ? (
                      <LoadingIcon text={false} height={100} />
                    ) : userState.user && userState.user !== 'FIRSTLOAD' ? (
                      userState.user.workflowStatus !== UserStatus.CREATING ? (
                        <Redirect to='/profile' />
                      ) : (
                        <SignUpForm
                          onSubmit={handleSignUpSubmit}
                          email={userState.user.email}
                          firstName={userState.user.firstName}
                          lastName={userState.user.lastName}
                          phone={userState.user.phone}
                        />
                      )
                    ) : (
                      <SignInWith signInWithApple={signInWithApple} />
                    )}
                  </Container>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </Section>
      </Layout>
    );
  } else {
    return (
      <Layout navSpace={true}>
        <Section minHeight='75vh'>
          <Container>
            <Row className='justify-content-center mt-3'>
              <Card className='card-max-400 shadow mx-4'>
                <CardHeader>
                  <h3 className='mb-0'>
                    {userState.user && userState.user !== 'FIRSTLOAD' && userState.user.workflowStatus === UserStatus.CREATING
                      ? 'Create account'
                      : 'Sign in'}
                  </h3>
                </CardHeader>
                <CardBody className='px-0'>
                  <Container>
                    <p>You have hidden your email with your Apple ID to continue signing up, please unhide your email with Apple.</p>
                    <p>To unhide your email please perform the folling steps.</p>
                    <p>On your Apple device:</p>
                    <Container>
                      <ol className='list-group'>
                        <li className='list-item'>Open the Settings app</li>
                        <li className='list-item'>Tap the Apple ID button.</li>
                        <li className='list-item'>Tap Password and Security.</li>
                        <li className='list-item'>Tap Apps Using Apple ID.</li>
                        <li className='list-item'>Find SurveySaurus and tap to the next screen.</li>
                        <li className='list-item'>Tap Stop Using Apple Id.</li>
                        <li className='list-item'>Click logout and then log in again with Apple ID and click share my email when prompted. </li>
                      </ol>
                    </Container>
                  </Container>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </Section>
      </Layout>
    );
  }
};

export default SignIn;
