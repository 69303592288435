import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { Button } from 'reactstrap';

interface FormikSubmitButtonProps {
  label: string;
  disableOnSubmit?: boolean;
  submittingMessage?: string;
  id?: string;
}

const FormikSubmitButton: React.FC<FormikSubmitButtonProps> = ({ label, disableOnSubmit = true, submittingMessage = 'Submitting...', id }) => {
  const { isSubmitting } = useFormikContext();
  const disabled = isSubmitting && disableOnSubmit;
  // Wrap return in useMemo as formikContext state updates on every input change
  // But we only need to rerender the button when isSubmitting changes
  return useMemo(
    () => (
      <Button className='CTA-btn' type='submit' disabled={disabled} id={id}>
        {disabled ? submittingMessage : label}
      </Button>
    ),
    [disabled, label, submittingMessage, id],
  );
};

export default FormikSubmitButton;
