import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Container, Row } from 'reactstrap';
import Section from '../../components/common/Section';
import Layout from '../../components/Layout';
import { usePageLog } from '../../utils/analytics';

const Privacy: React.FC<RouteComponentProps> = () => {
  usePageLog({ pageTitle: 'Privacy Policy' });

  return (
    <Layout navSpace={true}>
      <Section minHeight='75vh'>
        <Container>
          <Row className='mt-3'>
            <h1>Swing Therapeutics Privacy Policy - US Only</h1>
          </Row>
          <Row>
            <p>Effective Date: July 2, 2021</p>
          </Row>
          <Row>
            <p>
              We are excited to share our digital therapeutics with you. We established core principles from our founding that are focused on
              transparency, protection and uses of personal information. As we build our products and services, these core principles drive our
              product and service requirements, processes and audit readiness. We want you to be aware of what information we do and do not collect,
              why we collect it, what we use it for, and how we store it. Most importantly, among our core principles at Swing Therapeutics is that we
              will use your information to improve your experience, advance research in the field of chronic diseases and to improve our product for
              future patients.
            </p>
          </Row>
          <Row>
            <p>This Privacy Policy applies to residents of the United States.</p>
          </Row>
          <Row>
            <p>
              This Privacy Policy explains how Swing Therapeutics handles personal information that we collect online, through our websites and
              applications and through our offline interactions with you (collectively, the “Swing Therapeutics Services”). This Privacy Policy does
              not apply to information collected from residents of countries other than the United States, or to third-party websites to which Swing
              Therapeutics may link.
            </p>
          </Row>
          <Row>
            <p>Please read this Privacy Policy before using the Swing Therapeutics Services or submitting personal information to us</p>
          </Row>
          <Row>
            <p>
              <b>
                <i>
                  On occasion, Swing Therapeutics may offer applications, special programs, activities, or events (“Programs”) that have unique or
                  additional specific terms, privacy notices and/or consent forms that explain how any information you provide will be processed in
                  connection with the Programs. You should review the applicable terms before interacting or participating in the Programs.
                </i>
              </b>
            </p>
          </Row>
          <Row>
            <h6>PERSONAL INFORMATION</h6>
          </Row>
          <Row>
            <p>
              “Personal information” is information that identifies you as an individual or relates to an identifiable individual. Examples of
              personal information include your name, your postal address and your telephone number.
            </p>
          </Row>
          <Row>
            <h6>HOW DOES SWING THERAPEUTICS COLLECT PERSONAL INFORMATION?</h6>
          </Row>
          <Row>
            <p>Swing Therapeutics collects information in a few different ways:</p>
          </Row>
          <Row>
            <ul>
              <li>
                <p>Information you submit or choose to provide</p>
              </li>
              <li>
                <p>Information you give us permission to collect from you or another source</p>
              </li>
              <li>
                <p>From Health Care Professionals</p>
              </li>
              <li>
                <p>Through our websites and mobile applications</p>
              </li>
              <li>
                <p>From contract research organizations, electronic data capture systems, and clinical trial investigators</p>
              </li>
              <li>
                <p>From your participation in Swing Therapeutics programs or clinical trials</p>
              </li>
              <li>
                <p>From third party service providers, data brokers or business partners</p>
              </li>
              <li>
                <p>From industry and patient groups and associations</p>
              </li>
              <li>
                <p>Automatically from devices you use to connect to Swing Therapeutics Services (See “Cookies and Other Tools” below)</p>
              </li>
              <li>
                <p>Information provided by partners of Swing Therapeutics</p>
              </li>
              <li>
                <p>From public sources including, but not limited to, social media and other websites that enable social sharing</p>
              </li>
              <li>
                <p>Information from government agencies, public or third-party information sources</p>
              </li>
            </ul>
          </Row>
          <Row>
            <p>
              From time to time, we may use or augment the personal information we have about you obtained from other sources, such as public
              databases, social media platforms and other third parties. We may also combine information we get from a third-party with information we
              already have, and we may combine information that we have collected offline with information we collect online.
            </p>
          </Row>
          <Row>
            <p>
              If you choose to submit any personal information relating to other people, you represent that you have the authority to do so and permit
              us to use the information in accordance with this Policy.
            </p>
          </Row>
          <Row>
            <h6>INFORMATION SWING THERAPEUTICS COLLECTS</h6>
          </Row>
          <Row>
            <p>Swing Therapeutics may collect the following information, depending on what product and service you use:</p>
          </Row>
          <Row>
            <ul>
              <li>
                <p>Contact information and preferences (such as name, email address, mailing address, and phone number</p>
              </li>
              <li>
                <p>Biographical and demographic information (such as date of birth, age, gender, ethnicity, marital status)</p>
              </li>
              <li>
                <p>
                  Health and medical information (such as medical insurance details, information about physical and mental health conditions and
                  diagnoses, treatments for medical conditions, genetic information, family medical history and medications, including the dosage,
                  timing, and frequency). This may be collected in connection with managing clinical trials, conducting research, providing patient
                  support programs, distributing and marketing our products, and tracking adverse event report
                </p>
              </li>
              <li>
                <p>Responses to in-application prompts and questions</p>
              </li>
              <li>
                <p>Payment-related information we need to complete a transaction (such as credit card number and financial account information)</p>
              </li>
              <li>
                <p>Username and password that you may select in connection with establishing an account on our websites or mobile apps</p>
              </li>
              <li>
                <p>Your digital or electronic signature</p>
              </li>
              <li>
                <p>Publicly available information</p>
              </li>
              <li>
                <p>Information on your interaction with the Swing Therapeutics Services and our advertisements</p>
              </li>
              <li>
                <p>Device information</p>
              </li>
              <li>
                <p>
                  Inferences drawn from other personal information listed above, to create a profile reflecting your preferences, characteristics,
                  psychological trends, predispositions, behavior, attitudes, intelligence, abilities and aptitudes
                </p>
              </li>
              <li>
                <p>Any adverse event information</p>
              </li>
              <li>
                <p>
                  Communications that we exchange with you, including when you contact us with questions or feedback, through social media, or
                  otherwise.
                </p>
              </li>
            </ul>
          </Row>
          <Row>
            <p>
              <b>If you are a Health Care Professional, we may also collect:</b>
            </p>
          </Row>
          <Row>
            <ul>
              <li>
                <p>
                  Professional credentials, educational and professional history, institutional and government affiliations, information included on a
                  resume or curriculum vitae, education and work history (such as work experience, education and languages spoken)
                </p>
              </li>
              <li>
                <p>Information about the Swing Therapeutics programs, products and activities with which you have engaged</p>
              </li>
              <li>
                <p>
                  Details about our interactions with you, your prescribing of our products and the agreements you have executed with us; including
                  remittance or other financial information
                </p>
              </li>
              <li>
                <p>Any information you choose to provide about yourself or your household</p>
              </li>
              <li>
                <p>Information collected in connection with Swing Therapeutics hosted events or activities you attend or participate in</p>
              </li>
              <li>
                <p>
                  Publicly-available information related to your practice, such as license information, disciplinary history, prior litigation and
                  regulatory proceedings, and other due diligence related information
                </p>
              </li>
            </ul>
          </Row>
          <Row>
            <p>
              We may use the personal information we collect about you with information obtained from other sources, such as public databases, social
              media platforms and other third parties. For example, we may use public information to confirm contact or financial information, to
              verify licensure of Health Care Professionals or to better understand your interests by associating demographic information with the
              information you have provided.
            </p>
          </Row>
          <Row>
            <h6>HOW DOES SWING THERAPEUTICS USE PERSONAL INFORMATION?</h6>
          </Row>
          <Row>
            <p>
              How Swing Therapeutics uses your information depends on how you interact with the Swing Therapeutics Services. Generally, Swing
              Therapeutics may use your information to:
            </p>
          </Row>
          <Row>
            <ul>
              <li>
                <p>Provide our products and services to you</p>
              </li>
              <li>
                <p>Improve our products and services</p>
              </li>
              <li>
                <p>Develop new products and services</p>
              </li>
              <li>
                <p>Fulfill your requests</p>
              </li>
              <li>
                <p>Provide customer service</p>
              </li>
              <li>
                <p>Send you administrative communications regarding Swing Therapeutics’ products or Swing Therapeutics Services</p>
              </li>
              <li>
                <p>Notify you about special promotions or offers that may be of interest to you</p>
              </li>
              <li>
                <p>Invite you to participate in surveys about our products or services</p>
              </li>
              <li>
                <p>Better understand the interests of our customers and visitors who use Swing Therapeutics Services</p>
              </li>
              <li>
                <p>Maintain Swing Therapeutics’ online services such as websites and applications</p>
              </li>
              <li>
                <p>Monitor, detect and prevent fraud, improper activity and ensure Swing Therapeutics’ policy compliance</p>
              </li>
              <li>
                <p>Conduct research including secondary and follow-on research</p>
              </li>
              <li>
                <p>Determine an individual’s eligibility to participate in Swing Therapeutics programs or clinical trials</p>
              </li>
              <li>
                <p>Process payments</p>
              </li>
              <li>
                <p>Evaluate how you and other users interact with our online services</p>
              </li>
              <li>
                <p>Protect Swing Therapeutics and our users</p>
              </li>
              <li>
                <p>Secure our online services including our network, websites and applications</p>
              </li>
              <li>
                <p>Conduct audits related to our current interactions with you</p>
              </li>
              <li>
                <p>Debug the site and service to identify and repair errors that impair existing intended functionality</p>
              </li>
              <li>
                <p>Defend ourselves in litigation and investigations and to prosecute litigations</p>
              </li>
              <li>
                <p>Comply with our legal and regulatory obligations</p>
              </li>
            </ul>
          </Row>
          <Row>
            <p>Swing Therapeutics may also use information collected from you or about you to offer you customized content, including to</p>
          </Row>
          <Row>
            <ul>
              <li>
                <p>Recognize new or past visitors to our websites or applications</p>
              </li>
              <li>
                <p>Remember your personal preferences</p>
              </li>
              <li>
                <p>Show you more relevant and meaningful advertisements based on your interests</p>
              </li>
            </ul>
          </Row>
          <Row>
            <p>Swing Therapeutics may also use your information for other purposes disclosed to you from time to time.</p>
          </Row>
          <Row>
            <p>
              We may use personal information collected from any of the sources listed in the “How Does Swing Therapeutics Collect Personal
              Information?” section of this policy for our internal business purposes, such as data analysis, audits, developing new products,
              enhancing our websites and apps, improving our Swing Therapeutics Services, identifying usage trends and determining the effectiveness
              of our promotional campaigns.
            </p>
          </Row>
          <Row>
            <p>
              We may aggregate and/or de-identify data about customers and site visitors and use it for any purpose, including product and service
              development and improvement activities.
            </p>
          </Row>
          <Row>
            <h6>DOES SWING THERAPEUTICS SHARE PERSONAL INFORMATION?</h6>
          </Row>
          <Row>
            <p>
              We do not sell your personal information. However, we may share personal information with our vendors, contractors, business and service
              partners, or other third parties (collectively, “Service Providers”). Examples of Service Providers include analysis firms, advertisers,
              payment processing companies, customer service and support providers, email, IT services and SMS vendors, web hosting and development
              companies and fulfillment companies. Some Service Providers may collect personal data on behalf of Swing Therapeutics. These third
              parties may be provided with access to personal data needed to perform their functions. In addition, Swing Therapeutics’ practice is to
              require its Service Providers to keep your personal information confidential and to use personal information only to perform functions
              for Swing Therapeutics.
            </p>
          </Row>
          <Row>
            <p>
              Swing Therapeutics may also disclose your personal information as required by law to any competent law enforcement body, regulatory or
              government agency, court or other third party where we believe the disclosure is necessary or appropriate to comply with a regulatory
              requirement, judicial proceeding, court order, government request or legal process served on us, or to protect the safety, rights, or
              property of our customers, the public, Swing Therapeutics or others, and to exercise, establish or defend Swing Therapeutics’ legal
              rights.
            </p>
          </Row>
          <Row>
            <p>
              Swing Therapeutics may also disclose aggregate or de-identified data that is not personally identifiable to third parties for any
              purpose.
            </p>
          </Row>
          <Row>
            <p>
              <b>Cookies and Other Tools</b>
            </p>
          </Row>
          <Row>
            <p>
              Swing Therapeutics and its Service Providers collect information about you by using cookies, tracking pixels, web beacons and other
              technologies (collectively, “Tools”). We use this information to better understand, customize and improve user experience with our
              websites, services and offerings as well as to manage our advertising. This information can make your use of our Swing Therapeutics
              Services easier and more meaningful by allowing Swing Therapeutics and our Service Providers to provide better service, customize sites
              based on consumer preferences, compile statistics, provide you with more relevant advertisements based on your interests, analyze trends
              and otherwise administer and improve our Swing Therapeutics Services. If we have collected your personal information, we may associate
              this personal data with information gathered through the Tools.
            </p>
          </Row>
          <Row>
            <p>
              Your web browser can be set to allow you to control whether you will accept cookies, reject cookies, or to notify you each time a cookie
              is sent to your browser. If your browser is set to reject cookies, websites that are cookie-enabled will not recognize you when you
              return to the website, and some website functionality may be lost. The Help section of your browser may tell you how to prevent your
              browser from accepting cookies. To find out more about cookies, visit{' '}
              <a rel='noreferrer' href='https://www.aboutcookies.org' target='_blank'>
                www.aboutcookies.org
              </a>
              .
            </p>
          </Row>
          <Row>
            <p>
              You can also control Flash cookies which we may use on certain Swing Therapeutics websites and apps from time to time by clicking{' '}
              <a rel='noreferrer' href='http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html' target='_blank'>
                here
              </a>
              . Why? Because Flash cookies cannot be controlled through your browser settings. Choices you make are both browser and device-specific.
            </p>
          </Row>
          <Row>
            <p>Here are some of the types of information collected when visiting Swing Therapeutics Websites:</p>
          </Row>
          <Row>
            <p>
              <b>
                <i>Log data:</i>
              </b>{' '}
              When you use Swing Therapeutics websites and applications, our servers automatically record information (“log data”), including
              information that your browser sends whenever you visit a website or app or your mobile app sends when you are using it. This log data
              may include your Internet Protocol address, the address of the webpages you visited that had Swing Therapeutics features, browser type
              and settings, the date and time of your request, how you used websites and cookie data.
            </p>
          </Row>
          <Row>
            <p>
              <b>
                <i>Cookie data:</i>
              </b>{' '}
              Depending on how you are accessing Swing Therapeutics’ online Services, we and our Service Providers may use “cookies” (a small text
              file sent by your computer each time you visit our sites, unique to your Swing Therapeutics account or your browser) or similar
              technologies, to record log data. When we use cookies, we may use “session” cookies (that last until you close your browser) or
              “persistent” cookies (that last until you and/or your browser delete them). For example, we may use cookies to remember your personal
              preferences, such as sections of the website that you visit frequently, your user ID, or other settings so you do not have to set them
              up every time you visit, and we may use cookies to serve you relevant advertising tailored to your interests. Some of the cookies we use
              are associated with your Swing Therapeutics account (including personal information about you, such as the email address you gave us),
              and other cookies are not.
            </p>
          </Row>
          <Row>
            <p>
              <b>
                <i>Social media plugins and data:</i>
              </b>{' '}
              In addition, our websites and applications may use social media plugins (e.g., the Facebook "Like" button, "Share to Twitter" button) to
              enable you to easily share information with others. When you visit any of our online Swing Therapeutics Services, the operator of the
              social plugin can place a cookie on your computer, enabling that operator to recognize individuals who have previously visited our
              websites. If you are logged into the social media website (e.g., Facebook, Twitter, Google+) while browsing on our Swing Therapeutics
              Services, the social plugins allow that social media website to receive information that you have visited our Swing Therapeutics
              Services. The social plugins also allow the social media website to share information about your activities on Swing Therapeutics with
              other users of their social media website. Swing Therapeutics does not control any of the content from the social plugins. For more
              information about social plugins from other social media websites you should refer to those sites' privacy and data sharing statements.
            </p>
          </Row>
          <Row>
            <p>
              If you choose to link your social media or Google account to Swing Therapeutics, this allows us to obtain information from those
              accounts (such as your friends or contacts). The information we obtain from these services often depends on your settings or the
              applicable provider's privacy policies, so please check these policies before granting us access. You agree that we are not responsible
              for any acts or omissions by these social media service providers. If you give us permission to access this information, we will use it
              for the purposes described in this Privacy Policy.
            </p>
          </Row>
          <Row>
            <p>
              <b>
                <i>Device information:</i>
              </b>{' '}
              In addition to log data, we may also collect information about the device you are using to access our Swing Therapeutics Services,
              including what type of device it is, what operating system you are using, device settings, unique device identifiers and crash data.
              Whether we collect some or all of this information often depends on what type of device you are using and its settings. For example,
              different types of information are available depending on whether you are using a Mac or a PC, or an iPhone or an Android phone. To
              learn more about what information your device may make available to us, please also check the policies of your device manufacturer or
              software provider.
            </p>
          </Row>
          <Row>
            <p>
              <b>
                <i>Swing Therapeutics Do Not Track Policy:</i>
              </b>{' '}
              Some browsers have Do Not Track (“DNT”) features that allow you to tell a website not to track you. These features are not all uniform,
              and some browsers may not be set up to honor such requests. Swing Therapeutics honors the AdChoice DNT signals. Options you select are
              browser and device specific. Please be aware that limiting the ability of websites to set cookies, however, may worsen your overall user
              experience, and in some cases the sites will not work properly without cookies, local storage and similar technologies.
            </p>
          </Row>
          <Row>
            <h6>WHAT CHOICES DO I HAVE ABOUT HOW SWING THERAPEUTICS COLLECTS AND USES INFORMATION ABOUT ME?</h6>
          </Row>
          <Row>
            <p>
              Certain Swing Therapeutics Services may ask whether you wish to opt-out or opt into our contact lists for offers, promotions and
              additional services that may be of interest to you.
            </p>
          </Row>
          <Row>
            <p>
              <b>Your Privacy Choices</b>
            </p>
          </Row>
          <Row>
            <p>
              You may have certain rights and choices regarding our processing of your personal data. Depending on your jurisdiction, applicable law
              may entitle you to additional consumer rights, including the right (with certain limitations and exceptions) to:
            </p>
          </Row>
          <Row>
            <ul>
              <li>
                <p>
                  Know the categories and/or specific pieces of personal information collected about you, including whether your personal information
                  is sold or disclosed, and with whom your personal information was share
                </p>
              </li>
              <li>
                <p>Access a copy of the personal information we retain about you</p>
              </li>
              <li>
                <p>Request deletion of your personal information</p>
              </li>
            </ul>
          </Row>
          <Row>
            <p>
              We reserve the right to verify your identity in connection with any requests regarding personal information to help ensure that we
              provide the information we maintain to the individuals to whom it pertains, and allow only those individuals or their authorized
              representatives to exercise rights with respect to that information. If you are an authorized agent making a request on behalf of a
              consumer, we may require and request additional information to verify that you are authorized to make that request.
            </p>
          </Row>
          <Row>
            <p>
              We reserve the right to deny your request if we cannot verify your identity. Where we deny your request in whole or in part, we will
              endeavor to inform you of the denial and provide an explanation of our actions and the reasons for the denial.
            </p>
          </Row>
          <Row>
            <p>
              We will not restrict or deny you access to our Swing Therapeutics Services because of choices and requests you make in connection with
              your personal information. Please note, certain choices may affect our ability to deliver the Swing Therapeutics Services. For example,
              if you sign up to receive marketing communications by email, then ask Swing Therapeutics to delete all of your information, we will be
              unable to send you marketing communications.
            </p>
          </Row>
          <Row>
            <p>
              You may exercise any of your rights in relation to your personal information by contacting us using the details provided under the “How
              Can I Contact Swing Therapeutics” section below.
            </p>
          </Row>
          <Row>
            <h6>HOW DOES SWING THERAPEUTICS SECURE INFORMATION?</h6>
          </Row>
          <Row>
            <p>
              It is Swing Therapeutics’ practice to take steps to secure our Swing Therapeutics Services; however, the confidentiality of information
              transmitted over the Internet cannot be guaranteed. We urge you to exercise caution when transmitting personal information over the
              Internet, especially personal information related to your health. Swing Therapeutics cannot guarantee that unauthorized third parties
              will not gain access to your information; therefore, when submitting personal information to Swing Therapeutics websites or apps, you
              must weigh both the benefits and the risks. Swing Therapeutics websites or apps may link to third-party websites that are not controlled
              by Swing Therapeutics or subject to an Swing Therapeutics privacy policy. You should check the privacy policies of such third-party
              websites before submitting personal information.
            </p>
          </Row>
          <Row>
            <h6>THIS WEBSITE IS NOT FOR CHILDREN</h6>
          </Row>
          <Row>
            <p>
              Swing Therapeutics does not knowingly collect or use any information from children (we define “children” as minors younger than 13). We
              do not knowingly allow children to order our products, communicate with us, or use any of our online services. If you are a parent and
              become aware that your child has provided us with information, please contact us using one of the methods specified below and we will
              work with you to address this issue
            </p>
          </Row>
          <Row>
            <h6>THIRD PARTY SITES</h6>
          </Row>
          <Row>
            <p>
              We may provide links to websites and other third-party content that is not owned or operated by Swing Therapeutics. The websites and
              third-party content to which we link may have separate privacy notices or policies. Swing Therapeutics is not responsible for the
              privacy practices of any entity that it does not own or control.
            </p>
          </Row>
          <Row>
            <p>
              If you provide any personal information through a third-party site, your interaction and your personal information will be collected by,
              and controlled by the privacy policy of, that third party site. We recommend that you familiarize yourself with the privacy policies and
              practices of any such third parties, which are not governed by this privacy policy.
            </p>
          </Row>
          <Row>
            <h6>CROSS BORDER TRANSFERS</h6>
          </Row>
          <Row>
            <p>
              Any information you provide to Swing Therapeutics may be stored and processed, transferred between and accessed from the United States,
              where Swing Therapeutics is headquartered, and other countries which may not guarantee the same level of protection of personal data as
              the one in which you reside. However, Swing Therapeutics will handle your personal data in accordance with this privacy policy
              regardless of where your personal data is stored/accessed.
            </p>
          </Row>
          <Row>
            <h6>HOW CAN I CONTACT SWING THERAPEUTICS?</h6>
          </Row>
          <Row>
            <p>
              If you have questions about your information, please contact us by clicking on the “Contact Us” link on the website you are visiting or
              by e-mailing us at <a href='mailto:privacy@swingtherapeutics.com'>privacy@swingtherapeutics.com</a>. Alternatively, you may send a
              letter to the following address:
            </p>
          </Row>
          <Row>
            <p>
              Swing Therapeutics
              <br />
              353 Sacramento St, Suite 1803
              <br />
              San Francisco, CA, 94111
              <br />
            </p>
          </Row>
          <Row>
            <p>
              In all communications to Swing Therapeutics, please include the email address used for registration (if applicable), the website address
              or the specific Swing Therapeutics program to which you provided personal information (e.g. Swing Theraputics.com, etc.) and a detailed
              explanation of your request. We will do our best to respond to all reasonable requests in a timely manner.
            </p>
          </Row>
          <Row>
            <h6>CHANGES TO THIS PRIVACY POLICY</h6>
          </Row>
          <Row>
            <p>
              We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you
              by updating the date of this Privacy Policy and posting it on the Services. If required by law we will also provide notification of
              changes in another way that we believe is reasonably likely to reach you, such as via email or another manner through the Services. Any
              modifications to this Privacy Policy will be effective upon our posting the modified version (or as otherwise indicated at the time of
              posting). In all cases, your use of the Services after the effective date of any modified Privacy Policy indicates your acceptance of
              the modified Privacy Policy.
            </p>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default Privacy;
