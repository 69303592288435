import moment from 'moment';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { ClockIcon, CompleteIcon, ZoomIcon } from '../../../assets/icons/svgicons';

interface Props {
  email: string;
  callInfo: {
    callStartTime?: Date | null;
    callEndTime?: Date | null;
    locationInfo?: any | null;
    cancelURL?: string | null;
    rescheduleURL?: string | null;
  };
}

const ScheduledCallInfo: React.FC<Props> = ({ email, callInfo }) => {
  return (
    <>
      <Row className='align-items-center mb-4'>
        <CompleteIcon height='23px' width='23px' />
        <p className='mb-0 ml-2'>Your call has been scheduled.</p>
      </Row>
      <Row>
        <Col>
          {callInfo.callEndTime && callInfo.callStartTime ? (
            <>
              <Row>
                <ClockIcon height='23px' width='23px' />
                <p className='mb-1 ml-2'>Time of call:</p>
              </Row>
              <Row>
                <p>
                  {moment(callInfo.callStartTime).format('h:mm')} - {moment(callInfo.callEndTime).format('h:mm a, dddd, MMMM DD, YYYY')}
                </p>
              </Row>
              {callInfo.cancelURL && callInfo.rescheduleURL && (
                <Row className='mb-4'>
                  <Col xs='auto' className='pl-0'>
                    <a href={callInfo.rescheduleURL} target='_blank' rel='noreferrer'>
                      <Button color='primary'>Reschedule</Button>
                    </a>
                  </Col>
                  <Col xs='auto'>
                    <a href={callInfo.cancelURL} target='_blank' rel='noreferrer'>
                      <Button color='primary'>Cancel</Button>
                    </a>
                  </Col>
                </Row>
              )}
              {callInfo.locationInfo?.join_url && (
                <>
                  <Row>
                    <ZoomIcon height='23px' width='23px' />
                    <p className='mb-1 ml-2'>Zoom call link:</p>
                  </Row>
                  <Row>
                    <p className='mb-1'>
                      <a href={callInfo.locationInfo.join_url} target='_blank' rel='noreferrer'>
                        {callInfo.locationInfo.join_url}
                      </a>
                    </p>
                  </Row>
                  <Row>
                    <p>
                      <a href='https://support.zoom.us/hc/en-us/categories/200101697' target='_blank' rel='noreferrer'>
                        How to use Zoom?
                      </a>
                    </p>
                  </Row>
                </>
              )}
            </>
          ) : (
            <Row>
              <p>Check you email ({email}) for the date, time and location.</p>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ScheduledCallInfo;
