import { SurveyStatus, WorkflowID, WorkflowStatusMap, WorkflowType } from '@swing-therapeutics/surveybay/dist/types';
import { DocumentData, DocumentSnapshot, firestore } from '../../utils/firebase';
import { RequestError } from '../RequestError';
import { User } from '../User';
import { SurveyMetaAbstract } from './SurveyMetaAbstract';

const findTerminationSurveys = (userUID: string, workflowType: WorkflowType) => {
  let workflowID = WorkflowID.EARLY_TERM_SURVEYS;
  if (workflowType === WorkflowType.EXTENSION) {
    workflowID = WorkflowID.EXT_EARLY_TERM_SURVEYS;
  }
  return firestore.collection(`users/${userUID}/workflows`).where('workflowID', '==', workflowID);
};

export class TerminationMeta extends SurveyMetaAbstract {
  public surveysTerminal: boolean;

  constructor(props: any, docPath: string) {
    super(props, docPath);
    this.surveysTerminal = this.surveys.every((survey) =>
      [SurveyStatus.EXPIRED, SurveyStatus.EXPIRED_IN_PROGRESS, SurveyStatus.COMPLETED].includes(survey.status),
    );
  }

  getFirestoreData() {
    return {
      terminationSurveys: this.surveys,
    };
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData>): TerminationMeta {
    const docData = docSnap.data();

    return new TerminationMeta({ ...docData }, docSnap.ref.path);
  }

  static async fromUser(user: User): Promise<TerminationMeta | RequestError> {
    let doc: DocumentSnapshot<DocumentData>;
    try {
      const collection = await findTerminationSurveys(user.uid, WorkflowStatusMap[user.workflowStatus]).get();
      doc = collection.docs[0];
    } catch (error) {
      return new RequestError(error, 'Termination meta doc retrieval');
    }
    if (!doc?.exists) {
      return new RequestError(new Error('Termination doc doesnt exist for user'), 'Eligibility doc retrieval');
    } else {
      return this.fromFirestore(doc);
    }
  }

  static async fromUserSubscribe(user: User, changeCallback: (terminationMeta: TerminationMeta) => void): Promise<() => void> {
    try {
      const collection = await findTerminationSurveys(user.uid, WorkflowStatusMap[user.workflowStatus]).get();
      const doc = collection.docs[0];
      return doc.ref.onSnapshot((snap) => {
        if (!snap.exists) {
          return;
        }
        changeCallback(new TerminationMeta(snap.data(), snap.ref.path));
      });
    } catch (error) {
      console.log(error);
      return () => {};
    }
  }
}
