import { DocumentData, DocumentSnapshot, firestore } from '../../utils/firebase';
import { RequestError } from '../RequestError';
import { User } from '../User';
import { SurveyInfo, SurveyStatus } from '@swing-therapeutics/surveybay/dist/types';

export class SurveyMetaAbstract {
  surveys: SurveyInfo[];
  docPath: string;

  constructor(props: any, docPath: string) {
    if (this.constructor === SurveyMetaAbstract) {
      throw new Error("Abstract class can't be contructed");
    }
    // The field that contains the surveys is different based on the document, only 1 will be defined
    this.surveys = props.eligibilitySurveys || props.baselineSurveys || props.terminationSurveys;
    this.docPath = docPath;
  }

  getFirestoreData(): {} {
    throw new Error('Method getFirestoreData() must be implemented');
  }

  complete(surveyBayKey: string) {
    const surveyIndex = this.surveys.findIndex((i) => i.surveyBayKey === surveyBayKey);
    if (surveyIndex === -1) {
      console.error(`Could not find survey with surveyBayKey ${surveyBayKey} when setting status to complete`);
      return;
    }
    this.surveys[surveyIndex].status = SurveyStatus.COMPLETED;
    this.surveys[surveyIndex].updated = new Date();
  }

  start(surveyBayKey: string) {
    const surveyIndex = this.surveys.findIndex((i) => i.surveyBayKey === surveyBayKey);
    if (surveyIndex === -1) {
      console.error(`Could not find survey with surveyBayKey ${surveyBayKey} when setting status to start`);
      return;
    }
    this.surveys[surveyIndex].status = SurveyStatus.IN_PROGRESS;
    this.surveys[surveyIndex].updated = new Date();
  }

  async persist(): Promise<'Success' | RequestError> {
    try {
      await firestore.doc(this.docPath).set(this.getFirestoreData(), { merge: true });
    } catch (error) {
      return new RequestError(error, 'Survey meta doc persist');
    }
    return 'Success';
  }

  static fromFirestore(_docSnap: DocumentSnapshot<DocumentData>): any {
    throw new Error('Method fromFirestore() must be implemented');
  }

  static async fromUser(_user: User): Promise<any> {
    throw new Error('Method fromUser() must be implemented');
  }

  static async fromUserSubscribe(_user: User, _changeCallback: (metaClass: any) => void): Promise<() => void> {
    throw new Error('Method fromUserSubscribe() must be implemented');
  }
}
