import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import Layout from '../../components/Layout';
import Section from '../../components/common/Section';
import CalendlyCallCalendar from '../../components/common/CalendlyCallCalendar';
import UserStateContext from '../../utils/contexts/UserContext';
import LoadingIcon from '../../components/common/LoadingIcon';
import { User } from '../../models/User';
import isMobileDevice from '../../utils/functions/isMobileDevice';
import DownloadTempoButton from '../../components/common/DownloadTempoButton';
import { usePageLog } from '../../utils/analytics';
import { IScheduleCall, ScheduledCallStatus, WorkflowStatusMap, WorkflowType } from '@swing-therapeutics/surveybay/dist/types';
import ScheduledCallInfo from '../../components/common/ScheduledCallInfo';
import { ConsentMeta } from '../../models/calls/ConsentMeta';

interface PageParams {}

// Consent page is slightly different from a regular schedule call so it has its own page component
// Vs. just using the ScheduleACall page component
const Consent: React.FC<RouteComponentProps<PageParams>> = () => {
  usePageLog({ pageTitle: 'Consent' });
  const { userState } = useContext(UserStateContext);
  const [callState, setCallState] = useState<IScheduleCall | 'loading' | null>('loading');
  const consentMeta = useRef<ConsentMeta>();
  const unSubConsentMeta = useRef<() => void>();
  const workflowType = useMemo(() => {
    if (userState.user && userState.user !== 'FIRSTLOAD') {
      return WorkflowStatusMap[userState.user.workflowStatus];
    }
    return null;
  }, [userState.user]);

  const loadConsentMeta = useCallback(async (user: User) => {
    unSubConsentMeta.current = await ConsentMeta.fromUserSubscribe(user, (_consentMeta) => {
      consentMeta.current = _consentMeta;
      setCallState({ ..._consentMeta });
    });
  }, []);

  useEffect(() => {
    if (userState.user && userState.user !== 'FIRSTLOAD') {
      loadConsentMeta(userState.user);
    }
    return () => {
      unSubConsentMeta.current?.();
    };
  }, [userState.user, loadConsentMeta]);

  const onEventScheduled = useCallback((eventURI: string, inviteeURI: string) => {
    consentMeta.current?.callScheduled(eventURI, inviteeURI);
    consentMeta.current?.persist();
  }, []);

  return (
    <Layout navSpace={true}>
      <Section minHeight='75vh'>
        <Container className='mt-3'>
          <Row className='justify-content-center'>
            {callState === 'loading' || callState === null || !userState.user || userState.user === 'FIRSTLOAD' ? (
              <LoadingIcon text='Loading call...' />
            ) : (
              <Col xs={12} md={7}>
                <Row className='mb-3'>
                  <h2 className='mb-0'>{callState.title}</h2>
                </Row>
                {workflowType === WorkflowType.STANDARD && (
                  // Extra text for the standard portion of the trial
                  <>
                    <Row>
                      <h5 className='text-dark text-default'>1. Download the Tempo app to participate in the study.</h5>
                    </Row>
                    {isMobileDevice() ? (
                      <Row className='mt-3 justify-content-center'>
                        <DownloadTempoButton />
                      </Row>
                    ) : (
                      <Row className='mt-3'>
                        <p>Check your email ({userState.user.email}) on your mobile device to download Tempo.</p>
                      </Row>
                    )}
                  </>
                )}
                {callState.status !== ScheduledCallStatus.CALL_SCHEDULED ? (
                  <>
                    <Row className='mt-4'>
                      <h5 className='text-dark text-default'>{callState.description}</h5>
                    </Row>
                    <Row className='mt-2 justify-content-center'>
                      <CalendlyCallCalendar
                        name={userState.user.displayName}
                        email={userState.user.email}
                        apiURL={callState.calendlyCallURL}
                        onEventScheduled={onEventScheduled}
                      />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className='mt-4'>
                      <h5 className='text-dark text-default'>
                        {/*Two steps in the standard workflow */}
                        {workflowType === WorkflowType.STANDARD && '2.'} Participate in your call with a Swing study staff.
                      </h5>
                    </Row>
                    <ScheduledCallInfo email={userState.user.email} callInfo={callState} />
                    <Row className='mt-3 justify-content-center'>
                      <Link to='/profile'>
                        <Button className='btn-icon btn-3 btn-lg CTA-btn'>
                          <span className='btn-inner--text'>Back to profile page</span>
                          <span className='btn-inner--icon mr-1'>
                            <i className='ni ni-curved-next' />
                          </span>
                        </Button>
                      </Link>
                    </Row>
                  </>
                )}
              </Col>
            )}
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default Consent;
