import React from 'react';
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field, ErrorMessage } from 'formik';

interface TextFieldProps {
  name: string;
  placeHolder?: string;
  icon?: string;
  autoComplete?: string;
  id?: string;
  readOnly?: boolean;
}

const ErrorMessageRender = (msg: string) => <p className='mt-1 text-warning mb-0'>{msg}</p>;

const FormikTextField: React.FC<TextFieldProps> = ({ name, placeHolder, icon, autoComplete = 'off', id, readOnly = false }) => {
  return (
    <FormGroup className='mb-4'>
      <InputGroup className='input-group-alternative'>
        {icon && (
          <InputGroupAddon addonType='prepend'>
            <InputGroupText>
              <i className={`ni ni-${icon}`} />
            </InputGroupText>
          </InputGroupAddon>
        )}
        <Field autoComplete={autoComplete} className='form-control text-dark' placeholder={placeHolder} name={name} id={id} readOnly={readOnly} />
      </InputGroup>
      <ErrorMessage name={name} render={ErrorMessageRender} />
    </FormGroup>
  );
};

export default FormikTextField;
