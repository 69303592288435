import { DocumentData, DocumentSnapshot, firebase, firestore, Timestamp } from '../utils/firebase';
import { RequestError } from './RequestError';
import { UserStatus } from '@swing-therapeutics/surveybay/dist/types';
import LandingPage from './landing/LandingPage';

interface UserProperties {
  cohort: string;
  role: string;
}

interface UserRef {
  uid: string;
  email: string;
  activeProgram: string;
  displayName: string;
  subjectID: string;
  userProperties: UserProperties;
  lastOpenedTime: Timestamp;
  appVersion: string;
  deviceName: string;
  deviceOS: string;
  deviceOSVersion: string;
  FCMToken: string;
  FCMTokenUpdated: Timestamp;
  zendeskID: string;
  site: string;
  cohort: string;
  role: string;
  arm: string;
  study: string;
  firstName: string;
  lastName: string;
  registered: string;
  landingPageKey: string;
  workflowStatus: UserStatus;
  referralGroupKey: string;
  phone: string;
}

interface UserConstructorProps extends Omit<User, 'getFirestoreData'> {}

export interface CreateAccountInfo {
  referralGroupKey: string;
  email: string;
  firstName: string;
  lastName: string;
  landingPageKey: string;
  phone: string;
}

export class User {
  uid: string;
  email: string;
  displayName: string;
  firstName: string;
  lastName: string;
  landingPageKey: string;
  workflowStatus: UserStatus;
  referralGroupKey: string;
  phone: string;
  role: string;

  constructor(props: UserConstructorProps) {
    this.uid = props.uid;
    this.email = props.email;
    this.displayName = props.displayName;
    this.firstName = props.firstName;
    this.lastName = props.lastName;
    this.landingPageKey = props.landingPageKey ?? '';
    this.workflowStatus = props.workflowStatus ?? '';
    this.referralGroupKey = props.referralGroupKey ?? '';
    this.phone = props.phone ?? '';
    this.role = props.role ?? '';
    this.uid = props.uid;
  }

  getFirestoreData() {
    // Return data that should be in firestore
    return {
      uid: this.uid,
      email: this.email,
      displayName: this.displayName,
      firstName: this.firstName,
      lastName: this.lastName,
      workflowStatus: this.workflowStatus,
      referralGroupKey: this.referralGroupKey,
    };
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData>) {
    const docData = docSnap.data() as UserRef;

    return new User({ ...docData });
  }

  static fromUserAuth(userAuth: firebase.User) {
    const firstLast = userAuth.displayName?.split(' ');
    const addedInfo = {
      email: userAuth.email!,
      displayName: userAuth.displayName ?? '',
      firstName: firstLast ? firstLast[0] : '',
      lastName: firstLast ? firstLast[1] : '',
      landingPageKey: '', // Will be assigned when an account is created for user
      workflowStatus: UserStatus.CREATING,
      referralGroupKey: '',
      phone: userAuth.phoneNumber ?? '',
      role: '',
    };
    return new User({ ...userAuth, ...addedInfo });
  }

  static async registerUser(user: User, additionalInfo: CreateAccountInfo): Promise<'Success' | RequestError> {
    if (user.workflowStatus !== UserStatus.CREATING) {
      return new RequestError(new Error('Cannot register user if they already exist'), 'Register user');
    }
    const landingPage = await LandingPage.fetchLandingPage(additionalInfo.landingPageKey);
    if (landingPage instanceof RequestError) return landingPage;

    const userData = {
      ...user.getFirestoreData(),
      ...additionalInfo,
      workflowStatus: UserStatus.CREATED,
      displayName: `${additionalInfo.firstName} ${additionalInfo.lastName}`,
      createdBy: 'Surveysaurus',
      created: new Date(),
      updated: new Date(),
    };
    try {
      await firestore.collection('users').doc(userData.uid).set(userData, { merge: true });
    } catch (error) {
      return new RequestError(error, 'Register user');
    }
    return 'Success';
  }

  static async declineExtension(user: User): Promise<'Success' | RequestError> {
    if (user.workflowStatus !== UserStatus.EXT_ELIGIBLE) {
      return new RequestError(new Error('Cannont decline extension if already not eligible'), 'Decline Extension');
    }
    try {
      await firestore.doc(`users/${user.uid}`).update({
        workflowStatus: UserStatus.EXT_DECLINED,
      });
    } catch (error) {
      return new RequestError(error, 'Decline Extension');
    }
    return 'Success';
  }
}
