import { WorkflowID } from '@swing-therapeutics/surveybay/dist/types';
import { DocumentData, DocumentSnapshot, firestore } from '../../utils/firebase';
import { RequestError } from '../RequestError';
import { User } from '../User';
import { SurveyMetaAbstract } from './SurveyMetaAbstract';

const findBaselineDoc = (userUID: string) => {
  return firestore.collection(`users/${userUID}/workflows`).where('workflowID', '==', WorkflowID.BASELINE);
};

export class BaselineMeta extends SurveyMetaAbstract {
  getFirestoreData() {
    return {
      baselineSurveys: this.surveys,
    };
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData>): BaselineMeta {
    const docData = docSnap.data();

    return new BaselineMeta({ ...docData }, docSnap.ref.path);
  }

  static async fromUser(user: User): Promise<BaselineMeta | RequestError> {
    let doc: DocumentSnapshot<DocumentData>;
    try {
      const collection = await findBaselineDoc(user.uid).get();
      doc = collection.docs[0];
    } catch (error) {
      return new RequestError(error, 'Baseline meta doc retrieval');
    }
    if (!doc?.exists) {
      return new RequestError(new Error('Baseline meta doc doesnt exist for user'), 'Baseline meta doc retrieval');
    } else {
      return this.fromFirestore(doc);
    }
  }

  static async fromUserSubscribe(user: User, changeCallback: (baselineMeta: BaselineMeta) => void): Promise<() => void> {
    try {
      const collection = await findBaselineDoc(user.uid).get();
      const doc = collection.docs[0];
      return firestore.doc(`users/${user.uid}/workflows/${doc.id}`).onSnapshot((snap) => {
        if (!snap.exists) {
          return;
        }
        changeCallback(new BaselineMeta(snap.data(), snap.ref.path));
      });
    } catch (error) {
      console.log(error);
      return () => {};
    }
  }
}
