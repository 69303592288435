import React, { useEffect } from 'react';
import styles from './CalendlyCallCalendar.module.css';

interface CalendarProps {
  apiURL: string;
  name?: string;
  email?: string;
  onEventScheduled?: (eventURI: string, inviteeURI: string) => void;
}

const CalendlyCallCalendar: React.FC<CalendarProps> = ({ name = '', email = '', apiURL, onEventScheduled }) => {
  useEffect(() => {
    const CalendlyAPI = (window as any).Calendly;
    if (CalendlyAPI) {
      // Initialize the calendly widget with prefilled data
      CalendlyAPI.initInlineWidget({
        url: `${apiURL}?hide_event_type_details=1`,
        parentElement: document.getElementById('CalendlyParent'),
        prefill: {
          name,
          email,
        },
        utm: {},
      });
    } else {
      console.error('Could not load Calendly API');
    }

    function calendlyEventLogger(e: any) {
      if (e.data.event && e.data.event.indexOf('calendly') === 0) {
        // The event is a calendly event
        if (e.data.event === 'calendly.event_scheduled') {
          // The user scheduled an event
          onEventScheduled?.(e.data.payload.event.uri, e.data.payload.invitee.uri);
        }
      }
    }

    window.addEventListener('message', calendlyEventLogger);

    return () => window.removeEventListener('message', calendlyEventLogger);
  }, [name, email, onEventScheduled, apiURL]);

  return (
    <div className={styles.callcont}>
      <div id='CalendlyParent' className={styles.callparent} />
    </div>
  );
};

// Wrap in memo because we never want this component to rerender or calendly will start over
export default React.memo(CalendlyCallCalendar, () => true);
