import { firebaseTimestampToDate } from '@swing-therapeutics/swingcore/dist/utils/firebase/firebaseTimestampToDate';
import { DocumentData, DocumentSnapshot, firestore } from '../utils/firebase';
import { RequestError } from './RequestError';

class ReferralGroup {
  created: Date;
  logoURL: string;
  referralGroupKey: string;
  name: string;
  updated: Date;
  referralGroupEmail: string;

  constructor(props: any) {
    this.created = props.created ?? new Date();
    this.logoURL = props.logoURL;
    this.name = props.name;
    this.referralGroupKey = props.referralGroupKey;
    this.updated = props.updated ?? new Date();
    this.referralGroupEmail = props.referralGroupEmail;
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData>): ReferralGroup {
    const data = docSnap.data();
    const created = firebaseTimestampToDate(data.created);
    const updated = firebaseTimestampToDate(data.updated);
    return new ReferralGroup({ ...data, created, updated });
  }

  static async fetchReferralGroup(referralGroupKey: string): Promise<ReferralGroup | RequestError> {
    let doc;
    try {
      doc = await firestore.collection('web/surveysaurus/referralGroups').doc(referralGroupKey).get();
    } catch (error) {
      return new RequestError(error, 'Referral group retrieval');
    }
    if (!doc.exists) {
      return new RequestError(new Error(`Referral group (${referralGroupKey}) does not exist`), 'Referral group retrieval');
    }
    return this.fromFirestore(doc as DocumentSnapshot<DocumentData>);
  }
}

export default ReferralGroup;
