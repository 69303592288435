const zenDeskSetting = {
  webWidget: {
    color: { theme: '#3e6865' },
  },
};

// Add in zendesk support widget
// Placing the script in index.html does not work, unclear why
// This is essentially the same as adding the script to index.html
export const loadZenDeskWidget = (onLoad?: () => void) => {
  // Load in zendesk widget
  (window as any).zESettings = zenDeskSetting;
  const zendeskKey = 'a0eb5e15-e710-455a-9108-821c2acde7af';
  const script = document.createElement('script');
  script.async = true;
  script.id = 'ze-snippet';
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
  if (onLoad) {
    script.addEventListener('load', onLoad);
  }
  document.body.appendChild(script);
};

export const prefillZenDeskWidget = (name: string, email: string) => {
  (window as any).zE?.('webWidget', 'prefill', {
    name: {
      value: name,
    },
    email: {
      value: email,
    },
  });
};

export const closeZenDeskWidget = () => {
  (window as any).zE?.('webWidget', 'close');
};

// Clear the data in the zendesk widget
export const clearZenDeskWidget = () => {
  closeZenDeskWidget();
  (window as any).zE?.('webWidget', 'reset');
};
