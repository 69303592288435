import React, { useCallback, useContext, useEffect } from 'react';
import { matchPath, Route, RouteProps, useHistory } from 'react-router';
import LandingPageStyling from '../../models/landing/LandingPageStyling';
import { RequestError } from '../../models/RequestError';
import UserStateContext from '../contexts/UserContext';
import ContentContext from '../contexts/ContentContext/ContentContext';

interface LandingPageRouteProps extends RouteProps {
  landingPageKeyIndex: number;
}

// This route checks that a landing page key exists and sets it in the state
// If it does not exist, a user is redirected to the 404 page
const LandingPageRoute: React.FC<LandingPageRouteProps> = ({ landingPageKeyIndex, ...routeProps }) => {
  const history = useHistory();
  const { userState, userStateDispatch } = useContext(UserStateContext);
  const { appContentState, appContentStateDispatch } = useContext(ContentContext);

  const checkLandingPageExists = useCallback(
    async (landingPageKey): Promise<boolean> => {
      const response = await LandingPageStyling.fetchLandingPage(landingPageKey);
      if (response instanceof RequestError) {
        return false;
      }
      // If the current landing page is different from the one in state, set it
      if (landingPageKey !== userState.landingPageKey) {
        userStateDispatch({ type: 'SETLPKEY', payload: landingPageKey });
      }

      if (response.metadata.title !== appContentState.studyTitle) {
        appContentStateDispatch({ type: 'SETLPDATA', payload: response });
      }
      return true;
    },
    [appContentState.studyTitle, appContentStateDispatch, userState.landingPageKey, userStateDispatch],
  );

  useEffect(() => {
    const path = routeProps.path as string;
    if (!routeProps.location || !path || !matchPath(routeProps.location.pathname, path)) {
      return;
    }
    const landingPageKey = history.location.pathname.split('/')[landingPageKeyIndex];
    if (landingPageKey) {
      checkLandingPageExists(landingPageKey).then((exists) => {
        if (!exists) {
          // If the landing page doesnt exist go to 404 page
          history.push('/404');
        }
      });
    }
  }, [history, checkLandingPageExists, landingPageKeyIndex, routeProps.location, routeProps.path]);

  return <Route {...routeProps} />;
};

export default LandingPageRoute;
