import { AppContent, UserFail } from '@swing-therapeutics/surveybay/dist/types';

/*
 * If for some reason content can't be fetched, use this default content so it is at least populated
 */
const defaultContent: AppContent = {
  failPageText: {
    [UserFail.DISCLAIM_NOTACCEPTED]:
      'Thank you for your interest but the disclaimer must be accepted to participate in the study. You can click back to accept the disclaimer.',
    [UserFail.EXISTING_ACCOUNT]: 'Thank you for your interest but you have already taken part in a related study which makes you ineligible.',
    [UserFail.SCREENING_FAIL]: 'Sorry, this study is not a good fit for you. Sign up to keep updates for other programs that we plan to run.',
    [UserFail.BASELINE_ABANDONED]: 'Thank you for your interest but your baseline survey was not completed on time.',
    [UserFail.SCREENING_ABANDONED]: 'Thank you for your interest but your eligibility survey was not completed on time.',
    [UserFail.CONSENT_FAIL]: 'Thank you for your interest but you were not approved for this study.',
    [UserFail.EARLY_TERMINATION]: 'Account was removed from program enrollment.',
  },
  FAQs: [],
};

export default defaultContent;
