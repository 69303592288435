import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import LoadingIcon from '../../components/common/LoadingIcon';
import QuestionCarousel from '../../components/common/QuestionCarousel';
import Section from '../../components/common/Section';
import Layout from '../../components/Layout';
import { User } from '../../models/User';
import UserStateContext from '../../utils/contexts/UserContext';
import { firestore } from '../../utils/firebase';
import { SurveyBayResponse } from '@swing-therapeutics/surveybay/dist/models/SurveyBayResponse';
import { EligibilityMeta } from '../../models/survey/EligibilityMeta';
import { RequestError } from '../../models/RequestError';
import FormProgressBar from '../../components/common/FormProgressBar';
import { BaselineMeta } from '../../models/survey/BaselineMeta';
import { TherapyMeta } from '../../models/survey/TherapyMeta';
import moment from 'moment';
import { capitalize } from '../../utils/functions/Common';
import { usePageLog } from '../../utils/analytics';
import { ConditionalDisplay, Question, ResponseTypes, SurveyStatus } from '@swing-therapeutics/surveybay/dist/types';
import { firebaseTimestampToDate } from '@swing-therapeutics/swingcore/dist/utils/firebase/firebaseTimestampToDate';
import { TerminationMeta } from '../../models/survey/TerminationMeta';
import SurveyMessage from '../../components/common/SurveyMessage';

type SurveyTypes = 'baseline' | 'eligibility' | 'therapy' | 'followup';
interface PageParams {
  surveyType: SurveyTypes;
}

// Map a question index to a survey index and survey question index
interface QuestionIndexMap {
  surveyIndex: number;
  surveyQuestionIndex: number;
}

// Output some useful console.logs and allow nav on therapy survey
const debug = false;

const AnswerSurvey: React.FC<RouteComponentProps<PageParams>> = ({ match }) => {
  usePageLog({ pageTitle: capitalize(match.params.surveyType) + ' Survey' });
  const { userState } = useContext(UserStateContext);
  const history = useHistory();
  // Question index in relation to all questions
  const [questionIndex, setQuestionIndex] = useState(0);
  // All questions from every eligibility survey compiled into one array
  const [allQuestions, setAllQuestions] = useState<Question[]>();
  // Flags if all the surveys have been completed
  const [surveysCompleted, setSurveysCompleted] = useState(false);
  // Expire date
  const [expireDate, setExpireDate] = useState<Date>();
  // Each index corresponds to total question index
  const questionIndexMap = useRef<QuestionIndexMap[]>([]);
  // All the eligibility surveys
  const allSurveys = useRef<SurveyBayResponse[]>();
  // Eligibility meta workflow doc with info on what surveys should be completed
  const surveyMeta = useRef<EligibilityMeta | BaselineMeta | TherapyMeta | TerminationMeta>();
  const isMounted = useRef(true);

  const fetchAndSetSurvey = useCallback(
    async (user: User) => {
      let surveyMetaDoc: BaselineMeta | EligibilityMeta | TherapyMeta | RequestError | TerminationMeta | undefined;
      switch (match.params.surveyType) {
        case 'baseline':
          surveyMetaDoc = await BaselineMeta.fromUser(user);
          break;
        case 'eligibility':
          surveyMetaDoc = await EligibilityMeta.fromUser(user);
          break;
        case 'therapy':
          surveyMetaDoc = await TherapyMeta.fromUser(user);
          break;
        case 'followup':
          surveyMetaDoc = await TerminationMeta.fromUser(user);
          break;
        default:
          console.error(`Answer survey page received a survey type ${match.params.surveyType} which is not expected`);
          break;
      }

      if (!surveyMetaDoc || surveyMetaDoc instanceof RequestError) {
        return;
      }

      if (surveyMetaDoc instanceof TherapyMeta && surveyMetaDoc.surveyWeek === -1) {
        // No surveys to take right now
        history.push('/profile');
        return;
      }

      const surveyResponseDocs: SurveyBayResponse[] = [];
      const allQuestions: any[] = [];
      for (const survey of surveyMetaDoc.surveys) {
        const doc = await firestore.doc(`users/${user.uid}/surveyBayResponses/${survey.responseDocID}`).get();
        if (!doc.exists) {
          console.error(`Survey response doc with surveyBayKey ${survey.surveyBayKey} and ID ${survey.responseDocID} does not exist`);
        } else {
          const surveyResponse = SurveyBayResponse.fromFirestore(doc);
          surveyResponseDocs.push(surveyResponse);
          allQuestions.push(...surveyResponse.questions);
        }
      }
      if (surveyResponseDocs.length === 0) {
        console.warn(`No survey response documents found for ${match.params.surveyType}`);
        // Therapy survey meta doc can have empty arrays indicating there are no surevys to take this week
        history.push('/profile');
        return;
      }
      // Find the last survey completed
      let questionsCompleted = 0;
      for (let surveyI = 0; surveyI < surveyResponseDocs.length; surveyI++) {
        if (surveyResponseDocs[surveyI].questions.length === 0) {
          console.error(`Survey ${surveyResponseDocs[surveyI].id} has no questions!`);
        }
        if (surveyResponseDocs[surveyI].status === SurveyStatus.COMPLETED) {
          questionsCompleted += surveyResponseDocs[surveyI].questions.length;
          if (surveyI === surveyResponseDocs.length - 1) {
            // All surveys have been completed
            surveyMeta.current = surveyMetaDoc;
            allSurveys.current = surveyResponseDocs;
            setAllQuestions(allQuestions);
            setSurveysCompleted(true);
            break;
          }
          continue;
        }
        // Find the last answered question
        for (let questionI = 0; questionI < surveyResponseDocs[surveyI].questions.length; questionI++) {
          if (!surveyResponseDocs[surveyI].questions[questionI].answered && !surveyResponseDocs[surveyI].questions[questionI].skipped) {
            // Create the map for question index to survey index and survey question index
            const qIMap: QuestionIndexMap[] = [];
            let surveyIndex = 0;
            allQuestions.forEach((_question, index) => {
              let surveyQuestionIndex = index;
              for (let sI = 0; sI < surveyIndex; sI++) {
                if (surveyResponseDocs[sI].questions.length > surveyQuestionIndex) {
                  break;
                } else {
                  surveyQuestionIndex -= surveyResponseDocs[sI].questions.length;
                }
              }
              qIMap.push({
                surveyIndex,
                surveyQuestionIndex,
              });
              if (surveyResponseDocs[surveyIndex].questions.length - 1 === surveyQuestionIndex) {
                surveyIndex += 1;
              }
            });
            surveyMeta.current = surveyMetaDoc;
            allSurveys.current = surveyResponseDocs;
            questionIndexMap.current = qIMap;
            setAllQuestions(allQuestions);
            setQuestionIndex(questionsCompleted + questionI);
            setExpireDate(firebaseTimestampToDate(surveyMetaDoc.surveys[0].expires));

            if (debug) {
              console.log('Survey meta doc', surveyMeta.current);
              console.log('All surveys', allSurveys.current);
              console.log('Current survey index', surveyI);
              console.log('All questions', allQuestions);
              console.log('Current question index', questionsCompleted + questionI);
              console.log('Question index map', questionIndexMap);
            }
            break;
          }
        }
        break;
      }
    },
    [match.params.surveyType, history],
  );

  useEffect(() => {
    setAllQuestions(undefined);
    setSurveysCompleted(false);
    if (userState.user && userState.user !== 'FIRSTLOAD') {
      fetchAndSetSurvey(userState.user);
    }
  }, [userState, fetchAndSetSurvey]);

  useEffect(() => {
    isMounted.current = true;
    if (surveysCompleted && ['therapy', 'followup'].includes(match.params.surveyType)) {
      // Redirect back to profile page if the user has completed their currently assigned week of therapy surveys or followup surveys
      // Eligibility and baseline surveys are automatically redirected when workflow status updates
      setTimeout(() => {
        isMounted.current && history.push('/profile');
      }, 2000);
    }
    return () => {
      isMounted.current = false;
    };
  }, [surveysCompleted, match.params, history]);

  const handleQuestionAnswer = (answer: ResponseTypes) => {
    if (!allSurveys.current || !surveyMeta.current || !allQuestions || !questionIndexMap.current) return;
    const { surveyIndex, surveyQuestionIndex } = questionIndexMap.current[questionIndex];
    if (debug) {
      console.log('Answer', answer);
      console.log('Question Index', questionIndex);
      console.log('Current survey index', surveyIndex);
      console.log('Current survey question index', surveyQuestionIndex);
    }

    const answerQuestionAndProceed = (quesitonIndexesToSkip?: number[], skipped?: boolean) => {
      // Set the question answer in all questions
      setAllQuestions((prevAllQuestions) => {
        if (!prevAllQuestions) return prevAllQuestions;
        const allQuestions = [...prevAllQuestions];
        allQuestions[questionIndex].response = answer;
        allQuestions[questionIndex].answered = true;
        if (quesitonIndexesToSkip) {
          quesitonIndexesToSkip.forEach((questionIndex) => {
            allQuestions[questionIndex].skipped = skipped;
          });
        }
        return allQuestions;
      });
      handleQuestionNav('NEXT');
    };

    // Answer the survey question
    allSurveys.current[surveyIndex].questions[surveyQuestionIndex].response = answer;
    allSurveys.current[surveyIndex].questions[surveyQuestionIndex].answered = true;
    allSurveys.current[surveyIndex].questions[surveyQuestionIndex].updated = new Date();

    let questionIndexesToSkip: number[] | undefined = undefined;
    let skipped = false;
    if (allSurveys.current[surveyIndex].questions[surveyQuestionIndex].conditionalDisplay) {
      const { hideQuestionsIfEquals, questionNumbersToHide, hide } = allSurveys.current[surveyIndex].questions[surveyQuestionIndex]
        .conditionalDisplay as ConditionalDisplay;
      // Conditional display question, check if we need to hide questions
      const conditionMet = Array.isArray(answer)
        ? answer.length === hideQuestionsIfEquals.length && answer.every((a) => hideQuestionsIfEquals.includes(a))
        : answer === hideQuestionsIfEquals;
      // Hide/skip the questions or unhide/unskip the questions
      questionIndexesToSkip = [];
      // If the condition is met, then the questions should skipped based on the conditional display property hide (true or false),
      // If the condition is not met, then skipped should be the opposite of hide
      skipped = conditionMet ? hide : !hide;
      const baseQuestionIndex = questionIndex - surveyQuestionIndex;
      questionNumbersToHide.forEach((questionNumber: number) => {
        questionIndexesToSkip!.push(baseQuestionIndex + questionNumber - 1);
        allSurveys.current![surveyIndex].questions[questionNumber - 1].skipped = skipped;
      });
    }

    const allSurveyQuestionsAnswered = allSurveys.current[surveyIndex].questions.every((question) => question.answered || question.skipped);

    if (allSurveyQuestionsAnswered) {
      // Set survey to completed
      allSurveys.current[surveyIndex].complete();
      // Set survey in meta doc to completed
      surveyMeta.current.complete(allSurveys.current[surveyIndex].surveyBayKey);
      // Save both to DB
      allSurveys.current[surveyIndex].persist();
      surveyMeta.current.persist();
      // Check if we have completed all the surveys
      const allSurveysCompleted = surveyMeta.current.surveys.every((survey) => survey.status === SurveyStatus.COMPLETED);
      if (allSurveysCompleted) {
        // All questions answered
        setSurveysCompleted(true);
      } else {
        answerQuestionAndProceed(questionIndexesToSkip, skipped);
      }
    } else {
      // Make sure the survey is set to started
      allSurveys.current[surveyIndex].start();
      // Make sure the meta doc is set to started for the survey
      surveyMeta.current.start(allSurveys.current[surveyIndex].surveyBayKey);
      // Save both to DB
      allSurveys.current[surveyIndex].persist();
      surveyMeta.current.persist();
      answerQuestionAndProceed(questionIndexesToSkip, skipped);
    }
  };

  const handleQuestionNav = (direction: 'NEXT' | 'PREVIOUS' | number) => {
    if (!allQuestions || !allSurveys.current) return;
    switch (direction) {
      case 'NEXT':
        if (allQuestions.length - 1 > questionIndex) {
          if (allQuestions[questionIndex + 1].skipped) {
            // Find next question that is not skipped
            if (!allQuestions[questionIndex + 2]) return;
            for (let index = questionIndex + 2; index < allQuestions.length; index++) {
              if (!allQuestions[index].skipped) {
                handleQuestionNav(index);
                return;
              }
            }
            console.error("Could not find another question that wasn't skipped when pressing next");
            return;
          }
          setQuestionIndex((prevState) => prevState + 1);
        }
        break;
      case 'PREVIOUS':
        if (questionIndex !== 0) {
          if (allQuestions[questionIndex - 1].skipped) {
            // Find next question that is not skipped
            if (!allQuestions[questionIndex - 2]) return;
            for (let index = questionIndex - 2; index < allQuestions.length; index--) {
              if (!allQuestions[index].skipped) {
                handleQuestionNav(index);
                return;
              }
            }
            console.error("Could not find another question that wasn't skipped when pressing prev");
            return;
          }
          setQuestionIndex((prevState) => prevState - 1);
        }
        break;
      default:
        setQuestionIndex(direction);
    }
  };

  return (
    <Layout showDisclaimer={true} navSpace={true}>
      <Section minHeight='75vh'>
        <Container className='mt-3'>
          <Row>
            <h2 className='mb-0'>
              {capitalize(match.params.surveyType)} Survey
              {surveyMeta.current instanceof TherapyMeta && ` (week ${surveyMeta.current.surveyWeek})`}
            </h2>
          </Row>
          <Row>{expireDate && <p>Expires on {moment(expireDate).format('ddd MM/DD/YY h:mm a')}</p>}</Row>
          <Row>
            <p>{surveyText[match.params.surveyType]}</p>
          </Row>
          <Row className='justify-content-center mb-3'>
            <Col lg='5' sm='12'>
              {allQuestions && <FormProgressBar questions={allQuestions} />}
            </Col>
          </Row>
          <Row className='justify-content-center'>
            {allQuestions && !surveysCompleted ? (
              <QuestionCarousel
                questions={allQuestions}
                questionIndex={questionIndex}
                handleQuestionAnswer={handleQuestionAnswer}
                handleQuestionNav={handleQuestionNav}
                //Only allow navigation on baseline surveys, or if we are in debug mode
                navigation={['baseline', 'therapy'].includes(match.params.surveyType)}
                debugNavigation={debug}
              />
            ) : (
              <LoadingIcon
                text={surveysCompleted ? `Survey completed!${match.params.surveyType !== 'therapy' ? '\nLoading next step.' : ''}` : false}
                textColor='dark'
                height={200}
              />
            )}
          </Row>
          <Row className='justify-content-center'>
            <SurveyMessage />
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default AnswerSurvey;

const surveyText: { [key in SurveyTypes]: string } = {
  eligibility: 'Please answer the following questions to help us determine if you are eligible to participate in our study.',
  baseline: 'Please answer the following questions to help us measure your progress throughout your therapy.',
  therapy: 'Please answer the following questions.',
  followup: 'Please answer the following questions.',
};
