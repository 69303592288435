import React, { useCallback, useEffect, useState, useRef } from 'react';
import { ScheduledCallStatus, UserStatus } from '@swing-therapeutics/surveybay/dist/types';
import styles from './WorkflowProgress.module.scss';
import { TerminationMeta } from '../../../models/survey/TerminationMeta';
import type { User } from '../../../models/User';
import { TerminationCallMeta } from '../../../models/calls/TerminationCallMeta';

interface WorkflowProgressBarProps {
  user: User;
  inExtension: boolean;
}

enum BarType {
  EXTENION,
  STANDARD,
  FOLLOWUP,
}

interface BarState {
  width: number;
  type: BarType;
}

const WorkflowProgressBar: React.FC<WorkflowProgressBarProps> = ({ user, inExtension }) => {
  const [barState, setBarState] = useState<BarState | 'loading'>('loading');
  const unSub = useRef<() => void>();

  const parseSetBarWidth = useCallback(async () => {
    let barWidth: number;
    if ([UserStatus.EXT_EARLY_TERMINATION_FOLLOWUP, UserStatus.EARLY_TERMINATION_FOLLOWUP].includes(user.workflowStatus)) {
      unSub.current?.();
      // Special bar width handling for these two status
      unSub.current = await TerminationMeta.fromUserSubscribe(user, async (terminationMeta) => {
        if (terminationMeta.surveysTerminal) {
          unSub.current?.();
          unSub.current = await TerminationCallMeta.subscribeCurrentCall(user, (terminationCallMeta) => {
            if (terminationCallMeta.status === ScheduledCallStatus.COMPLETED) {
              barWidth = 100;
            } else {
              barWidth = 49;
            }
            setBarState({
              width: barWidth,
              type: BarType.FOLLOWUP,
            });
          });
        } else {
          setBarState({
            width: 0,
            type: BarType.FOLLOWUP,
          });
        }
      });
    } else {
      if (inExtension) {
        switch (user.workflowStatus) {
          case UserStatus.EXT_ELIGIBLE:
          case UserStatus.EXT_CONSENT_ABANDONED:
          case UserStatus.EXT_CONSENT:
            barWidth = 49;
            break;
          case UserStatus.EXT_PRE_THERAPY:
          case UserStatus.EXT_THERAPY:
            barWidth = 99;
            break;
          default:
            barWidth = 100;
            break;
        }
      } else {
        switch (user.workflowStatus) {
          case UserStatus.SCREENING:
            barWidth = 32;
            break;
          case UserStatus.CONSENT_ABANDONED:
          case UserStatus.CONSENT:
            barWidth = 65;
            break;
          case UserStatus.BASELINE:
            barWidth = 99;
            break;
          default:
            barWidth = 100;
            break;
        }
      }
      setBarState({
        width: barWidth,
        type: inExtension ? BarType.EXTENION : BarType.STANDARD,
      });
    }
  }, [user, inExtension]);

  useEffect(() => {
    parseSetBarWidth();
    return unSub.current;
  }, [parseSetBarWidth]);

  return barState !== 'loading' ? (
    <div className={styles.bar}>
      <div className={styles.barfill} style={{ width: `${barState.width}%` }} />
      {
        {
          [BarType.STANDARD]: <StandardBar barWidth={barState.width} />,
          [BarType.EXTENION]: <ExtensionBar barWidth={barState.width} />,
          [BarType.FOLLOWUP]: <FollowupBar barWidth={barState.width} />,
        }[barState.type]
      }
    </div>
  ) : null;
};

export default WorkflowProgressBar;

interface BarProps {
  barWidth: number;
}

const StandardBar: React.FC<BarProps> = ({ barWidth }) => {
  return (
    <>
      <BarPoint barWidth={barWidth} location={0} label='Signup' />
      <BarPoint barWidth={barWidth} location={33} label='Eligibility' />
      <BarPoint barWidth={barWidth} location={66} label='Consent' />
      <BarPoint barWidth={barWidth} location={100} label='Baseline' />
    </>
  );
};

const ExtensionBar: React.FC<BarProps> = ({ barWidth }) => {
  return (
    <>
      <BarPoint barWidth={barWidth} location={0} label='Eligibility' />
      <BarPoint barWidth={barWidth} location={50} label='Consent' />
      <BarPoint barWidth={barWidth} location={100} label='Therapy' />
    </>
  );
};

const FollowupBar: React.FC<BarProps> = ({ barWidth }) => {
  return (
    <>
      <BarPoint barWidth={barWidth} location={1} label='Surveys' />
      <BarPoint barWidth={barWidth} location={50} label='Call' />
      <BarPoint barWidth={barWidth} location={100} label='Done' />
    </>
  );
};

interface BarPointProps {
  // Bar width and location should be between 0 - 100
  barWidth: number;
  location: number;
  label: string;
}

const BarPoint: React.FC<BarPointProps> = ({ barWidth, location, label }) => {
  const filled = barWidth >= location;
  return (
    <div
      id={`${label}-point${filled ? '-filled' : ''}`}
      className={filled ? styles.checkpointfilled : styles.checkpoint}
      style={{ left: `${location}%` }}
    >
      <p>{label}</p>
    </div>
  );
};
