import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Container, Row } from 'reactstrap';
import { ExclamationIcon } from '../../assets/icons/svgicons';
import Section from '../../components/common/Section';
import Layout from '../../components/Layout';
import { usePageLog } from '../../utils/analytics';

const PageNotFound: React.FC<RouteComponentProps> = () => {
  usePageLog({ pageTitle: '404' });

  // this was always defaulting to 003, so there was always a userstate, no matter what.
  // no need to hide nav bar as it wasn't done prior.
  return (
    <Layout navSpace={true} showNavBar={true}>
      <Section minHeight='75vh'>
        <Container>
          <Row className='justify-content-center mt-4'>
            <ExclamationIcon height='50px' width='50px' />
          </Row>
          <Row className='justify-content-center mt-4'>
            <h3>Oops... we could not find that page</h3>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default PageNotFound;
