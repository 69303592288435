import type {
  Faq,
  ILandingPageFooter,
  ILandingPageHeader,
  ILandingPageMetadata,
  ILandingPageSectionOne,
  ILandingPageSectionTwo,
  ILandingPageStyling,
} from '@swing-therapeutics/surveybay/dist/types';
import { DocumentData, DocumentSnapshot, firestore } from '../../utils/firebase';
import { RequestError } from '../RequestError';
import LandingPage from './LandingPage';
import LandingPageCore from './LandingPageCore';

class LandingPageStyling extends LandingPageCore implements ILandingPageStyling {
  metadata: ILandingPageMetadata;
  header: ILandingPageHeader;
  sectionOne: ILandingPageSectionOne;
  sectionTwo: ILandingPageSectionTwo;
  footer: ILandingPageFooter;
  stylingID: string;
  faqs: Faq[];

  constructor(props: any) {
    super(props);
    // The parent class parses timestamp stuff, dont overwrite it
    const { created, updated, ...noTimeStampProps } = props;
    Object.assign(this, noTimeStampProps);
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData | undefined>): LandingPageStyling {
    const data = docSnap.data();
    return new LandingPageStyling(data);
  }

  static async fetchLandingPage(landingPageKey: string): Promise<LandingPageStyling | RequestError> {
    let doc: DocumentSnapshot<DocumentData | undefined>;
    try {
      doc = await firestore.doc(`web/surveysaurus/landingPages/${landingPageKey}`).get();
    } catch (error) {
      return new RequestError(error, 'Landing page retrieval');
    }
    if (!doc?.exists) {
      return new RequestError(new Error(`Landing page ${landingPageKey} does not exist`), 'Landing page retrieval');
    }
    const landingPage = LandingPage.fromFirestore(doc);
    if (!landingPage.stylingID) {
      return new RequestError(new Error(`Styling ID for ${landingPageKey} does not exist`), 'Landing page retrieval');
    }
    doc = await firestore.doc(`web/surveysaurus/landingPages/${landingPageKey}/styling/${landingPage.stylingID}`).get();
    if (!doc?.exists) {
      return new RequestError(new Error(`Styling for ${landingPageKey} does not exist`), 'Landing page retrieval');
    }
    return LandingPageStyling.fromFirestore(doc);
  }
}

export default LandingPageStyling;
