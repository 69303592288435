import type {
  ILandingPageCore,
  PaymentNotifications,
  ScheduleCallOption,
  ScheduleCallOptions,
  SurveyWeek,
} from '@swing-therapeutics/surveybay/dist/types';
import { DocumentData, DocumentSnapshot, firestore } from '../../utils/firebase';
import { firebaseTimestampToDate } from '@swing-therapeutics/swingcore/dist/utils/firebase/firebaseTimestampToDate';
import { RequestError } from '../RequestError';

class LandingPageCore implements ILandingPageCore {
  surveyWeeks: SurveyWeek[];
  created: Date | undefined;
  updated: Date;
  trialID: string;
  weekdaySurveyAssignment: number; // (0-6)
  daysToCompleteTherapySurvey: number;
  paymentNotifications: PaymentNotifications;
  landingPageKey: string;
  earlyTerminationProgram: string;
  // Extension will be the id of the extension doc
  // Or false if there is no extension
  extensionID: false | string;
  scheduleCallOptions: ScheduleCallOptions;
  terminationCall: ScheduleCallOption;
  consentCall: ScheduleCallOption;
  terminationSurveys: string[];
  stylingID: string | false;

  constructor(props: any) {
    Object.assign(this, props);
    this.created = firebaseTimestampToDate(props.created);
    this.updated = firebaseTimestampToDate(props.updated);
  }

  static fromFirestore(docSnap: DocumentSnapshot<DocumentData | undefined>): ILandingPageCore {
    const data = docSnap.data();
    return new LandingPageCore(data);
  }

  static async fetchLandingPage(landingPageKey: string, extension = false): Promise<LandingPageCore | RequestError> {
    let doc: DocumentSnapshot<DocumentData | undefined>;
    try {
      doc = await firestore.doc(`web/surveysaurus/landingPages/${landingPageKey}`).get();
    } catch (error) {
      return new RequestError(error, 'Landing page retrieval');
    }
    if (!doc?.exists) {
      return new RequestError(new Error(`Landing page ${landingPageKey} does not exist`), 'Landing page retrieval');
    }
    const landingPage = LandingPageCore.fromFirestore(doc);
    if (!extension) {
      return landingPage;
    }
    if (!landingPage.extensionID) {
      return new RequestError(new Error(`Extension for ${landingPageKey} does not exist`), 'Landing page retrieval');
    }
    doc = await firestore.doc(`web/surveysaurus/landingPages/${landingPageKey}/extensions/${landingPage.extensionID}`).get();
    if (!doc?.exists) {
      return new RequestError(new Error(`Extension for ${landingPageKey} does not exist`), 'Landing page retrieval');
    }
    return LandingPageCore.fromFirestore(doc);
  }
}

export default LandingPageCore;
