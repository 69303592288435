import type { firestore as firestoreType } from 'firebase';
import { useContext, useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useLocation } from 'react-router';
import UserStateContext from './contexts/UserContext';
import { firestore } from './firebase';

type CustomClass<T> = {
  fromFirestore: (snapshot: firestoreType.DocumentSnapshot) => T;
};

type CollectionReturn<T> = [T[], boolean, Error | undefined];

export const useCollectionClassData = <T>(customClass: CustomClass<T>, collectionQuery: firestoreType.Query): CollectionReturn<T> => {
  const [collection, loading, error] = useCollection(collectionQuery);
  const [_collection, setCollection] = useState<T[]>([] as T[]);

  useEffect(() => {
    if (collection) {
      setCollection(collection.docs.map((doc: any) => customClass.fromFirestore(doc)));
    }
  }, [collection, customClass]);

  return [_collection, loading, error];
};

// Navigate to the top of the page when the path changes
// Only fires on path change not hash change
export const usePageTopOnNav = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('/welcome')) {
      // Only scroll to top on pages that are not the /welcome page
      // There is another scroll handler for that page
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);
};

/*
 * Listen for new messages in the surveyMessages collection
 * When a new message is added state is updated with the new message
 */
export const useSurveyMessages = () => {
  const { userState } = useContext(UserStateContext);
  const [message, setMessage] = useState<null | string>(null);

  useEffect(() => {
    let unsub: () => void | undefined;
    if (userState.user && userState.user !== 'FIRSTLOAD') {
      unsub = firestore
        .collection(`users/${userState.user.uid}/surveyMessages`)
        // Grab all messages that exist after we started listening
        // If we don't filter by this, older messages will be returned
        // Only want to listen for new messages
        .where('timestamp', '>', new Date())
        .onSnapshot((snap) => {
          snap.docChanges().forEach((change) => {
            if (change.type === 'added') {
              // New message added, show it
              const data = change.doc.data();
              setMessage(data.message);
            }
          });
        });
    }
    return () => {
      unsub?.();
    };
  }, [userState]);

  return message;
};
